import React from "react";
import { motion } from "framer-motion";

import { ABIcon, ChatIcon, GearIcon, TelevisionIcon } from "../icons";
import TitleOfSection from "./TitleOfSection";

const HelpingBusinessCard = () => {
  const cards = [
    {
      title: "discussion",
      description:
        "Lors de cette phase, nous échangeons avec nos clients aﬁn de mieux identiﬁer leurs besoins et les aider dans l'élabora- tion de leur cahier des charges",
      icons: <ChatIcon width={50} className="fill-mainColor" />,
      delay: 0.25,
    },
    {
      title: "idéés & concepts",
      description:
        "Pendant cette phase, l'accent est mis sur l'analyse et la conception de l'idée aﬁn de fournir des artefacts qui seront utilisés lors des phases suivantes",
      icons: <TelevisionIcon width={50} className="fill-mainColor" />,
      delay: 0.5,
    },
    {
      title: "Tests & développement",
      description:
        "Notre démarche TDD nous oblige à faire usage de tests unitaires durant toute la phase de développe- ment avant de finir par des tests d'intégrations et de déploiements",
      icons: <ABIcon width={50} className="fill-mainColor" />,
      delay: 0.75,
    },
    {
      title: "execution & installation",
      description:
        "À l'issue des tests, nous vous fournissons une documentation  détaillée sur l'utilisation du service. Nous assurons également des services de formations et maintenances",
      icons: <GearIcon width={55} className="fill-mainColor" />,
      delay: 1,
    },
  ];

  function cardListVariants(delay) {
    return {
      hidden: {
        scale: 0.5,
        opacity: 0,
        x: -200,
      },
      visible: {
        scale: 1,
        opacity: 1,
        x: 0,
        transition: {
          duration: 0.5,
          delay: delay,
          delayChildren: 0.3,
          staggerChildren: 0.2,
        },
      },
    };
  }

  return (
    <div className="mt-20 px-5 md:px-10 lg:px-24 tracking-widest">
      <div className="relative flex flex-wrap space-y-4 items-start justify-between tracking-widest">
        <TitleOfSection title="comment nous travaillons" color1="mainColor">
          <span className="text-2xl ">
            Comment nous
            <br />
            assistons votre
            <br />
            Business
          </span>
        </TitleOfSection>
        <p className={` dark:text-white`}>
          Pour chaque projet sur lequel nous travaillons,
          <br /> nous suivons une démarche agile.
        </p>
      </div>

      <div className="w-full my-16 mb-10 flex gap-5 flex-wrap justify-between">
        {cards.map(({ title, description, icons, delay }, index) => (
          <motion.div
            key={index}
            variants={cardListVariants(delay)}
            initial="hidden"
            whileInView="visible"
            exit="visible"
            viewport={{ once: true }}
            className={`flex-1 mb-8 bg-white rounded-lg shadow hover:shadow-xl p-3 dark:text-black drop-shadow`}
          >
            <div className="flex items-center gap-4">
              {icons}
              <h3 className=" uppercase leading-5 text-s text-secondColor font-semibold">
                {title}
              </h3>
            </div>
            <p className={"text-sm pt-3 dark:text-black "}>{description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default HelpingBusinessCard;
