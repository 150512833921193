import { motion } from "framer-motion";
import { paraghVariants } from "../animation_variants";
import { memo } from "react";

function TitleOfSection({ title, color1, color2, children }) {
  return (
    <motion.div
      variants={paraghVariants}
      initial="hidden"
      whileInView="visible"
      exit="visible"
      viewport={{ once: true }}
    >
      <span className={`bg-${color1} inline-block w-[30px] h-1`} />
      <span className={`text-${color1}  w-44 font-bold ml-2 uppercase`}>
        {title}
      </span>
      <h2 className={`text-${color2} dark:text-white font-extrabold`}>
        {children}
      </h2>
    </motion.div>
  );
}

export default memo(TitleOfSection);
