import { memo } from "react";
import { imageVariants, paraghVariants } from "../../animation_variants";
import ContactForm from "../ContactForm";
import { motion } from "framer-motion";

function ConsultationSection({ title, img, description }) {
  return (
    <div className="relative bg-cover bg-center tracking-widest">
      <motion.img
        variants={imageVariants}
        initial="hidden"
        whileInView="visible"
        exit="visible"
        viewport={{ once: true }}
        src={img}
        alt="bg-codingart"
        loading="lazy"
        className="absolute h-[480px] w-full object-cover object-center"
      />
      <motion.h1
        variants={paraghVariants}
        initial="hidden"
        whileInView="visible"
        exit="visible"
        viewport={{ once: true }}
        className="relative text-4xl md:text-5xl font-bold leading-[50px] md:leading-[60px] capitalize text-white w-80 lg:w-[450px] h-[480px] pl-5 md:pl-10 lg:pl-24 pt-32 lg:pt-24"
      >
        {title}
      </motion.h1>

      <ContactForm
        title="Formulaire de contact"
        buttonTitle="envoyer"
        description={description}
      />
    </div>
  );
}

export default memo(ConsultationSection);
