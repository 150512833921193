import { motion } from "framer-motion";
import { draw, svgVariants } from "../animation_variants";

function Loader({ styling, lightIcon }) {
  return (
    <motion.svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 510 120"
      variants={svgVariants}
      initial="hidden"
      animate="visible"
      className={styling}
    >
      <motion.path
        stroke="#00B1E3"
        strokeWidth="1"
        variants={draw}
        fill="#00B1E3"
        d="M137.1,27.7c1.8-3.3,4.4-5.9,7.7-7.8c3.3-1.9,7-2.8,11.2-2.8c5.1,0,9.5,1.3,13.1,4c3.6,2.7,6.1,6.4,7.3,11.1
            H165c-0.9-1.8-2.1-3.1-3.7-4.1c-1.6-0.9-3.3-1.4-5.4-1.4c-3.2,0-5.8,1.2-7.8,3.4c-2,2.2-3,5.2-3,9s1,6.7,3,9c2,2.2,4.6,3.4,7.8,3.4
            c2,0,3.8-0.4,5.4-1.4c1.6-0.9,2.8-2.3,3.7-4.1h11.5c-1.3,4.7-3.7,8.4-7.3,11c-3.6,2.7-8,4-13.1,4c-4.1,0-7.9-0.9-11.2-2.8
            c-3.3-1.9-5.8-4.4-7.7-7.7c-1.8-3.3-2.8-7.1-2.8-11.3C134.3,34.8,135.2,31,137.1,27.7z M203.1,61.1c4.1,0,7.8-0.9,11.2-2.8
            c3.4-1.9,6-4.5,8-7.9c2-3.4,3-7.2,3-11.4s-0.9-8-2.9-11.3c-2-3.3-4.6-5.9-8-7.8c-3.4-1.9-7.1-2.8-11.2-2.8c-4,0-7.7,0.9-11.2,2.8
            c-3.3,1.9-6,4.5-8,7.8c-2,3.3-3,7.1-3,11.3s1,8,3,11.4c2,3.4,4.6,6,8,7.9C195.5,60.2,199.1,61.2,203.1,61.1z M203.2,51.6
            c-3.4,0-6.2-1.2-8.3-3.4c-2.1-2.2-3.1-5.3-3.1-9.1c0-3.9,1-6.9,3.1-9.2c2-2.2,4.8-3.4,8.3-3.4c3.4,0,6.2,1.2,8.3,3.4
            c2.1,2.2,3.1,5.3,3.1,9.1s-1,6.8-3.1,9.1S206.7,51.5,203.2,51.6z M247.3,17.6h-16.1v43.1h16.1c4.5,0,8.5-0.9,11.9-2.7
            c3.4-1.8,6-4.4,7.9-7.6c1.9-3.3,2.8-7,2.8-11.2s-0.9-8-2.8-11.2c-1.9-3.2-4.5-5.8-7.9-7.6C255.7,18.5,251.8,17.6,247.3,17.6z
             M246.5,51.6h-4.9v-25h4.9c4,0,7,1.1,9.3,3.3c2.2,2.2,3.3,5.3,3.3,9.3s-1.1,7-3.3,9.2C253.7,50.5,250.5,51.6,246.5,51.6z
             M275.7,17.6v43.1h10.5V17.6H275.7z M332.4,60.7V17.6h-10.5v26.7l-17.5-26.7h-10.5v43.1h10.4V34.2l17.5,26.5H332.4L332.4,60.7z
             M380.1,31.2c-1.2-4.4-3.5-7.8-7-10.3s-7.9-3.8-13-3.8c-4.2,0-8,0.9-11.3,2.8c-3.3,1.9-5.8,4.5-7.7,7.8s-2.8,7.2-2.8,11.4
            s0.9,8,2.8,11.3c1.9,3.3,4.5,5.8,7.8,7.7c3.3,1.9,7.2,2.8,11.4,2.8c3.6,0,6.8-0.7,9.7-2.1c2.9-1.4,5.2-3.2,7.1-5.5
            c1.9-2.2,3.1-4.7,4-7.3v-10h-22.8v7.7h13.3c-0.8,2.5-2.1,4.3-3.9,5.7c-1.8,1.3-4,2-6.6,2c-3.8,0-6.7-1.1-8.9-3.3
            c-2.2-2.2-3.2-5.3-3.2-9.3c0-3.7,1-6.7,3.1-8.9c2.1-2.2,4.8-3.3,8.2-3.3c2,0,3.7,0.4,5.1,1.2c1.4,0.8,2.5,1.9,3.3,3.3H380.1z"
      />
      <motion.path
        stroke={lightIcon ? "white" : "#2A2863"}
        strokeWidth="1"
        variants={draw}
        fill={lightIcon ? "white" : "#2A2863"}
        d="M415.6,53.1h-16.1l-2.6,7.6h-11l15.6-43.1h12.1l15.6,43.1H418L415.6,53.1z M412.9,45l-5.3-15.7L402.3,45H412.9
            z M456,60.7l-9-16.3h-2.5v16.3h-10.5V17.6h17.6c3.4,0,6.3,0.6,8.7,1.8c2.4,1.2,4.2,2.8,5.4,4.9s1.8,4.4,1.8,6.9
            c0,2.9-0.8,5.4-2.4,7.6c-1.6,2.2-4,3.9-7.2,4.8l10,17.1H456z M444.5,37h6.5c1.9,0,3.3-0.4,4.3-1.4c1-0.9,1.4-2.2,1.4-4
            c0-1.6-0.4-3-1.4-3.9c-1-0.9-2.4-1.4-4.3-1.4h-6.5V37z M471.6,17.6V26h11.4v34.7h10.5V26H505v-8.4H471.6z"
      />
      <motion.path
        stroke={lightIcon ? "white" : "#2A2863"}
        strokeWidth="1"
        variants={draw}
        fill={lightIcon ? "white" : "#2A2863"}
        d="M146.4,88.8h-7l-1.2,3.5H136l5.7-15.8h2.4l5.7,15.8h-2.2L146.4,88.8z M145.8,87.1l-2.9-8l-2.9,8H145.8z
             M160.4,80.3c0.8,0.4,1.4,1.1,1.9,1.8v-2.3h2.1v12.8c0,1.1-0.3,2.1-0.7,3.1c-0.5,0.9-1.1,1.6-2.1,2.1c-0.9,0.5-1.9,0.8-3.2,0.8
            c-1.7,0-3-0.4-4.1-1.1c-1.1-0.8-1.8-1.9-1.9-3.2h2c0.3,0.8,0.7,1.4,1.4,1.9c0.7,0.4,1.6,0.7,2.6,0.7c1.1,0,2.1-0.4,2.8-1.1
            c0.7-0.7,1.1-1.7,1.1-3v-2.6c-0.4,0.7-1.1,1.3-1.9,1.9c-0.8,0.5-1.8,0.7-2.8,0.7c-1.1,0-2.1-0.3-3-0.8s-1.6-1.3-2.1-2.3
            c-0.5-1-0.8-2.1-0.8-3.3c0-1.2,0.3-2.4,0.8-3.3c0.5-1,1.2-1.7,2.1-2.2c0.9-0.5,1.9-0.8,3-0.8C158.6,79.6,159.6,79.9,160.4,80.3z
             M161.6,83.6c-0.4-0.7-0.9-1.2-1.5-1.6c-0.6-0.4-1.3-0.5-2.1-0.5c-0.8,0-1.5,0.2-2.1,0.5c-0.6,0.4-1.1,0.9-1.5,1.6
            s-0.5,1.5-0.5,2.5c0,1,0.2,1.8,0.5,2.5c0.4,0.7,0.9,1.2,1.5,1.6c0.6,0.4,1.3,0.5,2.1,0.5c0.8,0,1.5-0.2,2.1-0.5
            c0.6-0.4,1.1-0.9,1.5-1.6c0.4-0.7,0.5-1.5,0.5-2.5C162.1,85.2,162,84.3,161.6,83.6z M179.1,86.9h-10c0.1,1.2,0.5,2.2,1.2,2.9
            c0.8,0.7,1.7,1.1,2.8,1.1c0.9,0,1.7-0.2,2.2-0.6c0.6-0.4,1.1-1,1.2-1.7h2.2c-0.4,1.2-1,2.2-2,2.9c-1,0.8-2.3,1.1-3.7,1.1
            c-1.1,0-2.2-0.3-3.2-0.8c-1-0.5-1.7-1.3-2.2-2.3c-0.5-1-0.8-2.1-0.8-3.4c0-1.3,0.3-2.5,0.8-3.4c0.5-1,1.2-1.8,2.2-2.2
            c1-0.5,2-0.8,3.3-0.8c1.1,0,2.2,0.3,3.2,0.8c0.9,0.5,1.6,1.2,2.1,2.1s0.7,1.9,0.7,3.1C179.1,86.1,179.1,86.4,179.1,86.9z
             M176.5,83.2c-0.4-0.5-0.8-1-1.4-1.3c-0.6-0.3-1.2-0.4-2-0.4c-1.1,0-1.9,0.4-2.6,1c-0.7,0.7-1.1,1.6-1.2,2.8h7.9
            C177,84.4,176.8,83.7,176.5,83.2z M187.9,79.6c-0.9,0-1.7,0.2-2.4,0.5s-1.2,0.9-1.7,1.5v-1.8h-2.1v12.6h2.1v-6.9c0-1.3,0.4-2.3,1-3
            c0.7-0.7,1.6-1.1,2.6-1.1c1.1,0,1.9,0.3,2.6,1c0.6,0.6,1,1.6,1,2.8v7h2V85c0-1.8-0.4-3.1-1.4-4C190.8,80.1,189.5,79.6,187.9,79.6z
             M195.6,86.1c0,1.3,0.3,2.5,0.8,3.4c0.4,1,1.2,1.8,2.1,2.3c1,0.5,2,0.8,3.2,0.8c1.5,0,2.8-0.4,3.8-1.1c1.1-0.8,1.7-1.9,2-3.1h-2.2
            c-0.2,0.8-0.6,1.4-1.2,1.9s-1.4,0.6-2.3,0.6c-1.2,0-2.2-0.4-2.9-1.2c-0.7-0.9-1.1-2-1.1-3.5c0-1.5,0.4-2.6,1.1-3.4
            c0.8-0.8,1.8-1.2,2.9-1.2c0.9,0,1.7,0.3,2.3,0.7s1.1,1,1.2,1.8h2.2c-0.3-1.3-1-2.4-1.9-3.1c-1-0.8-2.3-1.1-3.8-1.1
            c-1.2,0-2.3,0.3-3.2,0.8s-1.6,1.2-2.1,2.2C196,83.7,195.7,84.8,195.6,86.1z M221.7,86.9h-10c0.1,1.2,0.5,2.2,1.2,2.9
            c0.8,0.7,1.7,1.1,2.8,1.1c0.9,0,1.7-0.2,2.2-0.6c0.6-0.4,1.1-1,1.2-1.7h2.2c-0.4,1.2-1,2.2-2,2.9c-1,0.8-2.3,1.1-3.7,1.1
            c-1.1,0-2.2-0.3-3.2-0.8c-1-0.5-1.7-1.3-2.2-2.3s-0.8-2.1-0.8-3.4c0-1.3,0.3-2.5,0.8-3.4c0.5-1,1.2-1.8,2.2-2.2
            c1-0.5,2-0.8,3.3-0.8c1.1,0,2.2,0.3,3.2,0.8c0.9,0.5,1.6,1.2,2.1,2.1c0.5,0.9,0.7,1.9,0.7,3.1C221.8,86.1,221.7,86.4,221.7,86.9z
             M219.1,83.2c-0.4-0.5-0.8-1-1.4-1.3c-0.6-0.3-1.2-0.4-2-0.4c-1.1,0-1.9,0.4-2.6,1c-0.7,0.7-1.1,1.6-1.2,2.8h7.9
            C219.6,84.4,219.4,83.7,219.1,83.2z M250.5,76.4h-2.2l-3.5,13.4l-3.6-13.4h-2.3l-3.8,13.5l-3.4-13.5h-2.2l4.4,15.9h2.3l3.8-12.8
            l3.7,12.8h2.3L250.5,76.4L250.5,76.4z M264.2,86.9h-10c0.1,1.2,0.5,2.2,1.2,2.9c0.8,0.7,1.7,1.1,2.8,1.1c0.9,0,1.7-0.2,2.2-0.6
            c0.6-0.4,1.1-1,1.2-1.7h2.2c-0.4,1.2-1,2.2-2,2.9c-1,0.8-2.3,1.1-3.7,1.1c-1.1,0-2.2-0.3-3.2-0.8c-1-0.5-1.7-1.3-2.2-2.3
            s-0.8-2.1-0.8-3.4c0-1.3,0.3-2.5,0.8-3.4c0.5-1,1.2-1.8,2.2-2.2c1-0.5,2-0.8,3.3-0.8c1.1,0,2.2,0.3,3.2,0.8
            c0.9,0.5,1.6,1.2,2.1,2.1s0.7,1.9,0.7,3.1C264.3,86.1,264.2,86.4,264.2,86.9z M261.5,83.2c-0.4-0.5-0.8-1-1.4-1.3
            c-0.6-0.3-1.2-0.4-2-0.4c-1.1,0-1.9,0.4-2.6,1c-0.7,0.7-1.1,1.6-1.2,2.8h7.9C262.1,84.4,261.9,83.7,261.5,83.2z M271,80.3
            c0.8-0.4,1.8-0.7,2.8-0.7c1.1,0,2.1,0.3,3,0.8c0.9,0.5,1.6,1.3,2.1,2.2c0.5,1,0.8,2.1,0.8,3.3s-0.3,2.4-0.8,3.3
            c-0.5,1-1.2,1.8-2.1,2.3c-0.9,0.5-1.9,0.8-3,0.8c-1.1,0-2-0.3-2.8-0.7c-0.8-0.4-1.4-1.1-1.9-1.8v2.3H267V75.5h2.1v6.7
            C269.5,81.5,270.1,80.9,271,80.3z M276.9,83.6c-0.4-0.7-0.9-1.2-1.5-1.6s-1.3-0.5-2.1-0.5s-1.5,0.2-2.1,0.5
            c-0.6,0.4-1.1,0.9-1.6,1.6c-0.4,0.7-0.6,1.5-0.6,2.5c0,1,0.2,1.8,0.6,2.5c0.4,0.7,0.9,1.2,1.6,1.6c0.6,0.4,1.3,0.5,2.1,0.5
            s1.5-0.2,2.1-0.5c0.6-0.4,1.1-0.9,1.5-1.6c0.4-0.7,0.5-1.5,0.5-2.5C277.6,85.1,277.3,84.3,276.9,83.6z M299.8,86.9h-10
            c0.1,1.2,0.5,2.2,1.2,2.9c0.8,0.7,1.7,1.1,2.8,1.1c0.9,0,1.7-0.2,2.2-0.6c0.6-0.4,1.1-1,1.2-1.7h2.2c-0.4,1.2-1,2.2-2,2.9
            c-1,0.8-2.3,1.1-3.7,1.1c-1.1,0-2.2-0.3-3.2-0.8c-1-0.5-1.7-1.3-2.2-2.3s-0.8-2.1-0.8-3.4c0-1.3,0.3-2.5,0.8-3.4
            c0.5-1,1.2-1.8,2.2-2.2c1-0.5,2-0.8,3.3-0.8c1.1,0,2.2,0.3,3.2,0.8c0.9,0.5,1.6,1.2,2.1,2.1c0.5,0.9,0.7,1.9,0.7,3.1
            C299.9,86.1,299.9,86.4,299.8,86.9z M297.2,83.2c-0.4-0.5-0.8-1-1.4-1.3c-0.6-0.3-1.2-0.4-2-0.4c-1.1,0-1.9,0.4-2.6,1
            c-0.7,0.7-1.1,1.6-1.2,2.8h7.9C297.7,84.4,297.6,83.7,297.2,83.2z M308.3,81.6v-1.7h-3.3v-3.2h-2.1v3.2h-1.6v1.7h1.6V89
            c0,1.2,0.3,2.1,0.9,2.6c0.6,0.5,1.5,0.8,2.6,0.8h1.9v-1.8h-1.5c-0.6,0-1.1-0.1-1.3-0.4c-0.3-0.3-0.4-0.7-0.4-1.3v-7.4H308.3z
             M330.9,76.5l-5.8,13l-5.8-13H317v15.9h1.9V80.6l5.3,11.8h1.5l5.3-11.8v11.7h2.1V76.5H330.9z M336.6,82.7c0.5-1,1.2-1.7,2.1-2.2
            s1.9-0.8,3-0.8s2,0.3,2.8,0.7c0.8,0.4,1.4,1.1,1.8,1.8v-2.3h2.1v12.5h-2.1v-2.3c-0.4,0.7-1.1,1.3-1.9,1.9c-0.8,0.4-1.8,0.7-2.8,0.7
            c-1.1,0-2.1-0.3-3-0.8c-0.9-0.5-1.6-1.3-2.1-2.3s-0.8-2.1-0.8-3.3C335.9,84.8,336.1,83.7,336.6,82.7z M345.9,83.6
            c-0.4-0.7-0.9-1.2-1.5-1.6c-0.6-0.4-1.3-0.5-2.1-0.5s-1.5,0.2-2.1,0.5c-0.6,0.4-1.1,0.9-1.5,1.6c-0.4,0.7-0.5,1.5-0.5,2.5
            c0,1,0.2,1.8,0.5,2.5c0.4,0.7,0.9,1.2,1.5,1.6c0.6,0.4,1.3,0.5,2.1,0.5s1.5-0.2,2.1-0.5c0.6-0.4,1.1-0.9,1.5-1.6
            c0.4-0.7,0.5-1.5,0.5-2.5C346.5,85.2,346.2,84.3,345.9,83.6z M354.1,81.9v-2h-2.1v12.4h2.1v-6.8c0-2.6,1.2-3.8,3.5-3.8h0.6v-2.1
            c-1,0-1.8,0.3-2.5,0.6C355,80.6,354.5,81.2,354.1,81.9z M367.5,92.4h2.8l-5.9-6.3l5.9-6.3h-2.9l-4.8,5.6v-10h-2.1v16.8h2.1v-5.6
            L367.5,92.4L367.5,92.4z M383.7,86.9h-10c0.1,1.2,0.5,2.2,1.2,2.9c0.8,0.7,1.7,1.1,2.8,1.1c0.9,0,1.7-0.2,2.2-0.6
            c0.6-0.4,1.1-1,1.2-1.7h2.2c-0.4,1.2-1,2.2-2,2.9c-1,0.8-2.3,1.1-3.7,1.1c-1.1,0-2.2-0.3-3.2-0.8c-1-0.5-1.7-1.3-2.2-2.3
            c-0.5-1-0.8-2.1-0.8-3.4c0-1.3,0.3-2.5,0.8-3.4c0.5-1,1.2-1.8,2.2-2.2c1-0.5,2-0.8,3.3-0.8c1.1,0,2.2,0.3,3.2,0.8
            c0.9,0.5,1.6,1.2,2.1,2.1s0.7,1.9,0.7,3.1C383.7,86.1,383.7,86.4,383.7,86.9z M381,83.2c-0.4-0.5-0.8-1-1.4-1.3
            c-0.6-0.3-1.2-0.4-2-0.4c-1.1,0-1.9,0.4-2.6,1c-0.7,0.7-1.1,1.6-1.2,2.8h7.9C381.5,84.4,381.4,83.7,381,83.2z M392.1,81.6v-1.7
            h-3.3v-3.2h-2.1v3.2h-1.6v1.7h1.6V89c0,1.2,0.3,2.1,0.9,2.6c0.6,0.5,1.5,0.8,2.6,0.8h1.9v-1.8h-1.5c-0.6,0-1.1-0.1-1.3-0.4
            c-0.3-0.3-0.4-0.7-0.4-1.3v-7.4H392.1z M394.8,77.4c-0.3-0.3-0.4-0.6-0.4-1s0.2-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4s0.7,0.2,1,0.4
            c0.3,0.3,0.4,0.6,0.4,1s-0.2,0.7-0.4,1s-0.6,0.4-1,0.4S395.1,77.7,394.8,77.4z M396.8,79.9v12.5h-2.1V79.9H396.8z M406.4,79.6
            c-0.9,0-1.7,0.2-2.4,0.5c-0.7,0.4-1.2,0.9-1.7,1.5v-1.8h-2.1v12.6h2.1v-6.9c0-1.3,0.4-2.3,1-3c0.7-0.7,1.6-1.1,2.6-1.1
            c1.1,0,1.9,0.3,2.6,1c0.6,0.6,1,1.6,1,2.8v7h2V85c0-1.8-0.4-3.1-1.4-4C409.2,80.1,408,79.6,406.4,79.6z M423,80.3
            c0.8,0.4,1.4,1.1,1.9,1.8v-2.3h2.1v12.8c0,1.1-0.3,2.1-0.7,3.1c-0.5,0.9-1.1,1.6-2.1,2.1c-0.9,0.5-1.9,0.8-3.2,0.8
            c-1.7,0-3-0.4-4.1-1.1c-1.1-0.8-1.8-1.9-1.9-3.2h2c0.3,0.8,0.7,1.4,1.4,1.9c0.7,0.4,1.6,0.7,2.6,0.7c1.1,0,2.1-0.4,2.8-1.1
            c0.7-0.7,1.1-1.7,1.1-3v-2.6c-0.4,0.7-1.1,1.3-1.9,1.9s-1.8,0.7-2.8,0.7c-1.1,0-2.1-0.3-3-0.8s-1.6-1.3-2.1-2.3
            c-0.5-1-0.8-2.1-0.8-3.3c0-1.2,0.3-2.4,0.8-3.3c0.5-1,1.2-1.7,2.1-2.2c0.9-0.5,1.9-0.8,3-0.8C421.2,79.6,422.1,79.9,423,80.3z
             M424.2,83.6c-0.4-0.7-0.9-1.2-1.5-1.6s-1.3-0.5-2.1-0.5s-1.5,0.2-2.1,0.5s-1.1,0.9-1.5,1.6s-0.5,1.5-0.5,2.5c0,1,0.2,1.8,0.5,2.5
            c0.4,0.7,0.9,1.2,1.5,1.6c0.6,0.4,1.3,0.5,2.1,0.5s1.5-0.2,2.1-0.5c0.6-0.4,1.1-0.9,1.5-1.6c0.4-0.7,0.5-1.5,0.5-2.5
            C424.7,85.2,424.5,84.3,424.2,83.6z M445.9,77.4c1.2,0.6,2.2,1.6,2.9,2.7c0.7,1.2,1.1,2.6,1.1,4.2s-0.4,3-1.1,4.2
            c-0.7,1.2-1.7,2.1-2.9,2.7c-1.2,0.6-2.7,1-4.5,1h-4.9V76.5h4.9C443.1,76.5,444.6,76.8,445.9,77.4z M446,89.1
            c1.1-1.1,1.6-2.6,1.6-4.6s-0.5-3.5-1.7-4.7c-1.1-1.1-2.6-1.7-4.7-1.7h-2.9v12.5h2.9C443.4,90.6,445,90.1,446,89.1z M452.7,77.4
            c-0.3-0.3-0.4-0.6-0.4-1s0.2-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4s0.7,0.2,1,0.4c0.3,0.3,0.4,0.6,0.4,1s-0.2,0.7-0.4,1s-0.6,0.4-1,0.4
            S452.9,77.7,452.7,77.4z M454.7,79.9v12.5h-2.1V79.9H454.7z M466.1,80.3c0.8,0.4,1.4,1.1,1.9,1.8v-2.3h2.1v12.8
            c0,1.1-0.3,2.1-0.7,3.1c-0.5,0.9-1.1,1.6-2.1,2.1c-0.9,0.5-1.9,0.8-3.2,0.8c-1.7,0-3-0.4-4.1-1.1s-1.8-1.9-1.9-3.2h2
            c0.3,0.8,0.7,1.4,1.4,1.9c0.7,0.4,1.6,0.7,2.6,0.7c1.1,0,2.1-0.4,2.8-1.1c0.7-0.7,1.1-1.7,1.1-3v-2.6c-0.4,0.7-1.1,1.3-1.9,1.9
            c-0.8,0.5-1.8,0.7-2.8,0.7c-1.1,0-2.1-0.3-3-0.8s-1.6-1.3-2.1-2.3c-0.5-1-0.8-2.1-0.8-3.3c0-1.2,0.3-2.4,0.8-3.3
            c0.5-1,1.2-1.7,2.1-2.2c0.9-0.5,1.9-0.8,3-0.8C464.4,79.6,465.3,79.9,466.1,80.3z M467.4,83.6c-0.4-0.7-0.9-1.2-1.5-1.6
            s-1.3-0.5-2.1-0.5c-0.8,0-1.5,0.2-2.1,0.5s-1.1,0.9-1.5,1.6s-0.5,1.5-0.5,2.5c0,1,0.2,1.8,0.5,2.5c0.4,0.7,0.9,1.2,1.5,1.6
            c0.6,0.4,1.3,0.5,2.1,0.5c0.8,0,1.5-0.2,2.1-0.5c0.6-0.4,1.1-0.9,1.5-1.6c0.4-0.7,0.5-1.5,0.5-2.5C468,85.2,467.8,84.3,467.4,83.6z
             M473.6,77.4c-0.3-0.3-0.4-0.6-0.4-1s0.2-0.7,0.4-1c0.3-0.3,0.6-0.4,1-0.4c0.4,0,0.7,0.2,1,0.4c0.3,0.3,0.4,0.6,0.4,1
            s-0.2,0.7-0.4,1s-0.6,0.4-1,0.4C474.2,77.9,473.9,77.7,473.6,77.4z M475.7,79.9v12.5h-2.1V79.9H475.7z M484.9,81.6v-1.7h-3.3v-3.2
            h-2.1v3.2h-1.6v1.7h1.6V89c0,1.2,0.3,2.1,0.9,2.6c0.6,0.5,1.5,0.8,2.6,0.8h1.9v-1.8h-1.5c-0.6,0-1.1-0.1-1.3-0.4
            c-0.3-0.3-0.4-0.7-0.4-1.3v-7.4H484.9z M487.5,82.7c0.5-1,1.2-1.7,2.1-2.2s1.9-0.8,3-0.8s2,0.3,2.8,0.7s1.4,1.1,1.8,1.8v-2.3h2.1
            v12.5h-2.1v-2.3c-0.4,0.7-1.1,1.3-1.9,1.9c-0.8,0.4-1.8,0.7-2.8,0.7c-1.1,0-2.1-0.3-3-0.8c-0.9-0.5-1.6-1.3-2.1-2.3
            c-0.5-1-0.8-2.1-0.8-3.3C486.8,84.8,487,83.7,487.5,82.7z M496.7,83.6c-0.4-0.7-0.9-1.2-1.5-1.6s-1.3-0.5-2.1-0.5s-1.5,0.2-2.1,0.5
            s-1.1,0.9-1.5,1.6s-0.5,1.5-0.5,2.5c0,1,0.2,1.8,0.5,2.5c0.4,0.7,0.9,1.2,1.5,1.6c0.6,0.4,1.3,0.5,2.1,0.5s1.5-0.2,2.1-0.5
            c0.6-0.4,1.1-0.9,1.5-1.6c0.4-0.7,0.5-1.5,0.5-2.5C497.3,85.2,497.2,84.3,496.7,83.6z M502.9,75.5v16.9h2.1V75.5H502.9z"
      />
      <motion.path
        stroke="#00B1E3"
        strokeWidth="1"
        variants={draw}
        fill="#00B1E3"
        d="M108.7,33.2l-15,15c-4.3-14.7-17.8-25.4-33.8-25.4c-19.4,0-35.2,15.8-35.2,35.2c0,3.4,0.5,6.8,1.4,9.9
            L14.2,88.3C8.4,79.6,5,69.2,5,58C5,27.8,29.6,3.2,59.8,3.2C81.1,3.2,99.6,15.4,108.7,33.2z M93.7,67.8c-1.1,3.9-2.9,7.5-5.2,10.7
            c-3.9,5.4-9.3,9.7-15.6,12.2c-4,1.6-8.4,2.5-13,2.5s-9-0.9-13-2.5l-9.9,17.1c7,3.2,14.8,5,23,5c8.2,0,16-1.8,22.9-5
            c6-2.8,11.5-6.7,16.1-11.3c3.9-4,7.3-8.6,9.8-13.6L93.7,67.8z"
      />
      <motion.path
        stroke={lightIcon ? "white" : "#2A2863"}
        strokeWidth="1"
        variants={draw}
        fill={lightIcon ? "white" : "#2A2863"}
        d="M46.8,90.7C46.8,90.7,46.8,90.7,46.8,90.7l13-22.4l13,22.4c0,0,0,0,0,0c6.3-2.5,11.7-6.8,15.6-12.2L66.3,40.4
            H53.4L24.5,90.1l-3.7,6.4l0,0l-5.4,9.2L9,116.8h22.7l5.2-9l0,0c0,0,0,0,0,0L46.8,90.7z"
      />
    </motion.svg>
  );
}

export default Loader;
