import { ArrowrightIcon } from "../icons";
import CustomCheckbox from "./contact_form_comp/CustomCheckbox";
import { motion } from "framer-motion";
import { formVariants } from "../animation_variants";
import PhoneField from "./contact_form_comp/PhoneField";
import { memo, useState } from "react";
import { toast } from "react-toastify";
import CustomeInput from "../utils/CustomeInput";
import InputList from "../utils/InputList";
import { usePostContactMutation } from "../redux/apiCalls/apiSlice";

const projects = [
  "Nature de votre projet",
  "Design",
  "Développement Web",
  "Marketing Digital",
  "Application Mobile",
  "Réseaux et Serveurs",
  "Hébergement Web",
  "IOT",
  "Dév Spécifique",
  "DevOps Strategy",
];
const prices = [
  "Budget",
  "0 à 500€",
  "500€ à 1000€",
  "1000€ à 5000€",
  "5000€ à 10000€",
  "plus de 10000€",
];

function ContactForm({ title, description, buttonTitle }) {
  const [activeProjects, setActiveProjects] = useState("");
  const [activePrices, setActivePrice] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [message, setMessage] = useState("");
  const [isClientAgree, setIsClientAgree] = useState(false);
  const [error, setError] = useState("");
  const [sendContact] = usePostContactMutation();

  const data = {
    fullName: fullName,
    tel: phone,
    email: email,
    entrepriseName: companyName,
    projetNature: activeProjects,
    budget: activePrices,
    message: message,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isClientAgree) {
      return setError("S'il vous plaît accepter la condition!");
    }
    try {
      if (isClientAgree && activePrices && activeProjects) {
        await sendContact({ data: data }).unwrap();
        toast.success("Votre contact a été enregistré avec succès.");
        setError("");
        setActivePrice("");
        setActiveProjects("");
        setPhone("+212");
        setEmail("");
        setFullName("");
        setCompanyName("");
        setMessage("");
        setIsClientAgree(false);
      } else
        toast.error("Veuillez renseigner les champs nature projet/budget.");
    } catch (error) {
      console.log(error);
      toast.error("une erreur est survenu veuillez re-essayer");
      setError("");
    }
  };

  return (
    <motion.form
      onSubmit={handleSubmit}
      variants={formVariants(true)}
      initial="hidden"
      whileInView="visible"
      exit="visible"
      viewport={{ once: true }}
      className="relative md:absolute md:-top-5 md:right-[5%] bg-mainColor md:w-96 px-5 md:px-5 py-5 text-white text-sm space-y-4"
    >
      <h3 className={`capitalize font-bold pt-4 text-lg`}>{title}</h3>
      <p className="text-sm font-light mb-4">{description}</p>

      <CustomeInput
        value={fullName}
        setValue={setFullName}
        type={"text"}
        name={"Votre nom et prénom *"}
      />
      <PhoneField value={phone} setValue={setPhone} placeholder="Téléphone*" />
      <CustomeInput
        value={email}
        setValue={setEmail}
        type={"email"}
        name={"Email *"}
      />
      <CustomeInput
        value={companyName}
        setValue={setCompanyName}
        type={"text"}
        name={"Votre entreprise"}
      />
      <InputList select={0} list={projects} setActive={setActiveProjects} />
      <InputList select={0} list={prices} setActive={setActivePrice} />
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="w-full min-h-8 resize-none overflow-hidden placeholder:text-white outline-none bg-transparent border-b p-1 border-white tracking-widest duration-300 font-medium ease-out focus:border-secondColor/50"
        placeholder="Ajouter un message"
      />

      {error && <p className="text-sm text-center text-red-500">{error}</p>}

      <CustomCheckbox
        description="J'accepte que mes données soumises soient collectées et stockées."
        label="agree"
        value={isClientAgree}
        setValue={setIsClientAgree}
      />

      <button
        type="submit"
        className="w-full capitalize pointer-events-non duration-200 tracking-[.1em] hover:tracking-[.2em] font-semibold text-lg hover:text-secondColor/50 flex group justify-between items-center"
      >
        {buttonTitle}
        <ArrowrightIcon
          width="40"
          color="white"
          className="group-hover:fill-secondColor/50"
        />
      </button>
    </motion.form>
  );
}

export default memo(ContactForm);
