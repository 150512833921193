import { useEffect, useRef, useState } from "react";

function StaticsNum() {
  const statics = useRef();
  const [clientH, setClientH] = useState(0);
  const [projects, setProjects] = useState(0);
  const [compétents, setCompétents] = useState(0);
  const [postes, setPostes] = useState(0);

  useEffect(() => {
    let timer1;
    let timer2;
    let timer3;
    let timer4;

    const observer = new IntersectionObserver(
      (statics) => {
        if (statics[0].isIntersecting) {
          timer1 = setInterval(() => {
            setClientH((prev) => {
              if (prev < 10) return prev + 1;
              clearInterval(timer1);
              return 10;
            });
          }, 300);
          timer2 = setInterval(() => {
            setProjects((prev) => {
              if (prev < 12) return prev + 1;
              clearInterval(timer2);
              return 12;
            });
          }, 250);
          timer3 = setInterval(() => {
            setCompétents((prev) => {
              if (prev < 11) return prev + 1;
              clearInterval(timer3);
              return 11;
            });
          }, 225);
          timer4 = setInterval(() => {
            setPostes((prev) => {
              if (prev < 90) return prev + 1;
              clearInterval(timer4);
              return 90;
            });
          }, 50);
        }
      },
      {
        threshold: 0.5,
      }
    );

    observer.observe(statics.current);

    return () => {
      clearInterval(timer1);
      clearInterval(timer2);
      clearInterval(timer3);
      clearInterval(timer4);
    };
  }, []);

  return (
    <div
      ref={statics}
      className=" px-5 md:px-10 lg:px-24 py-20 bg-black text-white flex flex-wrap justify-center md:justify-between tracking-widest"
    >
      <div className="w-52 mb-4 text-center md:text-left">
        <span className="bg-white inline-block w-12 h-0.5" />
        <h2 className="capitalize text-lg my-4">
          clients <br /> heureux
        </h2>
        <span className="text-4xl font-bold">{clientH}</span>
      </div>
      <div className="w-52 mb-4 text-center md:text-left">
        <span className="bg-white inline-block w-12 h-0.5" />
        <h2 className="capitalize text-lg my-4">
          projets <br /> achevés
        </h2>
        <span className="text-4xl font-bold">{projects}</span>
      </div>

      <div className="w-52 mb-4 text-center md:text-left">
        <span className="bg-white inline-block w-12 h-0.5" />
        <h2 className="capitalize text-lg my-4">
          experts <br /> compétents
        </h2>
        <span className="text-4xl font-bold">{compétents}</span>
      </div>

      <div className="w-52 mb-4 text-center md:text-left">
        <span className="bg-white inline-block w-12 h-0.5" />
        <h2 className="capitalize text-lg my-4">
          postes <br /> media
        </h2>
        <span className="text-4xl font-bold">+{postes}</span>
      </div>
    </div>
  );
}

export default StaticsNum;
