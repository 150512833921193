import { memo } from "react";

export const FixPhoneIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M60.46,19.09C59.8,12.8,54.46,7.88,48,7.88H20.17c-1.57-2.64-4.44-4.42-7.72-4.42c-4.95,0-8.99,4.03-8.99,8.99
	v7.96v21.64V48c0,6.91,5.62,12.53,12.53,12.53h32c6.91,0,12.53-5.62,12.53-12.53V20.42c0-0.22-0.01-0.45-0.02-0.67
	c0.01-0.06,0.02-0.12,0.02-0.18C60.53,19.4,60.5,19.24,60.46,19.09z M56.37,17.7H44.45c-1.91,0-3.47-1.56-3.47-3.47v-2.62H48
	C51.91,11.62,55.22,14.18,56.37,17.7z M7.2,12.45c0-2.9,2.36-5.25,5.25-5.25c2.9,0,5.25,2.36,5.25,5.25v29.6
	c0,2.9-2.36,5.25-5.25,5.25c-2.9,0-5.25-2.36-5.25-5.25V20.42V12.45z M48,56.8H16c-4.37,0-7.99-3.2-8.67-7.38
	c1.46,1.02,3.22,1.62,5.13,1.62c4.95,0,8.99-4.03,8.99-8.99v-29.6c0-0.28-0.02-0.56-0.04-0.84h15.86v2.62c0,3.97,3.23,7.2,7.2,7.2
	H56.8V48C56.8,52.85,52.85,56.8,48,56.8z M30.9,49.17c0,1.03-0.84,1.87-1.87,1.87c-1.03,0-1.87-0.84-1.87-1.87
	c0-1.03,0.84-1.87,1.87-1.87C30.07,47.31,30.9,48.14,30.9,49.17z M40.98,49.17c0,1.03-0.84,1.87-1.87,1.87
	c-1.03,0-1.87-0.84-1.87-1.87c0-1.03,0.84-1.87,1.87-1.87C40.15,47.31,40.98,48.14,40.98,49.17z M51.06,49.17
	c0,1.03-0.84,1.87-1.87,1.87c-1.03,0-1.87-0.84-1.87-1.87c0-1.03,0.84-1.87,1.87-1.87C50.22,47.31,51.06,48.14,51.06,49.17z
	 M30.9,39.12c0,1.03-0.84,1.87-1.87,1.87c-1.03,0-1.87-0.84-1.87-1.87c0-1.03,0.84-1.87,1.87-1.87
	C30.07,37.25,30.9,38.09,30.9,39.12z M40.98,39.12c0,1.03-0.84,1.87-1.87,1.87c-1.03,0-1.87-0.84-1.87-1.87
	c0-1.03,0.84-1.87,1.87-1.87C40.15,37.25,40.98,38.09,40.98,39.12z M51.06,39.12c0,1.03-0.84,1.87-1.87,1.87
	c-1.03,0-1.87-0.84-1.87-1.87c0-1.03,0.84-1.87,1.87-1.87C50.22,37.25,51.06,38.09,51.06,39.12z M30.9,29.06
	c0,1.03-0.84,1.87-1.87,1.87c-1.03,0-1.87-0.84-1.87-1.87c0-1.03,0.84-1.87,1.87-1.87C30.07,27.19,30.9,28.03,30.9,29.06z
	 M40.98,29.06c0,1.03-0.84,1.87-1.87,1.87c-1.03,0-1.87-0.84-1.87-1.87c0-1.03,0.84-1.87,1.87-1.87
	C40.15,27.19,40.98,28.03,40.98,29.06z M51.06,29.06c0,1.03-0.84,1.87-1.87,1.87c-1.03,0-1.87-0.84-1.87-1.87
	c0-1.03,0.84-1.87,1.87-1.87C50.22,27.19,51.06,28.03,51.06,29.06z"
      />
    </svg>
  );
});
export const PhoneIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M56.69,42.54l-9.22-5.36c-0.01,0-0.05-0.03-0.06-0.03c-0.56-0.32-1.09-0.54-1.63-0.69
   c-2.54-0.67-5.17,0-7.03,1.82l-0.05,0.04c-0.54,0.47-1.34,0.52-1.94,0.12c-4.14-2.75-8.25-6.86-11.27-11.26
   c-0.43-0.62-0.35-1.47,0.19-2c2.32-2.31,2.82-5.91,1.21-8.75c0-0.01-0.03-0.05-0.03-0.06l-5.43-9.28c0-0.01-0.04-0.06-0.04-0.07
   c-1.32-2.17-3.71-3.51-6.25-3.51c0,0,0,0,0,0c-1.83,0-3.55,0.67-4.85,1.9L10.2,5.48c-0.03,0.03-0.06,0.05-0.08,0.08l-3.1,3.35
   c-3.43,3.44-4.49,8.75-2.59,12.88c3.88,9.01,8.94,16.67,15.06,22.77C27.47,52.5,36.3,57.1,42.32,59.56
   c1.37,0.62,2.86,0.93,4.42,0.93c3.15,0,6.24-1.29,8.5-3.54l3.11-3.1c1.55-1.54,2.32-3.79,2.06-6.02
   C60.13,45.47,58.78,43.54,56.69,42.54z M55.71,51.21l-3.11,3.1c-1.56,1.56-3.7,2.45-5.86,2.45c-1.03,0-2.01-0.21-2.91-0.61
   c-0.02-0.01-0.05-0.02-0.07-0.03c-5.7-2.33-14.08-6.68-21.63-14.21c-5.78-5.76-10.58-13.03-14.29-21.64
   c-1.28-2.79-0.52-6.38,1.85-8.74c0.02-0.02,0.03-0.03,0.05-0.05l3.08-3.32l0.03-0.03c0.59-0.56,1.4-0.87,2.28-0.87c0,0,0,0,0,0
   c1.24,0,2.42,0.66,3.07,1.74l5.44,9.29c0.78,1.38,0.54,3.13-0.6,4.26c-1.82,1.81-2.08,4.65-0.64,6.76
   c3.29,4.78,7.76,9.25,12.28,12.26c2.02,1.34,4.69,1.16,6.49-0.43l0.08-0.07c0.03-0.02,0.05-0.05,0.07-0.07
   c0.93-0.92,2.2-1.25,3.49-0.9c0.23,0.06,0.47,0.17,0.77,0.34l9.29,5.4c0.05,0.03,0.1,0.06,0.16,0.08c0.94,0.44,1.53,1.28,1.66,2.38
   c0,0,0,0,0,0C56.83,49.37,56.46,50.47,55.71,51.21z M32.38,9.1c-0.57-0.86-0.35-2.02,0.51-2.59c2.92-1.96,6.33-3,9.87-3
   c9.79,0,17.75,7.96,17.75,17.75c0,3.54-1.04,6.95-3,9.87c-0.36,0.54-0.95,0.83-1.55,0.83c-0.36,0-0.72-0.1-1.04-0.32
   c-0.86-0.57-1.08-1.73-0.51-2.59c1.55-2.3,2.36-5,2.36-7.79c0-7.73-6.29-14.02-14.02-14.02c-2.8,0-5.49,0.82-7.79,2.37
   C34.11,10.18,32.95,9.95,32.38,9.1z M49.52,24.53c0.49-1.03,0.74-2.13,0.74-3.27c0-4.14-3.37-7.51-7.51-7.51
   c-1.14,0-2.24,0.25-3.27,0.74c-0.93,0.45-2.04,0.06-2.49-0.87c-0.45-0.93-0.05-2.04,0.87-2.49c1.54-0.74,3.18-1.11,4.89-1.11
   c6.2,0,11.24,5.04,11.24,11.24c0,1.71-0.37,3.35-1.11,4.89c-0.32,0.67-0.99,1.06-1.68,1.06c-0.27,0-0.55-0.06-0.81-0.18
   C49.47,26.57,49.08,25.46,49.52,24.53z"
      />
    </svg>
  );
});
export const LocationIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M32,3.47c-12.59,0-22.83,10.24-22.83,22.83c0,0.26,0.01,0.51,0.01,0.77C9.67,48.5,30.24,59.84,31.11,60.31
   c0.28,0.15,0.58,0.22,0.89,0.22s0.61-0.07,0.89-0.22c0.88-0.47,21.44-11.81,21.93-33.25c0.01-0.25,0.01-0.51,0.01-0.77
   C54.83,13.71,44.59,3.47,32,3.47z M51.08,26.98C50.7,43.83,35.82,54.13,32,56.5c-3.82-2.36-18.7-12.63-19.08-29.52
   c0-0.23-0.01-0.45-0.01-0.68C12.91,15.77,21.47,7.2,32,7.2s19.09,8.57,19.09,19.09C51.09,26.52,51.09,26.75,51.08,26.98z M32,15.57
   c-5.91,0-10.73,4.81-10.73,10.73S26.09,37.02,32,37.02s10.73-4.81,10.73-10.73S37.92,15.57,32,15.57z M32,33.29
   c-3.86,0-6.99-3.14-6.99-6.99S28.14,19.3,32,19.3s6.99,3.14,6.99,6.99S35.86,33.29,32,33.29z"
      />
    </svg>
  );
});
export const EnvolopeIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M48,8.79H16c-6.91,0-12.53,5.62-12.53,12.53v21.35c0,6.91,5.62,12.53,12.53,12.53h32
	c6.91,0,12.53-5.62,12.53-12.53V21.32C60.53,14.41,54.91,8.79,48,8.79z M56.8,42.68c0,4.85-3.95,8.8-8.8,8.8H16
	c-4.85,0-8.8-3.95-8.8-8.8V21.32c0-4.85,3.95-8.8,8.8-8.8h32c4.85,0,8.8,3.95,8.8,8.8V42.68z M51.94,19.09
	c0.51,0.9,0.19,2.04-0.7,2.54L32.92,32.02c-0.29,0.16-0.6,0.24-0.92,0.24s-0.63-0.08-0.92-0.24L12.76,21.63
	c-0.9-0.51-1.21-1.65-0.7-2.54c0.51-0.9,1.65-1.21,2.54-0.7L32,28.25l17.4-9.86C50.3,17.88,51.44,18.19,51.94,19.09z"
      />
    </svg>
  );
});
export const DesignIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <g>
        <g>
          <g>
            <path
              d="M34.18,57H7.63C7.28,57,7,56.72,7,56.37V29.82c0-0.26,0.15-0.49,0.39-0.58c0.24-0.1,0.51-0.04,0.69,0.14
               l26.54,26.54c0.18,0.18,0.23,0.45,0.14,0.69C34.66,56.85,34.43,57,34.18,57z M8.26,55.74h24.39L8.26,31.35V55.74z"
            />
          </g>
          <g>
            <path
              d="M20.9,50.05h-6.32c-0.35,0-0.63-0.28-0.63-0.63V43.1c0-0.26,0.15-0.49,0.39-0.58
               c0.24-0.1,0.51-0.04,0.69,0.14l6.32,6.32c0.18,0.18,0.23,0.45,0.14,0.69C21.39,49.89,21.16,50.05,20.9,50.05z M15.22,48.78h4.16
               l-4.16-4.16V48.78z"
            />
          </g>
          <g>
            <path
              d="M56.37,52H32.73c-0.35,0-0.63-0.28-0.63-0.63V44.4c0-0.35,0.28-0.63,0.63-0.63h23.64
               c0.35,0,0.63,0.28,0.63,0.63v6.97C57,51.72,56.72,52,56.37,52z M33.36,50.74h22.38v-5.71H33.36V50.74z"
            />
          </g>
          <g>
            <path
              d="M52.78,47.67c-0.35,0-0.63-0.28-0.63-0.63V44.4c0-0.35,0.28-0.63,0.63-0.63c0.35,0,0.63,0.28,0.63,0.63v2.64
               C53.41,47.39,53.13,47.67,52.78,47.67z"
            />
          </g>
          <g>
            <path
              d="M49.5,47.67c-0.35,0-0.63-0.28-0.63-0.63V44.4c0-0.35,0.28-0.63,0.63-0.63c0.35,0,0.63,0.28,0.63,0.63v2.64
               C50.13,47.39,49.85,47.67,49.5,47.67z"
            />
          </g>
          <g>
            <path
              d="M46.23,47.67c-0.35,0-0.63-0.28-0.63-0.63V44.4c0-0.35,0.28-0.63,0.63-0.63c0.35,0,0.63,0.28,0.63,0.63v2.64
               C46.86,47.39,46.58,47.67,46.23,47.67z"
            />
          </g>
          <g>
            <path
              d="M42.96,47.67c-0.35,0-0.63-0.28-0.63-0.63V44.4c0-0.35,0.28-0.63,0.63-0.63c0.35,0,0.63,0.28,0.63,0.63v2.64
               C43.59,47.39,43.3,47.67,42.96,47.67z"
            />
          </g>
          <g>
            <path
              d="M39.68,47.67c-0.35,0-0.63-0.28-0.63-0.63V44.4c0-0.35,0.28-0.63,0.63-0.63c0.35,0,0.63,0.28,0.63,0.63v2.64
               C40.31,47.39,40.03,47.67,39.68,47.67z"
            />
          </g>
          <g>
            <path
              d="M36.41,47.67c-0.35,0-0.63-0.28-0.63-0.63V44.4c0-0.35,0.28-0.63,0.63-0.63c0.35,0,0.63,0.28,0.63,0.63v2.64
               C37.04,47.39,36.76,47.67,36.41,47.67z"
            />
          </g>
          <g>
            <path
              d="M24.69,12.55c-0.35,0-0.63-0.28-0.63-0.63V10.6c0-0.35,0.28-0.63,0.63-0.63c0.35,0,0.63,0.28,0.63,0.63v1.32
               C25.32,12.27,25.04,12.55,24.69,12.55z"
            />
          </g>
          <g>
            <path
              d="M21.41,12.55c-0.35,0-0.63-0.28-0.63-0.63V10.6c0-0.35,0.28-0.63,0.63-0.63c0.35,0,0.63,0.28,0.63,0.63v1.32
               C22.04,12.27,21.76,12.55,21.41,12.55z"
            />
          </g>
          <g>
            <path
              d="M18.14,12.55c-0.35,0-0.63-0.28-0.63-0.63V10.6c0-0.35,0.28-0.63,0.63-0.63c0.35,0,0.63,0.28,0.63,0.63v1.32
               C18.77,12.27,18.49,12.55,18.14,12.55z"
            />
          </g>
          <g>
            <path
              d="M49.5,45.03c-0.35,0-0.63-0.28-0.63-0.63V16.46l-8.2-8.2H17.59c-1.04,0-1.89,0.85-1.89,1.89v23.36
               c0,0.35-0.28,0.63-0.63,0.63c-0.35,0-0.63-0.28-0.63-0.63V10.16c0-1.74,1.42-3.16,3.16-3.16h23.34c0.17,0,0.33,0.07,0.45,0.18
               l8.57,8.57c0.12,0.12,0.18,0.28,0.18,0.45V44.4C50.13,44.74,49.85,45.03,49.5,45.03z"
            />
          </g>
          <g>
            <path
              d="M32.73,48.51h-3.82c-0.35,0-0.63-0.28-0.63-0.63s0.28-0.63,0.63-0.63h3.82c0.35,0,0.63,0.28,0.63,0.63
               S33.08,48.51,32.73,48.51z"
            />
          </g>
          <g>
            <path
              d="M49.5,16.83h-6.05c-1.74,0-3.16-1.42-3.16-3.16V7.63c0-0.26,0.15-0.49,0.39-0.58
               c0.24-0.1,0.51-0.04,0.69,0.14l8.57,8.57c0.18,0.18,0.23,0.45,0.14,0.69C49.99,16.68,49.76,16.83,49.5,16.83z M41.56,9.16v4.52
               c0,1.04,0.85,1.89,1.89,1.89h4.52L41.56,9.16z"
            />
          </g>
          <g>
            <path
              d="M32.29,35.79c-0.11,0-0.22-0.03-0.32-0.08l-6.96-4.02c-0.2-0.11-0.32-0.32-0.32-0.55v-8.03
               c0-0.23,0.12-0.43,0.32-0.55l6.96-4.02c0.2-0.11,0.44-0.11,0.63,0l6.96,4.02c0.2,0.11,0.32,0.32,0.32,0.55v8.03
               c0,0.23-0.12,0.43-0.32,0.55L32.6,35.7C32.5,35.76,32.39,35.79,32.29,35.79z M25.96,30.77l6.33,3.65l6.33-3.65v-7.3l-6.33-3.65
               l-6.33,3.65V30.77z M39.24,31.14L39.24,31.14L39.24,31.14z"
            />
          </g>
          <g>
            <path
              d="M32.28,27.75c-0.11,0-0.22-0.03-0.31-0.08l-6.96-4.02c-0.3-0.17-0.41-0.56-0.23-0.86
               c0.17-0.3,0.56-0.41,0.86-0.23l6.96,4.02c0.3,0.17,0.41,0.56,0.23,0.86C32.72,27.64,32.5,27.75,32.28,27.75z"
            />
          </g>
          <g>
            <path
              d="M32.29,27.75c-0.22,0-0.43-0.11-0.55-0.32c-0.17-0.3-0.07-0.69,0.23-0.86l6.96-4.02
               c0.3-0.18,0.69-0.07,0.86,0.23c0.17,0.3,0.07,0.69-0.23,0.86l-6.96,4.02C32.5,27.73,32.39,27.75,32.29,27.75z"
            />
          </g>
          <g>
            <path
              d="M32.29,35.79c-0.35,0-0.63-0.28-0.63-0.63v-8.03c0-0.35,0.28-0.63,0.63-0.63c0.35,0,0.63,0.28,0.63,0.63
               v8.03C32.92,35.5,32.63,35.79,32.29,35.79z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
});
export const TelevisionIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      color={color}
      width={width}
      height={height}
      className={className}
    >
      <g>
        <g>
          <g>
            <path
              d="M56.37,47.89H7.63C7.28,47.89,7,47.6,7,47.25V13.2c0-0.35,0.28-0.63,0.63-0.63h48.74
               c0.35,0,0.63,0.28,0.63,0.63v34.05C57,47.6,56.72,47.89,56.37,47.89z M8.26,46.62h47.47V13.83H8.26V46.62z"
            />
          </g>
          <g>
            <path
              d="M43.77,41.81H13.71c-0.35,0-0.63-0.28-0.63-0.63V17.88c0-0.35,0.28-0.63,0.63-0.63h30.07
               c0.35,0,0.63,0.28,0.63,0.63v23.29C44.4,41.53,44.12,41.81,43.77,41.81z M14.34,40.55h28.8V18.52h-28.8V40.55z"
            />
          </g>
          <g>
            <path
              d="M50.29,36.5c-1.52,0-2.76-1.24-2.76-2.76c0-1.52,1.24-2.76,2.76-2.76c1.52,0,2.76,1.24,2.76,2.76
               C53.05,35.26,51.81,36.5,50.29,36.5z M50.29,32.25c-0.82,0-1.49,0.67-1.49,1.49c0,0.82,0.67,1.49,1.49,1.49
               c0.82,0,1.49-0.67,1.49-1.49C51.78,32.92,51.11,32.25,50.29,32.25z"
            />
          </g>
          <g>
            <path
              d="M50.29,43.94c-1.52,0-2.76-1.24-2.76-2.76s1.24-2.76,2.76-2.76c1.52,0,2.76,1.24,2.76,2.76
               S51.81,43.94,50.29,43.94z M50.29,39.69c-0.82,0-1.49,0.67-1.49,1.49s0.67,1.49,1.49,1.49c0.82,0,1.49-0.67,1.49-1.49
               S51.11,39.69,50.29,39.69z"
            />
          </g>
          <g>
            <path
              d="M13.71,51.43c-0.35,0-0.63-0.28-0.63-0.63v-3.54c0-0.35,0.28-0.63,0.63-0.63c0.35,0,0.63,0.28,0.63,0.63
               v3.54C14.34,51.15,14.06,51.43,13.71,51.43z"
            />
          </g>
          <g>
            <path
              d="M50.29,51.43c-0.35,0-0.63-0.28-0.63-0.63v-3.54c0-0.35,0.28-0.63,0.63-0.63c0.35,0,0.63,0.28,0.63,0.63
               v3.54C50.92,51.15,50.64,51.43,50.29,51.43z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
});
export const ChatIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      color={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M21.34,22.38c0-0.42,0.34-0.76,0.76-0.76h6.07h1.52h5.67c0.42,0,0.76,0.34,0.76,0.76
   c0,0.42-0.34,0.76-0.76,0.76h-5.67h-1.52H22.1C21.68,23.14,21.34,22.8,21.34,22.38z M17.15,18.85c0.42,0,0.76-0.34,0.76-0.76
   s-0.34-0.76-0.76-0.76s-0.76,0.34-0.76,0.76S16.73,18.85,17.15,18.85z M17.15,23.14c0.42,0,0.76-0.34,0.76-0.76
   s-0.34-0.76-0.76-0.76s-0.76,0.34-0.76,0.76S16.73,23.14,17.15,23.14z M12.19,27.76V12.7c0-0.75,0.61-1.36,1.36-1.36h14.62V8.28
   c0-0.75,0.61-1.36,1.36-1.36h24.65c0.75,0,1.36,0.61,1.36,1.36v15.05c0,0.75-0.61,1.36-1.36,1.36h-2.54v3.52
   c0,0.46-0.37,0.78-0.78,0.78c-0.14,0-0.29-0.04-0.43-0.13l-6.2-4.17h-4.66v3.07c0,0.75-0.61,1.36-1.36,1.36h-14.7l-6.2,4.17
   c-0.13,0.09-0.28,0.13-0.43,0.13c-0.4,0-0.78-0.32-0.78-0.78v-3.52h-2.55C12.8,29.11,12.19,28.51,12.19,27.76z M29.69,11.35h8.51
   c0.75,0,1.36,0.61,1.36,1.36v0.2h6.07c0.42,0,0.76,0.34,0.76,0.76c0,0.42-0.34,0.76-0.76,0.76h-6.07v2.76h6.07
   c0.42,0,0.76,0.34,0.76,0.76c0,0.42-0.34,0.76-0.76,0.76h-6.07v4.45h4.66c0.3,0,0.6,0.09,0.85,0.26l5.04,3.38v-2.12
   c0-0.84,0.68-1.52,1.52-1.52h2.38V8.45H29.69V11.35z M13.72,27.59h2.38c0.84,0,1.52,0.68,1.52,1.52v2.12l5.04-3.38
   c0.25-0.17,0.55-0.26,0.85-0.26h14.53v-2.9v-1.52v-4.45v-1.52v-2.76v-1.52v-0.03h-8.34h-1.52H13.72V27.59z M22.1,18.85h6.07h1.52
   h5.67c0.16,0,0.3-0.05,0.43-0.13c0.2-0.13,0.33-0.37,0.33-0.63c0-0.42-0.34-0.76-0.76-0.76h-3.43h-2.24h-1.52H22.1
   c-0.42,0-0.76,0.34-0.76,0.76C21.34,18.51,21.68,18.85,22.1,18.85z M50.58,14.43c0.42,0,0.76-0.34,0.76-0.76s-0.34-0.76-0.76-0.76
   c-0.42,0-0.76,0.34-0.76,0.76S50.16,14.43,50.58,14.43z M50.58,18.71c0.42,0,0.76-0.34,0.76-0.76s-0.34-0.76-0.76-0.76
   c-0.42,0-0.76,0.34-0.76,0.76S50.16,18.71,50.58,18.71z M57.07,57.07H36.13c0-4.8,3.23-8.84,7.63-10.08c-1.76-1-2.96-2.88-2.96-5.06
   c0-3.21,2.6-5.8,5.8-5.8c3.21,0,5.8,2.6,5.8,5.8c0,2.17-1.19,4.06-2.96,5.06C53.85,48.23,57.07,52.28,57.07,57.07z M46.6,46.22
   c2.36,0,4.28-1.92,4.28-4.28s-1.92-4.28-4.28-4.28c-2.36,0-4.28,1.92-4.28,4.28S44.24,46.22,46.6,46.22z M37.78,55.55h17.64
   c-0.73-4.21-4.4-7.43-8.82-7.43S38.51,51.34,37.78,55.55z M27.87,57.07H6.93c0-4.8,3.23-8.84,7.63-10.08
   c-1.76-1-2.96-2.88-2.96-5.06c0-3.21,2.6-5.8,5.8-5.8s5.8,2.6,5.8,5.8c0,2.17-1.19,4.06-2.96,5.06
   C24.65,48.23,27.87,52.28,27.87,57.07z M17.4,46.22c2.36,0,4.28-1.92,4.28-4.28s-1.92-4.28-4.28-4.28s-4.28,1.92-4.28,4.28
   S15.04,46.22,17.4,46.22z M26.22,55.55c-0.73-4.21-4.4-7.43-8.82-7.43s-8.09,3.21-8.82,7.43H26.22z"
      />
    </svg>
  );
});
export const CodingIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
    >
      <path
        d="M38.75,35.38H25.25c-1.28,0-2.31,1.03-2.31,2.31v10.66c0,1.28,1.03,2.31,2.31,2.31h13.51
   c1.28,0,2.31-1.03,2.31-2.31V37.69C41.06,36.41,40.03,35.38,38.75,35.38z M39.54,48.34c0,0.43-0.35,0.79-0.79,0.79H25.25
   c-0.43,0-0.79-0.35-0.79-0.79V37.69c0-0.43,0.35-0.79,0.79-0.79h13.51c0.43,0,0.79,0.35,0.79,0.79V48.34z M46.56,17.13H17.44
   c-1.28,0-2.31,1.03-2.31,2.31v10.66c0,1.28,1.03,2.31,2.31,2.31h29.12c1.28,0,2.31-1.03,2.31-2.31V19.44
   C48.87,18.16,47.83,17.13,46.56,17.13z M47.35,30.09c0,0.43-0.35,0.79-0.79,0.79H17.44c-0.43,0-0.79-0.35-0.79-0.79V19.44
   c0-0.43,0.35-0.79,0.79-0.79h29.12c0.43,0,0.79,0.35,0.79,0.79V30.09z M36.31,19.67h-8.62c-0.4,0-0.73,0.33-0.73,0.73v8.72
   c0,0.4,0.33,0.73,0.73,0.73h8.62c0.4,0,0.73-0.33,0.73-0.73V20.4C37.04,20,36.71,19.67,36.31,19.67z M35.51,21.2v4.06l-1.55-2.66
   c-0.14-0.24-0.38-0.37-0.67-0.38c-0.27,0-0.53,0.15-0.66,0.39l-1.7,3.06l-0.37-0.59c-0.14-0.23-0.38-0.37-0.66-0.36
   c-0.27,0-0.51,0.15-0.65,0.38l-0.78,1.34V21.2H35.51z M29.14,28.33l0.8-1.38l0.38,0.62c0.14,0.23,0.39,0.36,0.67,0.36
   c0.27-0.01,0.52-0.16,0.65-0.39l1.68-3.02l2.19,3.77v0.04H29.14z M57.07,6.93h-7.32v2.9H14.25v-2.9H6.93v7.32h2.9v0v35.51h-2.9v7.32
   h7.32v-2.9h35.51v2.9h7.32v-7.32h-2.9c0-0.02,0.01-0.05,0.01-0.07V14.25v0h2.9V6.93z M8.45,8.45h4.27v4.27H8.45V8.45z M12.72,55.55
   H8.45v-4.27h4.27V55.55z M55.55,55.55h-4.27v-4.27h4.27V55.55z M52.66,49.75h-2.9v2.9H14.25v-2.9h-2.9V14.25v0h2.9v-2.9h35.51v2.9
   h2.9v0v35.44C52.65,49.71,52.66,49.73,52.66,49.75z M55.55,12.72h-4.27V8.45h4.27V12.72z M33.55,38.25l-1.59,9.78
   c-0.06,0.37-0.38,0.64-0.75,0.64c-0.04,0-0.08,0-0.12-0.01c-0.42-0.07-0.7-0.46-0.63-0.87L32.04,38c0.07-0.42,0.46-0.7,0.87-0.63
   C33.33,37.44,33.61,37.83,33.55,38.25z M38.7,43.01c0,0.25-0.13,0.49-0.34,0.63l-3.66,2.44c-0.13,0.09-0.28,0.13-0.42,0.13
   c-0.25,0-0.49-0.12-0.63-0.34c-0.23-0.35-0.14-0.82,0.21-1.06l2.71-1.81l-2.71-1.81c-0.35-0.23-0.44-0.71-0.21-1.06
   c0.23-0.35,0.71-0.44,1.06-0.21l3.66,2.44C38.57,42.52,38.7,42.76,38.7,43.01z M30.36,45.88c-0.15,0.22-0.39,0.34-0.63,0.34
   c-0.15,0-0.29-0.04-0.42-0.13l-3.66-2.44c-0.21-0.14-0.34-0.38-0.34-0.63c0-0.25,0.13-0.49,0.34-0.63l3.66-2.44
   c0.35-0.23,0.82-0.14,1.06,0.21c0.23,0.35,0.14,0.82-0.21,1.06l-2.71,1.81l2.71,1.81C30.5,45.06,30.59,45.53,30.36,45.88z"
      />
    </svg>
  );
};
export const ABIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      color={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M10.34,41.54h0.75c0.04,0,0.08-0.01,0.12-0.01c0.32,1.01,1.26,1.74,2.37,1.74c1.11,0,2.05-0.73,2.37-1.74
   c0.04,0.01,0.08,0.01,0.12,0.01h10.89c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76H16.06c-0.04,0-0.08,0.01-0.12,0.01
   c-0.32-1.01-1.26-1.74-2.37-1.74c-1.11,0-2.05,0.73-2.37,1.74c-0.04-0.01-0.08-0.01-0.12-0.01h-0.75c-0.42,0-0.76,0.34-0.76,0.76
   C9.57,41.2,9.91,41.54,10.34,41.54z M13.58,39.82c0.53,0,0.96,0.43,0.96,0.96c0,0.53-0.43,0.96-0.96,0.96s-0.96-0.43-0.96-0.96
   C12.61,40.25,13.04,39.82,13.58,39.82z M10.34,37.8h10.88c0.04,0,0.08-0.01,0.12-0.01c0.32,1.01,1.26,1.74,2.37,1.74
   c1.11,0,2.05-0.73,2.37-1.74c0.04,0.01,0.08,0.01,0.12,0.01h0.75c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76H26.2
   c-0.04,0-0.08,0.01-0.12,0.01c-0.32-1.01-1.26-1.74-2.37-1.74s-2.05,0.73-2.37,1.74c-0.04-0.01-0.08-0.01-0.12-0.01H10.34
   c-0.42,0-0.76,0.34-0.76,0.76C9.57,37.46,9.91,37.8,10.34,37.8z M23.71,36.07c0.53,0,0.96,0.43,0.96,0.96
   c0,0.53-0.43,0.96-0.96,0.96s-0.96-0.43-0.96-0.96C22.74,36.5,23.18,36.07,23.71,36.07z M30.62,7.64c-0.01-0.07-0.02-0.13-0.05-0.19
   c0,0,0,0,0,0c0,0,0,0,0,0c0-0.01-0.01-0.02-0.01-0.02c-0.02-0.03-0.03-0.07-0.05-0.1c-0.01-0.01-0.02-0.02-0.02-0.03
   c-0.02-0.03-0.04-0.05-0.06-0.07c-0.01-0.01-0.02-0.02-0.03-0.03c-0.03-0.03-0.06-0.05-0.09-0.08c0,0-0.01-0.01-0.01-0.01
   c-0.04-0.02-0.07-0.04-0.11-0.06c-0.01-0.01-0.03-0.01-0.04-0.02C30.11,7.02,30.08,7.01,30.05,7c-0.01,0-0.03-0.01-0.04-0.01
   c-0.04-0.01-0.09-0.01-0.14-0.01h-4.59c-0.42,0-0.76,0.34-0.76,0.76c0,0.42,0.34,0.76,0.76,0.76h2.75l-3.15,3.15h-7.14
   c-0.2,0-0.4,0.08-0.54,0.22l-3.16,3.16H8.77c-1.02,0-1.84,0.83-1.84,1.84v26.67c0,1.02,0.83,1.84,1.84,1.84h19.74
   c1.02,0,1.84-0.83,1.84-1.84V16.87c0-1.02-0.83-1.84-1.84-1.84H16.21l1.86-1.86h7.14c0.2,0,0.4-0.08,0.54-0.22l3.37-3.37v2.75
   c0,0.42,0.34,0.76,0.76,0.76s0.76-0.34,0.76-0.76V7.74c0-0.02,0-0.03,0-0.05C30.62,7.67,30.62,7.66,30.62,7.64z M28.83,43.54
   c0,0.18-0.14,0.32-0.32,0.32H8.77c-0.18,0-0.32-0.14-0.32-0.32V33.8h20.38V43.54z M28.51,16.55c0.18,0,0.32,0.14,0.32,0.32v15.4
   H8.45v-15.4c0-0.18,0.14-0.32,0.32-0.32H28.51z M13.47,31.59c0.09,0.04,0.19,0.06,0.29,0.06c0.3,0,0.58-0.18,0.7-0.47l1.16-2.81
   h3.02c0.02,0,0.04,0,0.07-0.01c0.02,0,0.04,0.01,0.07,0.01h3.02l1.16,2.81c0.12,0.29,0.4,0.47,0.7,0.47c0.1,0,0.19-0.02,0.29-0.06
   c0.39-0.16,0.57-0.61,0.41-0.99l-1.35-3.29c0,0,0,0,0-0.01l-3.53-8.58c-0.13-0.32-0.45-0.5-0.77-0.47
   c-0.32-0.03-0.64,0.15-0.77,0.47l-3.53,8.58c0,0,0,0,0,0l-1.35,3.29C12.89,30.99,13.08,31.43,13.47,31.59z M18.71,20.85l2.46,5.99
   h-2.4c-0.02,0-0.04,0-0.07,0.01c-0.02,0-0.04-0.01-0.07-0.01h-2.4L18.71,20.85z M37.05,49.43h0.75c0.04,0,0.08-0.01,0.12-0.01
   c0.32,1.01,1.26,1.74,2.37,1.74s2.05-0.73,2.37-1.74c0.04,0.01,0.08,0.01,0.12,0.01h10.88c0.42,0,0.76-0.34,0.76-0.76
   s-0.34-0.76-0.76-0.76H42.78c-0.04,0-0.08,0.01-0.12,0.01c-0.32-1.01-1.26-1.74-2.37-1.74s-2.05,0.73-2.37,1.74
   c-0.04-0.01-0.08-0.01-0.12-0.01h-0.75c-0.42,0-0.76,0.34-0.76,0.76S36.63,49.43,37.05,49.43z M40.29,47.71
   c0.53,0,0.96,0.43,0.96,0.96c0,0.53-0.43,0.96-0.96,0.96c-0.53,0-0.96-0.43-0.96-0.96C39.33,48.14,39.76,47.71,40.29,47.71z
    M37.05,53.18h10.88c0.04,0,0.08-0.01,0.12-0.01c0.32,1.01,1.26,1.74,2.37,1.74s2.05-0.73,2.37-1.74c0.04,0.01,0.08,0.01,0.12,0.01
   h0.75c0.42,0,0.76-0.34,0.76-0.76c0-0.42-0.34-0.76-0.76-0.76h-0.75c-0.04,0-0.08,0.01-0.12,0.01c-0.32-1.01-1.26-1.74-2.37-1.74
   s-2.05,0.73-2.37,1.74c-0.04-0.01-0.08-0.01-0.12-0.01H37.05c-0.42,0-0.76,0.34-0.76,0.76C36.29,52.84,36.63,53.18,37.05,53.18z
    M50.42,51.45c0.53,0,0.96,0.43,0.96,0.96c0,0.53-0.43,0.96-0.96,0.96c-0.53,0-0.96-0.43-0.96-0.96
   C49.46,51.88,49.89,51.45,50.42,51.45z M55.23,26.66h-0.6l2.22-2.22c0.01-0.01,0.01-0.01,0.01-0.02c0.02-0.03,0.05-0.05,0.07-0.08
   c0.01-0.01,0.02-0.02,0.02-0.03c0.02-0.04,0.04-0.07,0.06-0.12c0,0,0,0,0,0c0.03-0.06,0.04-0.13,0.05-0.2c0-0.02,0-0.03,0-0.05
   c0-0.07,0-0.13-0.01-0.2c0,0,0,0,0,0c-0.01-0.06-0.04-0.12-0.07-0.18c-0.01-0.01-0.01-0.03-0.02-0.04
   c-0.03-0.06-0.07-0.11-0.12-0.16c0,0,0,0,0,0l-3.25-3.25c-0.3-0.3-0.78-0.3-1.08,0c-0.3,0.3-0.3,0.78,0,1.08l1.95,1.95h-2.94
   c-0.2,0-0.4,0.08-0.54,0.22l-1.68,1.68l-4.12-4.12c-0.3-0.3-0.78-0.3-1.08,0l-5.74,5.74h-2.89c-1.02,0-1.84,0.83-1.84,1.84v26.67
   c0,1.02,0.83,1.84,1.84,1.84h19.74c1.02,0,1.84-0.83,1.84-1.84V28.51C57.07,27.49,56.25,26.66,55.23,26.66z M49.85,26.66l2-2h2.63
   l-1.95,1.95c-0.02,0.02-0.03,0.03-0.04,0.05L49.85,26.66C49.85,26.66,49.85,26.66,49.85,26.66z M44.65,22.54l4.12,4.12c0,0,0,0,0,0
   h-8.25L44.65,22.54z M55.55,55.18c0,0.18-0.14,0.32-0.32,0.32H35.49c-0.18,0-0.32-0.14-0.32-0.32v-9.74h20.38V55.18z M55.55,43.91
   H35.17v-15.4c0-0.18,0.14-0.32,0.32-0.32h19.74c0.18,0,0.32,0.14,0.32,0.32V43.91z M47.45,35.09c0.42-0.55,0.67-1.23,0.67-1.97
   c0-1.79-1.45-3.24-3.24-3.24h-2.86c-0.42,0-0.76,0.34-0.76,0.76v4.95v6.92c0,0.42,0.34,0.76,0.76,0.76h4c2.33,0,4.22-1.89,4.22-4.22
   C50.25,37.23,49.08,35.68,47.45,35.09z M42.79,31.41h2.1c0.95,0,1.72,0.77,1.72,1.71c0,0.95-0.77,1.71-1.72,1.71h-2.1V31.41z
    M46.03,41.76h-3.24v-5.4h2.1h1.14c1.49,0,2.7,1.21,2.7,2.7C48.72,40.55,47.51,41.76,46.03,41.76z"
      />
    </svg>
  );
});
export const GearIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      color={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M28.02,16.93c-6.16,0-11.15,4.99-11.15,11.15s4.99,11.15,11.15,11.15c6.16,0,11.15-4.99,11.15-11.15
   S34.18,16.93,28.02,16.93z M28.02,37.71c-5.31,0-9.63-4.32-9.63-9.63s4.32-9.63,9.63-9.63c5.31,0,9.63,4.32,9.63,9.63
   S33.33,37.71,28.02,37.71z M45.93,34.72c-0.86,0-1.7,0.1-2.51,0.29c0.44-0.99,0.8-2.04,1.05-3.12h3.4c0.53,0,0.99-0.39,1.06-0.92
   c0.13-0.94,0.2-1.91,0.2-2.89c0-0.98-0.07-1.94-0.2-2.89c-0.07-0.53-0.52-0.92-1.06-0.92h-3.4c-0.42-1.84-1.15-3.57-2.12-5.12
   l2.4-2.4c0.37-0.37,0.42-0.98,0.1-1.4c-1.17-1.54-2.54-2.92-4.09-4.09c-0.19-0.14-0.42-0.22-0.64-0.22c-0.27,0-0.55,0.11-0.76,0.31
   l-2.4,2.4c-1.56-0.97-3.28-1.7-5.13-2.12v-3.4c0-0.53-0.39-0.99-0.92-1.06c-0.94-0.13-1.91-0.2-2.89-0.2c-0.98,0-1.94,0.07-2.89,0.2
   c-0.53,0.07-0.92,0.52-0.92,1.06v3.4c-1.84,0.42-3.57,1.15-5.13,2.13l-2.4-2.4c-0.21-0.21-0.48-0.31-0.76-0.31
   c-0.23,0-0.45,0.07-0.64,0.22c-1.54,1.17-2.92,2.54-4.08,4.08c-0.32,0.42-0.28,1.02,0.09,1.4l2.4,2.4c-0.97,1.56-1.7,3.28-2.12,5.13
   h-3.4c-0.53,0-0.99,0.39-1.06,0.92c-0.13,0.94-0.2,1.91-0.2,2.89c0,0.98,0.07,1.94,0.2,2.89c0.07,0.53,0.52,0.92,1.06,0.92h3.4
   c0.42,1.84,1.15,3.57,2.12,5.13l-2.4,2.4c-0.37,0.37-0.42,0.98-0.09,1.4c1.17,1.54,2.54,2.92,4.08,4.08
   c0.19,0.14,0.42,0.21,0.64,0.21c0.28,0,0.55-0.1,0.76-0.31l2.4-2.4c1.56,0.97,3.28,1.7,5.13,2.12v3.4c0,0.53,0.39,0.99,0.92,1.06
   c0.94,0.13,1.91,0.2,2.89,0.2c0.98,0,1.94-0.07,2.89-0.2c0.53-0.07,0.92-0.52,0.92-1.06v-3.4c1.12-0.26,2.19-0.63,3.21-1.09
   c-0.18,0.79-0.27,1.6-0.27,2.44c0,6.16,4.99,11.15,11.15,11.15c6.16,0,11.15-4.99,11.15-11.15C57.08,39.71,52.09,34.72,45.93,34.72z
    M31.49,43.04c-0.69,0.16-1.18,0.77-1.18,1.48v3c-0.76,0.09-1.52,0.13-2.29,0.13s-1.53-0.05-2.29-0.13v-3
   c0-0.71-0.49-1.33-1.18-1.48c-1.65-0.38-3.21-1.03-4.66-1.93c-0.25-0.16-0.53-0.23-0.81-0.23c-0.39,0-0.78,0.15-1.08,0.44
   l-2.12,2.12c-1.2-0.95-2.29-2.03-3.23-3.23l2.12-2.12c0.5-0.5,0.59-1.29,0.21-1.89c-0.9-1.44-1.55-3.01-1.93-4.66
   c-0.16-0.69-0.77-1.18-1.48-1.18h-3c-0.09-0.76-0.13-1.52-0.13-2.29c0-0.76,0.05-1.53,0.13-2.29h3c0.71,0,1.33-0.49,1.48-1.19
   c0.38-1.64,1.02-3.21,1.93-4.66c0.37-0.6,0.29-1.38-0.21-1.89l-2.12-2.12c0.95-1.2,2.03-2.28,3.23-3.23l2.12,2.12
   c0.3,0.29,0.69,0.44,1.08,0.44c0.28,0,0.56-0.08,0.81-0.23c1.44-0.9,3.01-1.55,4.66-1.93c0.69-0.16,1.18-0.77,1.18-1.48v-3
   c0.76-0.09,1.52-0.13,2.29-0.13s1.53,0.05,2.29,0.13v3c0,0.71,0.49,1.33,1.19,1.48c1.64,0.38,3.21,1.02,4.66,1.93
   c0.25,0.16,0.53,0.23,0.81,0.23c0.39,0,0.78-0.15,1.08-0.44l2.12-2.12c1.2,0.94,2.28,2.03,3.23,3.23l-2.12,2.12
   c-0.5,0.5-0.59,1.29-0.21,1.89c0.9,1.43,1.55,3,1.93,4.66c0.16,0.69,0.77,1.18,1.48,1.18h3c0.09,0.76,0.13,1.52,0.13,2.29
   c0,0.76-0.05,1.53-0.13,2.29h-3c-0.71,0-1.33,0.49-1.48,1.19c-0.33,1.45-0.87,2.85-1.62,4.14c-2.51,1.13-4.52,3.15-5.63,5.67
   C34.4,42.13,32.98,42.7,31.49,43.04z M45.93,55.5c-5.31,0-9.63-4.32-9.63-9.63c0-1.22,0.23-2.39,0.64-3.46
   c0.19-0.48,0.41-0.94,0.67-1.38c0.81-1.39,1.97-2.56,3.34-3.4c0.46-0.28,0.95-0.52,1.46-0.73c1.09-0.43,2.28-0.66,3.52-0.66
   c5.31,0,9.63,4.32,9.63,9.63C55.56,51.18,51.24,55.5,45.93,55.5z M50.65,41.97c0.3,0.3,0.3,0.78,0,1.08l-5.38,5.38
   c-0.14,0.14-0.34,0.23-0.54,0.23c-0.2,0-0.4-0.08-0.54-0.23l-2.87-2.87c-0.3-0.3-0.3-0.78,0-1.08c0.3-0.3,0.78-0.3,1.08,0l2.33,2.33
   l4.84-4.84C49.87,41.68,50.35,41.68,50.65,41.97z"
      />
    </svg>
  );
});
export const AndroidIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <g>
        <g>
          <g>
            <path
              d="M44.54,24.21H19.46c-0.35,0-0.63-0.28-0.63-0.63c0-7.26,5.91-13.17,13.17-13.17s13.17,5.91,13.17,13.17
               C45.17,23.92,44.89,24.21,44.54,24.21z M20.1,22.94H43.9c-0.33-6.28-5.54-11.28-11.9-11.28S20.43,16.67,20.1,22.94z"
            />
          </g>
          <g>
            <path
              d="M26.48,20.09c-1.11,0-2-0.9-2-2s0.9-2,2-2s2,0.9,2,2S27.59,20.09,26.48,20.09z M26.48,17.34
               c-0.41,0-0.74,0.33-0.74,0.74c0,0.41,0.33,0.74,0.74,0.74c0.41,0,0.74-0.33,0.74-0.74C27.23,17.68,26.89,17.34,26.48,17.34z"
            />
          </g>
          <g>
            <path
              d="M37.52,20.09c-1.11,0-2-0.9-2-2s0.9-2,2-2c1.11,0,2,0.9,2,2S38.62,20.09,37.52,20.09z M37.52,17.34
               c-0.41,0-0.74,0.33-0.74,0.74c0,0.41,0.33,0.74,0.74,0.74c0.41,0,0.74-0.33,0.74-0.74C38.26,17.68,37.93,17.34,37.52,17.34z"
            />
          </g>
          <g>
            <path
              d="M27.03,12.69c-0.21,0-0.42-0.11-0.54-0.3l-2.74-4.43c-0.18-0.3-0.09-0.69,0.2-0.87
               c0.3-0.18,0.69-0.09,0.87,0.2l2.74,4.43c0.18,0.3,0.09,0.69-0.2,0.87C27.26,12.66,27.14,12.69,27.03,12.69z"
            />
          </g>
          <g>
            <path
              d="M36.97,12.69c-0.11,0-0.23-0.03-0.33-0.09c-0.3-0.18-0.39-0.57-0.2-0.87l2.74-4.43
               c0.18-0.3,0.57-0.39,0.87-0.2c0.3,0.18,0.39,0.57,0.2,0.87l-2.74,4.43C37.39,12.58,37.18,12.69,36.97,12.69z"
            />
          </g>
          <g>
            <path
              d="M49.35,43.67c-1.66,0-3.01-1.35-3.01-3.01V28.38c0-1.66,1.35-3.01,3.01-3.01c1.66,0,3.01,1.35,3.01,3.01
               v12.27C52.36,42.32,51.01,43.67,49.35,43.67z M49.35,26.64c-0.96,0-1.75,0.78-1.75,1.75v12.27c0,0.96,0.78,1.75,1.75,1.75
               c0.96,0,1.75-0.78,1.75-1.75V28.38C51.1,27.42,50.31,26.64,49.35,26.64z"
            />
          </g>
          <g>
            <path
              d="M14.65,43.67c-1.66,0-3.01-1.35-3.01-3.01V28.38c0-1.66,1.35-3.01,3.01-3.01c1.66,0,3.01,1.35,3.01,3.01
               v12.27C17.66,42.32,16.31,43.67,14.65,43.67z M14.65,26.64c-0.96,0-1.75,0.78-1.75,1.75v12.27c0,0.96,0.78,1.75,1.75,1.75
               c0.96,0,1.75-0.78,1.75-1.75V28.38C16.39,27.42,15.61,26.64,14.65,26.64z"
            />
          </g>
          <g>
            <path
              d="M36.83,57c-1.66,0-3.01-1.35-3.01-3.01v-5.57c0-0.12-0.09-0.21-0.21-0.21h-3.22c-0.12,0-0.21,0.09-0.21,0.21
               v5.57c0,1.66-1.35,3.01-3.01,3.01c-1.66,0-3.01-1.35-3.01-3.01v-5.79h-2.18c-1.74,0-3.16-1.42-3.16-3.16V26.01
               c0-0.35,0.28-0.63,0.63-0.63h25.09c0.35,0,0.63,0.28,0.63,0.63v19.04c0,1.74-1.42,3.16-3.16,3.16h-2.18v5.79
               C39.84,55.65,38.49,57,36.83,57z M30.39,46.94h3.22c0.81,0,1.47,0.66,1.47,1.47v5.57c0,0.96,0.78,1.75,1.75,1.75
               c0.96,0,1.75-0.78,1.75-1.75v-6.42c0-0.35,0.28-0.63,0.63-0.63h2.81c1.04,0,1.89-0.85,1.89-1.89V26.64H20.09v18.41
               c0,1.04,0.85,1.89,1.89,1.89h2.81c0.35,0,0.63,0.28,0.63,0.63v6.42c0,0.96,0.78,1.75,1.75,1.75c0.96,0,1.75-0.78,1.75-1.75v-5.57
               C28.92,47.6,29.58,46.94,30.39,46.94z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
});
export const ArrowleftIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <polygon
        points="60.16,31.11 7.26,31.11 14.5,23.87 13.24,22.61 3.84,32 13.24,41.39 14.5,40.13 7.26,32.89
   60.16,32.89 "
      />
    </svg>
  );
});
export const ArrowrightIcon = memo(({ listener, color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
      onClick={listener}
    >
      <polygon
        points="3.84,32.89 56.74,32.89 49.5,40.13 50.76,41.39 60.16,32 50.76,22.61 49.5,23.87 56.74,31.11
   3.84,31.11 "
      />
    </svg>
  );
});
export const PrintIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M60.28,15.52c-0.69,0-1.25,0.56-1.25,1.25v24.42H4.97V16.77c0-1.95,1.59-3.54,3.54-3.54h33.57
  c0.69,0,1.25-0.56,1.25-1.25s-0.56-1.25-1.25-1.25H8.51c-3.33,0-6.04,2.71-6.04,6.04v25.66c0,3.33,2.71,6.04,6.04,6.04h15.92v4.93
  h-3.96c-0.38,0-0.75,0.18-0.98,0.48l-3.48,4.42c-0.3,0.38-0.35,0.89-0.14,1.32c0.21,0.43,0.65,0.7,1.12,0.7H47
  c0.48,0,0.91-0.27,1.12-0.7c0.21-0.43,0.15-0.94-0.14-1.32l-3.48-4.42c-0.24-0.3-0.6-0.48-0.98-0.48h-3.96v-4.93h15.92
  c3.33,0,6.04-2.71,6.04-6.04V16.77C61.53,16.08,60.97,15.52,60.28,15.52z M44.43,57.83H19.57l1.51-1.93h21.84L44.43,57.83z
   M37.07,53.4H26.93v-4.93h10.14V53.4z M55.49,45.97H8.51c-1.51,0-2.8-0.95-3.31-2.29H58.8C58.29,45.02,57,45.97,55.49,45.97z
   M29.85,26.85c-2.33,2.33-1.67,6.05-1.2,7.77H13c-0.69,0-1.25,0.56-1.25,1.25s0.56,1.25,1.25,1.25h20.53c0.03,0,0.07-0.01,0.1-0.01
  c0.09,0,0.18,0.01,0.27,0.01c1.56,0,3.27-0.38,4.56-1.66c2.36-2.36,2.72-5.16,2.77-6.13l19.2-19.2c1.47-1.47,1.47-3.87,0-5.34
  c-1.47-1.47-3.87-1.47-5.34,0L35.76,24.1C34.63,24.2,32.05,24.65,29.85,26.85z M36.69,33.69c-1.39,1.39-4.16,0.92-5.57,0.5
  c-0.43-1.4-0.89-4.18,0.49-5.57c1.56-1.56,3.37-1.93,4.2-2.01l2.89,2.89C38.62,30.32,38.25,32.13,36.69,33.69z M56.84,6.55
  c0.5-0.5,1.31-0.5,1.81,0c0.5,0.5,0.5,1.31,0,1.81L43.87,23.14l-1.81-1.81L56.84,6.55z M40.29,23.1l1.81,1.81l-2.22,2.22l-1.81-1.81
  L40.29,23.1z"
      />
    </svg>
  );
});
export const FbIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      className={className}
      width={width}
      height={height}
    >
      <path
        id="XMLID_2081_"
        d="M25.65,57.18h10.14V31.79h7.07l0.75-8.5h-7.83c0,0,0-3.17,0-4.84c0-2,0.4-2.8,2.34-2.8
   c1.56,0,5.49,0,5.49,0V6.82c0,0-5.79,0-7.02,0c-7.54,0-10.95,3.32-10.95,9.68c0,5.54,0,6.78,0,6.78h-5.27v8.61h5.27V57.18z"
      />
    </svg>
  );
});
export const InstaIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      className={className}
      width={width}
      height={height}
    >
      <g id="XMLID_2023_">
        <g id="XMLID_2024_">
          <path
            id="XMLID_2076_"
            d="M32,13.28c6.1,0,6.82,0.02,9.23,0.13c2.23,0.1,3.44,0.47,4.24,0.79
           c1.07,0.41,1.83,0.91,2.63,1.71c0.8,0.8,1.29,1.56,1.71,2.63c0.31,0.81,0.68,2.01,0.79,4.24c0.11,2.41,0.13,3.13,0.13,9.23
           s-0.02,6.82-0.13,9.23c-0.1,2.23-0.47,3.44-0.79,4.24c-0.41,1.07-0.91,1.83-1.71,2.63c-0.8,0.8-1.56,1.29-2.63,1.71
           c-0.81,0.31-2.01,0.69-4.24,0.79c-2.41,0.11-3.13,0.13-9.23,0.13s-6.82-0.02-9.23-0.13c-2.23-0.1-3.44-0.47-4.24-0.79
           c-1.07-0.41-1.83-0.91-2.63-1.71c-0.8-0.8-1.29-1.56-1.71-2.63c-0.31-0.8-0.68-2.01-0.79-4.24c-0.11-2.41-0.13-3.13-0.13-9.23
           s0.02-6.82,0.13-9.23c0.1-2.23,0.47-3.44,0.79-4.24c0.41-1.07,0.91-1.83,1.71-2.63c0.8-0.8,1.56-1.29,2.63-1.71
           c0.8-0.31,2.01-0.69,4.24-0.79C25.18,13.3,25.9,13.28,32,13.28 M32,9.16c-6.2,0-6.98,0.03-9.42,0.14
           c-2.43,0.11-4.09,0.5-5.54,1.06c-1.5,0.58-2.78,1.36-4.05,2.63c-1.27,1.27-2.05,2.54-2.63,4.05c-0.56,1.45-0.95,3.11-1.06,5.54
           C9.19,25.02,9.16,25.8,9.16,32c0,6.2,0.03,6.98,0.14,9.42c0.11,2.43,0.5,4.09,1.06,5.54c0.58,1.5,1.36,2.78,2.63,4.05
           c1.27,1.27,2.54,2.05,4.05,2.63c1.45,0.56,3.11,0.95,5.54,1.06c2.44,0.11,3.21,0.14,9.42,0.14c6.2,0,6.98-0.03,9.42-0.14
           c2.43-0.11,4.09-0.5,5.54-1.06c1.5-0.58,2.78-1.36,4.05-2.63c1.27-1.27,2.05-2.54,2.63-4.05c0.56-1.45,0.95-3.11,1.06-5.54
           c0.11-2.44,0.14-3.21,0.14-9.42c0-6.2-0.03-6.98-0.14-9.42c-0.11-2.43-0.5-4.09-1.06-5.54c-0.58-1.5-1.36-2.78-2.63-4.05
           s-2.54-2.05-4.05-2.63c-1.45-0.56-3.11-0.95-5.54-1.06C38.98,9.19,38.2,9.16,32,9.16"
          />
          <path
            id="XMLID_2073_"
            d="M32,20.27c-6.48,0-11.73,5.25-11.73,11.73c0,6.48,5.25,11.73,11.73,11.73
           c6.48,0,11.73-5.25,11.73-11.73C43.73,25.52,38.48,20.27,32,20.27 M32,39.61c-4.2,0-7.61-3.41-7.61-7.61
           c0-4.2,3.41-7.61,7.61-7.61s7.61,3.41,7.61,7.61C39.61,36.2,36.2,39.61,32,39.61"
          />
          <path
            id="XMLID_2025_"
            d="M46.93,19.81c0,1.51-1.23,2.74-2.74,2.74c-1.51,0-2.74-1.23-2.74-2.74
           c0-1.51,1.23-2.74,2.74-2.74C45.7,17.07,46.93,18.3,46.93,19.81"
          />
        </g>
      </g>
    </svg>
  );
});
export const AppleIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <g>
        <g>
          <path
            d="M24.84,57c-3.56,0-6.12-3.72-8-6.44c-4.65-6.75-7.31-17.88-3.1-25.23c2.23-3.9,6.28-6.36,10.56-6.43
           c1.87-0.03,3.68,0.67,5.24,1.3c1.06,0.42,2.06,0.82,2.77,0.82c0.62,0,1.57-0.38,2.67-0.81c1.88-0.74,4.21-1.67,6.67-1.42
           c1.78,0.07,6.34,0.72,9.32,5.1c0.09,0.14,0.13,0.31,0.1,0.48s-0.13,0.31-0.27,0.4c-1.22,0.76-5,3.55-4.95,8.75
           c0.06,6.58,5.48,9.02,6.1,9.27c0.31,0.13,0.48,0.48,0.37,0.79c-0.36,1.16-1.39,4.09-3.43,7.09l0,0c-1.98,2.9-4.22,6.19-7.93,6.26
           c-1.72,0.02-2.89-0.47-4.02-0.96c-1.15-0.5-2.23-0.97-4-0.97c-1.86,0-3,0.49-4.21,1.01c-1.07,0.46-2.17,0.93-3.71,0.99
           C24.97,57,24.91,57,24.84,57z M24.44,20.16c-0.04,0-0.07,0-0.11,0c-3.84,0.06-7.48,2.28-9.49,5.79
           c-2.95,5.15-2.89,15.27,3.04,23.89c2.18,3.17,4.33,5.99,7.11,5.89c1.31-0.05,2.26-0.46,3.26-0.89c1.21-0.52,2.59-1.11,4.71-1.11
           c2.03,0,3.34,0.57,4.5,1.08c1.09,0.48,2.05,0.88,3.49,0.86c3.06-0.06,5.11-3.06,6.91-5.71v0c1.71-2.51,2.67-4.96,3.11-6.24
           c-1.64-0.82-6.31-3.74-6.36-10.19c-0.05-5.22,3.32-8.27,4.97-9.45c-2.65-3.44-6.44-3.97-7.99-4.03c-2.2-0.22-4.37,0.64-6.12,1.33
           c-1.22,0.48-2.27,0.9-3.14,0.9c-0.95,0-2.01-0.42-3.24-0.91C27.66,20.8,26.05,20.16,24.44,20.16z M32.41,19.51
           c-0.18,0-0.35-0.01-0.52-0.02c-0.3-0.02-0.54-0.25-0.58-0.55c-0.41-3.11,1.13-6.19,2.72-8.04l0,0c1.86-2.19,4.99-3.8,7.59-3.91
           c0.32-0.01,0.62,0.23,0.65,0.56c0.3,2.76-0.7,5.85-2.66,8.25C37.73,18.1,34.96,19.51,32.41,19.51z M41.06,8.32
           c-2.13,0.3-4.59,1.67-6.07,3.41c0,0,0,0,0,0c-1.26,1.46-2.6,3.99-2.47,6.52c2.15-0.03,4.5-1.26,6.12-3.24
           C40.25,13.05,41.13,10.59,41.06,8.32z M34.51,11.32L34.51,11.32L34.51,11.32z"
          />
        </g>
      </g>
    </svg>
  );
});
export const IotIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <g>
        <g>
          <g>
            <path
              d="M18.87,26.97h-4.6c-1.74,0-3.16-1.42-3.16-3.16v-7.96c0-0.35,0.28-0.63,0.63-0.63
               c0.35,0,0.63,0.28,0.63,0.63v7.96c0,1.04,0.85,1.89,1.89,1.89h4.6c0.35,0,0.63,0.28,0.63,0.63
               C19.5,26.69,19.22,26.97,18.87,26.97z"
            />
          </g>
          <g>
            <path
              d="M11.74,16.49C9.13,16.49,7,14.36,7,11.74S9.13,7,11.74,7s4.74,2.13,4.74,4.74S14.36,16.49,11.74,16.49z
                M11.74,8.26c-1.92,0-3.48,1.56-3.48,3.48s1.56,3.48,3.48,3.48s3.48-1.56,3.48-3.48S13.66,8.26,11.74,8.26z"
            />
          </g>
          <g>
            <path
              d="M49.73,35.39h-4.6c-0.35,0-0.63-0.28-0.63-0.63c0-0.35,0.28-0.63,0.63-0.63h4.6c1.04,0,1.89-0.85,1.89-1.89
               V15.85c0-0.35,0.28-0.63,0.63-0.63s0.63,0.28,0.63,0.63v16.38C52.89,33.97,51.47,35.39,49.73,35.39z"
            />
          </g>
          <g>
            <path
              d="M52.26,16.49c-2.62,0-4.74-2.13-4.74-4.74S49.64,7,52.26,7S57,9.13,57,11.74S54.87,16.49,52.26,16.49z
                M52.26,8.26c-1.92,0-3.48,1.56-3.48,3.48s1.56,3.48,3.48,3.48s3.48-1.56,3.48-3.48S54.18,8.26,52.26,8.26z"
            />
          </g>
          <g>
            <path
              d="M52.26,48.78c-0.35,0-0.63-0.28-0.63-0.63v-7.96c0-1.04-0.85-1.89-1.89-1.89h-4.6
               c-0.35,0-0.63-0.28-0.63-0.63c0-0.35,0.28-0.63,0.63-0.63h4.6c1.74,0,3.16,1.42,3.16,3.16v7.96
               C52.89,48.49,52.61,48.78,52.26,48.78z"
            />
          </g>
          <g>
            <path
              d="M52.26,57c-2.62,0-4.74-2.13-4.74-4.74c0-2.62,2.13-4.74,4.74-4.74S57,49.64,57,52.26
               C57,54.87,54.87,57,52.26,57z M52.26,48.78c-1.92,0-3.48,1.56-3.48,3.48c0,1.92,1.56,3.48,3.48,3.48s3.48-1.56,3.48-3.48
               C55.74,50.34,54.18,48.78,52.26,48.78z"
            />
          </g>
          <g>
            <path
              d="M42.61,45.76H21.39c-1.74,0-3.16-1.42-3.16-3.16V21.39c0-1.74,1.42-3.16,3.16-3.16h21.21
               c1.74,0,3.16,1.42,3.16,3.16v21.21C45.76,44.35,44.35,45.76,42.61,45.76z M21.39,19.5c-1.04,0-1.89,0.85-1.89,1.89v21.21
               c0,1.04,0.85,1.89,1.89,1.89h21.21c1.04,0,1.89-0.85,1.89-1.89V21.39c0-1.04-0.85-1.89-1.89-1.89H21.39z"
            />
          </g>
          <g>
            <path
              d="M11.74,48.78c-0.35,0-0.63-0.28-0.63-0.63V31.77c0-1.74,1.42-3.16,3.16-3.16h4.6c0.35,0,0.63,0.28,0.63,0.63
               c0,0.35-0.28,0.63-0.63,0.63h-4.6c-1.04,0-1.89,0.85-1.89,1.89v16.38C12.37,48.49,12.09,48.78,11.74,48.78z"
            />
          </g>
          <g>
            <path
              d="M11.74,57C9.13,57,7,54.87,7,52.26c0-2.62,2.13-4.74,4.74-4.74s4.74,2.13,4.74,4.74
               C16.49,54.87,14.36,57,11.74,57z M11.74,48.78c-1.92,0-3.48,1.56-3.48,3.48c0,1.92,1.56,3.48,3.48,3.48s3.48-1.56,3.48-3.48
               C15.22,50.34,13.66,48.78,11.74,48.78z"
            />
          </g>
          <g>
            <path
              d="M32,43.1c-0.17,0-0.35,0-0.53-0.01c-0.02,0-0.04,0-0.05,0c-0.49-0.03-0.95-0.08-1.39-0.17
               c-0.3-0.06-0.51-0.32-0.51-0.62V39.8c-0.96-0.3-1.84-0.77-2.63-1.4l-2.06,1.39c-0.25,0.17-0.59,0.13-0.8-0.08
               c-0.47-0.48-0.91-1.02-1.3-1.6c-0.11-0.17-0.21-0.32-0.3-0.48c-0.09-0.14-0.18-0.31-0.26-0.48c-0.12-0.21-0.23-0.46-0.34-0.7
               c-0.12-0.28-0.03-0.61,0.23-0.78l2.08-1.39c-0.43-1.49-0.43-3.07,0-4.55l-2.08-1.39c-0.26-0.17-0.35-0.5-0.23-0.78
               c0.11-0.24,0.22-0.48,0.35-0.72c0.08-0.16,0.18-0.33,0.28-0.5c0.06-0.12,0.15-0.27,0.26-0.42c0.1-0.15,0.22-0.32,0.33-0.48
               c0.11-0.15,0.22-0.3,0.35-0.45c0.11-0.13,0.22-0.27,0.34-0.4l0.26-0.28c0.21-0.23,0.56-0.28,0.82-0.1l2.07,1.39
               c0.78-0.63,1.66-1.1,2.63-1.4v-2.51c0-0.3,0.22-0.57,0.52-0.62c0.3-0.05,0.6-0.1,0.91-0.13c0.7-0.05,1.42-0.05,2.11,0
               c0.31,0.03,0.62,0.07,0.91,0.13c0.3,0.05,0.52,0.32,0.52,0.62v2.51c0.96,0.3,1.85,0.78,2.63,1.4l2.08-1.39
               c0.25-0.17,0.59-0.13,0.81,0.09c0.7,0.73,1.3,1.54,1.77,2.42c0.09,0.16,0.18,0.33,0.25,0.51c0.05,0.08,0.12,0.24,0.18,0.4
               c0.09,0.27-0.01,0.57-0.25,0.73l-2.07,1.38c0.43,1.48,0.42,3.06-0.01,4.55l2.08,1.38c0.24,0.16,0.34,0.46,0.25,0.73
               c-0.05,0.16-0.13,0.31-0.2,0.45c-0.17,0.38-0.41,0.81-0.72,1.27c-0.09,0.15-0.2,0.31-0.32,0.46c-0.1,0.14-0.22,0.29-0.35,0.44
               c-0.11,0.13-0.23,0.27-0.34,0.4c-0.02,0.03-0.04,0.05-0.07,0.08l-0.22,0.22c-0.21,0.21-0.55,0.25-0.8,0.08l-2.07-1.38
               c-0.78,0.62-1.67,1.1-2.63,1.4v2.49c0,0.3-0.21,0.56-0.51,0.62c-0.45,0.09-0.9,0.14-1.4,0.17c-0.02,0-0.04,0-0.05,0
               c-0.01,0-0.01,0-0.02,0C32.34,43.09,32.17,43.1,32,43.1z M31.55,41.83c0.32,0.01,0.61,0.01,0.9,0c0.03,0,0.05,0,0.08,0
               c0.24-0.01,0.46-0.04,0.69-0.07v-2.43c0-0.29,0.2-0.54,0.48-0.61c1.1-0.27,2.12-0.81,2.94-1.56c0.21-0.2,0.53-0.22,0.78-0.06
               l2.02,1.35c0.06-0.07,0.13-0.15,0.2-0.23c0.11-0.13,0.21-0.25,0.3-0.39c0.1-0.13,0.19-0.26,0.27-0.39
               c0.25-0.37,0.44-0.7,0.59-1.02l-2.03-1.35c-0.24-0.16-0.34-0.47-0.24-0.74c0.54-1.5,0.54-3.15,0.01-4.64
               c-0.1-0.27,0-0.58,0.24-0.74l2.02-1.35c-0.05-0.1-0.09-0.19-0.14-0.27c-0.34-0.63-0.74-1.22-1.21-1.76l-2.02,1.36
               c-0.24,0.16-0.56,0.14-0.78-0.06c-0.82-0.75-1.84-1.29-2.94-1.56c-0.28-0.07-0.48-0.32-0.48-0.61v-2.45
               c-0.09-0.01-0.18-0.02-0.27-0.03c-0.61-0.05-1.27-0.05-1.9,0c-0.09,0.01-0.18,0.02-0.27,0.03v2.45c0,0.29-0.2,0.54-0.48,0.61
               c-1.11,0.27-2.09,0.8-2.93,1.56c-0.21,0.19-0.55,0.21-0.78,0.06l-2.01-1.35c-0.07,0.08-0.13,0.15-0.19,0.23
               c-0.11,0.13-0.21,0.26-0.3,0.39c-0.11,0.15-0.21,0.3-0.31,0.45c-0.08,0.12-0.15,0.23-0.22,0.36c-0.11,0.17-0.18,0.3-0.25,0.43
               c-0.03,0.06-0.06,0.12-0.09,0.18l2.02,1.36c0.24,0.16,0.34,0.47,0.24,0.74c-0.54,1.49-0.54,3.14,0,4.64
               c0.1,0.27,0,0.58-0.24,0.74l-2.02,1.35c0.03,0.05,0.05,0.1,0.08,0.15c0.08,0.15,0.15,0.29,0.24,0.42
               c0.09,0.16,0.17,0.28,0.25,0.41c0.25,0.37,0.51,0.72,0.8,1.04l2.01-1.35c0.24-0.16,0.58-0.13,0.78,0.06
               c0.84,0.76,1.83,1.29,2.93,1.56c0.28,0.07,0.48,0.32,0.48,0.61v2.43c0.22,0.03,0.45,0.05,0.68,0.07
               C31.49,41.82,31.52,41.82,31.55,41.83z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
});
export const LinkedinIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <g id="XMLID_2095_">
        <g id="XMLID_2097_">
          <rect
            id="XMLID_2100_"
            x="11.95"
            y="25.04"
            width="8.62"
            height="27.85"
          />
          <path
            id="XMLID_2098_"
            d="M16.22,21.39c2.82,0,5.1-2.3,5.1-5.14s-2.28-5.14-5.1-5.14c-2.82,0-5.1,2.3-5.1,5.14
           S13.4,21.39,16.22,21.39z"
          />
        </g>
        <path
          id="XMLID_2096_"
          d="M34.35,38.27c0-3.91,1.8-6.25,5.25-6.25c3.17,0,4.69,2.24,4.69,6.25c0,4.01,0,14.62,0,14.62
       h8.58c0,0,0-10.17,0-17.63c0-7.46-4.23-11.07-10.13-11.07c-5.91,0-8.39,4.6-8.39,4.6v-3.75h-8.27v27.85h8.27
       C34.35,52.89,34.35,42.56,34.35,38.27z"
        />
      </g>
    </svg>
  );
});
export const TargetIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      color={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M56.93,16.23c-0.37-0.58-1.14-0.76-1.72-0.39c-0.58,0.37-0.75,1.14-0.39,1.72C57.55,21.88,59,26.87,59,32
   c0,14.89-12.11,27-27,27S5,46.89,5,32S17.11,5,32,5c6.13,0,12.02,2.07,16.79,5.86v2.59l-3.37,3.37c-3.71-3.28-8.42-5.08-13.41-5.08
   c-11.17,0-20.26,9.09-20.26,20.26S20.83,52.26,32,52.26S52.26,43.17,52.26,32c0-4.06-1.2-7.98-3.46-11.33
   c-0.39-0.57-1.16-0.72-1.73-0.34c-0.57,0.39-0.72,1.16-0.34,1.73c1.98,2.94,3.03,6.37,3.03,9.93c0,9.79-7.97,17.76-17.76,17.76
   S14.24,41.79,14.24,32S22.21,14.24,32,14.24c4.33,0,8.41,1.54,11.64,4.35l-4.79,4.79c-1.95-1.55-4.35-2.4-6.85-2.4
   c-6.08,0-11.02,4.94-11.02,11.02S25.92,43.02,32,43.02S43.02,38.08,43.02,32c0-1.49-0.29-2.93-0.87-4.29
   c-0.27-0.63-1-0.93-1.64-0.66c-0.64,0.27-0.93,1-0.66,1.64c0.44,1.05,0.67,2.17,0.67,3.32c0,4.7-3.82,8.52-8.52,8.52
   S23.48,36.7,23.48,32s3.82-8.52,8.52-8.52c1.84,0,3.61,0.59,5.08,1.68L32.24,30c-0.08-0.01-0.16-0.02-0.24-0.02
   c-1.12,0-2.03,0.91-2.03,2.03s0.91,2.03,2.03,2.03c1.12,0,2.03-0.91,2.03-2.03c0-0.08-0.01-0.16-0.02-0.24l16.55-16.55h3.53
   l5.84-5.84h-5.29V4.02l-4.62,4.62C44.86,4.67,38.56,2.5,32,2.5C15.74,2.5,2.5,15.74,2.5,32S15.74,61.5,32,61.5
   c16.26,0,29.5-13.23,29.5-29.5C61.5,26.39,59.92,20.94,56.93,16.23z"
      />
    </svg>
  );
});
export const MobileDevIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      color={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M47.77,42.37c-0.69,0-1.25,0.56-1.25,1.25v6.27H17.48v-6.27c0-0.69-0.56-1.25-1.25-1.25s-1.25,0.56-1.25,1.25
   v11.84c0,3.33,2.71,6.04,6.04,6.04h21.96c3.33,0,6.04-2.71,6.04-6.04V43.62C49.02,42.93,48.46,42.37,47.77,42.37z M42.98,59H21.02
   c-1.95,0-3.54-1.59-3.54-3.54v-3.08h29.04v3.08C46.52,57.41,44.93,59,42.98,59z M55.05,32.88L43.01,44.92
   c-0.24,0.24-0.56,0.37-0.88,0.37s-0.64-0.12-0.88-0.37c-0.49-0.49-0.49-1.28,0-1.77L52.4,32L41.24,20.84
   c-0.49-0.49-0.49-1.28,0-1.77c0.49-0.49,1.28-0.49,1.77,0l12.04,12.04C55.54,31.6,55.54,32.4,55.05,32.88z M20.99,44.92L8.95,32.88
   c-0.49-0.49-0.49-1.28,0-1.77l12.04-12.04c0.49-0.49,1.28-0.49,1.77,0c0.49,0.49,0.49,1.28,0,1.77L11.6,32l11.16,11.16
   c0.49,0.49,0.49,1.28,0,1.77c-0.24,0.24-0.56,0.37-0.88,0.37C21.56,45.29,21.24,45.17,20.99,44.92z M36.96,55.69
   c0,0.69-0.56,1.25-1.25,1.25h-7.42c-0.69,0-1.25-0.56-1.25-1.25s0.56-1.25,1.25-1.25h7.42C36.4,54.44,36.96,55,36.96,55.69z
    M16.23,21.63c0.69,0,1.25-0.56,1.25-1.25V9.79h29.04v10.59c0,0.69,0.56,1.25,1.25,1.25s1.25-0.56,1.25-1.25V8.54
   c0-3.33-2.71-6.04-6.04-6.04H21.02c-3.33,0-6.04,2.71-6.04,6.04v11.84C14.98,21.07,15.54,21.63,16.23,21.63z M21.02,5h21.96
   c1.51,0,2.8,0.95,3.31,2.29H17.71C18.22,5.95,19.51,5,21.02,5z"
      />
    </svg>
  );
});
export const PlayBtnIcon = memo(({ color, width, height, className, listener }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
      onClick={() => listener()}
    >
      <path
        d="M18.62,32V18.88c0-1.55,1.68-2.52,3.02-1.74l11.36,6.56l11.36,6.56c1.34,0.77,1.34,2.71,0,3.48L33.01,40.3
   l-11.36,6.56c-1.34,0.77-3.02-0.19-3.02-1.74V32z"
      />
    </svg>
  );
});
export const TwitterIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        id="XMLID_2112_"
        d="M9.81,45.94c4.03,2.58,8.82,4.09,13.96,4.09c16.91,0,26.46-14.28,25.88-27.09
   c1.78-1.28,3.32-2.89,4.54-4.71c-1.63,0.72-3.39,1.21-5.23,1.43c1.88-1.12,3.32-2.91,4-5.04c-1.76,1.04-3.71,1.8-5.78,2.21
   c-1.66-1.77-4.03-2.88-6.65-2.88c-5.88,0-10.2,5.48-8.87,11.18c-7.57-0.38-14.28-4-18.77-9.52c-2.39,4.09-1.24,9.45,2.82,12.16
   c-1.49-0.05-2.9-0.46-4.13-1.14c-0.1,4.22,2.92,8.16,7.3,9.04c-1.28,0.35-2.69,0.43-4.11,0.15c1.16,3.62,4.52,6.25,8.51,6.32
   C19.46,45.17,14.64,46.51,9.81,45.94z"
      />
    </svg>
  );
});
export const WebIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <g>
        <g>
          <g>
            <path
              d="M53,46.08H11c-2.2,0-4-1.79-4-4V11c0-2.2,1.79-4,4-4h42c2.2,0,4,1.79,4,4v31.08
               C57,44.28,55.21,46.08,53,46.08z M11,8.26c-1.51,0-2.74,1.23-2.74,2.74v31.08c0,1.51,1.23,2.74,2.74,2.74h42
               c1.51,0,2.74-1.23,2.74-2.74V11c0-1.51-1.23-2.74-2.74-2.74H11z"
            />
          </g>
          <g>
            <path
              d="M53.37,38.61c-0.35,0-0.63-0.28-0.63-0.63V15.1c0-1.51-1.23-2.74-2.74-2.74H13.99
               c-1.51,0-2.74,1.23-2.74,2.74v22.88c0,0.35-0.28,0.63-0.63,0.63c-0.35,0-0.63-0.28-0.63-0.63V15.1c0-2.2,1.79-4,4-4h36.02
               c2.2,0,4,1.79,4,4v22.88C54.01,38.33,53.72,38.61,53.37,38.61z"
            />
          </g>
          <g>
            <path
              d="M56.37,38.61H7.63C7.28,38.61,7,38.33,7,37.98s0.28-0.63,0.63-0.63h48.74c0.35,0,0.63,0.28,0.63,0.63
               S56.72,38.61,56.37,38.61z"
            />
          </g>
          <g>
            <path
              d="M42.26,19.92H18.16c-0.35,0-0.63-0.28-0.63-0.63c0-0.35,0.28-0.63,0.63-0.63h24.11
               c0.35,0,0.63,0.28,0.63,0.63C42.9,19.64,42.61,19.92,42.26,19.92z"
            />
          </g>
          <g>
            <path
              d="M35.46,42.34h-6.92c-0.35,0-0.63-0.28-0.63-0.63c0-0.35,0.28-0.63,0.63-0.63h6.92
               c0.35,0,0.63,0.28,0.63,0.63C36.09,42.06,35.81,42.34,35.46,42.34z"
            />
          </g>
          <g>
            <path
              d="M42.26,31.93H18.16c-0.35,0-0.63-0.28-0.63-0.63V14.92c0-0.35,0.28-0.63,0.63-0.63h24.11
               c0.35,0,0.63,0.28,0.63,0.63V31.3C42.9,31.65,42.61,31.93,42.26,31.93z M18.79,30.67h22.85V15.55H18.79V30.67z"
            />
          </g>
          <g>
            <path
              d="M42.12,57H21.88c-1.41,0-2.56-1.15-2.56-2.56c0-1.41,1.15-2.56,2.56-2.56h20.25c1.41,0,2.56,1.15,2.56,2.56
               C44.69,55.85,43.54,57,42.12,57z M21.88,53.14c-0.72,0-1.3,0.58-1.3,1.3c0,0.72,0.58,1.3,1.3,1.3h20.25c0.72,0,1.3-0.58,1.3-1.3
               c0-0.72-0.58-1.3-1.3-1.3H21.88z"
            />
          </g>
          <g>
            <path
              d="M28.59,28.41h-6.87c-0.35,0-0.63-0.28-0.63-0.63v-3.44c0-0.35,0.28-0.63,0.63-0.63h6.87
               c0.35,0,0.63,0.28,0.63,0.63v3.44C29.22,28.13,28.94,28.41,28.59,28.41z M22.35,27.15h5.61v-2.18h-5.61V27.15z"
            />
          </g>
          <g>
            <path
              d="M26,53.14c-0.05,0-0.11-0.01-0.16-0.02c-0.34-0.09-0.54-0.43-0.45-0.77l1.86-7.07
               c0.09-0.34,0.44-0.54,0.77-0.45c0.34,0.09,0.54,0.43,0.45,0.77l-1.86,7.07C26.53,52.95,26.28,53.14,26,53.14z"
            />
          </g>
          <g>
            <path
              d="M38,53.14c-0.28,0-0.54-0.19-0.61-0.47l-1.86-7.07c-0.09-0.34,0.11-0.68,0.45-0.77
               c0.34-0.09,0.68,0.11,0.77,0.45l1.86,7.07c0.09,0.34-0.11,0.68-0.45,0.77C38.11,53.14,38.06,53.14,38,53.14z"
            />
          </g>
        </g>
      </g>
    </svg>
  );
});
export const WebDevIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      color={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M54.81,7.93H9c-0.69,0-1.25,0.56-1.25,1.25v33.23c0,0.69,0.56,1.25,1.25,1.25h45.81c0.69,0,1.25-0.56,1.25-1.25
   V9.17C56.06,8.49,55.5,7.93,54.81,7.93z M53.56,41.16H10.25V10.42h43.31V41.16z M38.91,34.29l8.49-8.49l-8.49-8.49
   c-0.49-0.49-0.49-1.28,0-1.77c0.49-0.49,1.28-0.49,1.77,0l9.38,9.38c0.49,0.49,0.49,1.28,0,1.77l-9.38,9.38
   c-0.24,0.24-0.56,0.37-0.88,0.37s-0.64-0.12-0.88-0.37C38.42,35.57,38.42,34.77,38.91,34.29z M13.76,26.67
   c-0.49-0.49-0.49-1.28,0-1.77l9.38-9.38c0.49-0.49,1.28-0.49,1.77,0c0.49,0.49,0.49,1.28,0,1.77l-8.49,8.49l8.49,8.49
   c0.49,0.49,0.49,1.28,0,1.77c-0.24,0.24-0.56,0.37-0.88,0.37c-0.32,0-0.64-0.12-0.88-0.37L13.76,26.67z M55.4,2.55H8.42
   c-3.33,0-6.04,2.71-6.04,6.04v34.41c0,3.33,2.71,6.04,6.04,6.04h15.92v2.43H12.15c-0.38,0-0.75,0.18-0.98,0.48l-5.96,7.58
   c-0.3,0.38-0.35,0.89-0.14,1.32c0.21,0.43,0.65,0.7,1.12,0.7h51.44c0.48,0,0.91-0.27,1.12-0.7c0.21-0.43,0.15-0.94-0.14-1.32
   l-5.96-7.58c-0.24-0.3-0.6-0.48-0.98-0.48H39.48v-2.43H55.4c3.33,0,6.04-2.71,6.04-6.04V8.59C61.43,5.26,58.73,2.55,55.4,2.55z
    M51.06,53.96l4,5.09H8.76l4-5.09h11.58v1.22c0,0.69,0.56,1.25,1.25,1.25h12.64c0.69,0,1.25-0.56,1.25-1.25v-1.22H51.06z
    M36.98,53.93H26.84v-4.9h10.15V53.93z M58.94,42.99c0,1.95-1.59,3.54-3.54,3.54H8.42c-1.95,0-3.54-1.59-3.54-3.54V8.59
   c0-1.95,1.59-3.54,3.54-3.54H55.4c1.95,0,3.54,1.59,3.54,3.54V42.99z"
      />
    </svg>
  );
});
export const YoutubeIcon = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      space="preserve"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <g id="XMLID_2117_">
        <path
          d="M55.96,19.89c-0.58-2.17-2.27-3.87-4.43-4.45C47.64,14.39,32,14.39,32,14.39s-15.64,0-19.53,1.05
       c-2.16,0.58-3.85,2.28-4.43,4.45C7,23.81,7,32,7,32s0,8.19,1.04,12.11c0.58,2.17,2.27,3.87,4.43,4.45
       C16.36,49.61,32,49.61,32,49.61s15.64,0,19.53-1.05c2.16-0.58,3.85-2.28,4.43-4.45C57,40.19,57,32,57,32S57,23.81,55.96,19.89z
        M26.89,39.43V24.57L39.95,32L26.89,39.43z"
        />
      </g>
    </svg>
  );
});
export const ServiceBox = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1121.5 1080"
      space="preserve"
      color={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        style={{
          boxShadow: "0px 10px 10px rgba(0,0,0,0.1)",
          border: "2px solid red",
        }}
        d="M0,383C0,199,138,30.7,337.3,11C411.6,3.7,486.2,0,560.7,0c74.5,0,149.1,3.7,223.4,11
 c254.6,25.2,409.2,293,303.7,526.1h0c-61.6,136-136.4,265.6-223.4,387c-74.5,104-189.1,156-303.7,156c-114.6,0-229.2-52-303.7-156
 c-43.5-60.7-84-123.4-121.2-188c-37.3-64.6-71.4-131-102.2-199C10.7,486.4,0,434.1,0,383z"
      />
    </svg>
  );
});
export const Business = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path d="M15.8,42.5h7c2.2,0,4,1.8,4,4V61h-15V46.5C11.8,44.3,13.6,42.5,15.8,42.5z M3,57c0,2.2,1.8,4,4,4h28.6V10.7
	c0-1.5-1.1-2.7-2.5-3L3,3V57z M10.1,15h6.2 M10.1,22.5h6.2 M10.1,30h6.2 M10.1,37.6h6.2 M22.4,15h6.2 M22.4,22.5h6.2 M22.4,30h6.2
	 M22.4,37.6h6.2 M45.2,29.9h6.2 M45.2,37.4h6.2 M45.2,44.9h6.2 M45.2,52.4h6.2 M35.6,19v42H57c2.2,0,4-1.8,4-4V25.6
	c0-1.5-1.1-2.7-2.5-3L35.6,19z"
      />
    </svg>
  );
});
export const NosOffres = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path d="M17.3,56.7c-3.3,3.3-8.6,3.3-11.8,0s-3.3-8.6,0-11.8 M39.8,29.7c2-2,2-5.4,0-7.4c-2-2-5.4-2-7.4,0 M49,38.9
	L23.2,13.2L11.4,38.9l-5.9,5.9 M49,38.9L23.2,50.8l-5.9,5.9 M11.4,38.9l18.8,18.8 M24.7,61l-5.9-5.9 M30.1,57.7L24.7,61 M42.9,41.7
	L26.3,25.1 M45.9,18.1l4.6-4.6 M53.9,26H61 M38,10.1V3"/>
    </svg>
  );
});
export const NosServices = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path d="M56,18.1c-0.2,0.9-0.6,1.8-1,2.6l1.9,2.2c-0.9,1.3-2,2.4-3.2,3.3l-2.2-1.9c-0.8,0.5-1.8,0.9-2.7,1.2l-0.2,2.8
	c-0.8,0.1-1.5,0.2-2.3,0.2c-0.8,0-1.5-0.1-2.3-0.2l-0.2-2.8c-1-0.2-1.9-0.6-2.7-1.2l-2.2,1.9c-1.3-0.9-2.3-2-3.2-3.3l1.9-2.2
	c-0.5-0.8-0.8-1.7-1-2.6l-2.8-0.2c-0.1-0.7-0.2-1.4-0.2-2.1c0-0.9,0.1-1.7,0.2-2.5l2.9-0.2c0.2-0.9,0.6-1.7,1.1-2.5l-1.9-2.2
	c0.9-1.3,2-2.4,3.3-3.2L41.3,7c0.7-0.4,1.5-0.7,2.4-1l0.2-2.9C44.6,3.1,45.4,3,46.2,3c0.8,0,1.6,0.1,2.3,0.2l0.2,2.9
	c0.8,0.2,1.6,0.5,2.4,1l2.2-1.9c1.3,0.9,2.4,2,3.3,3.2l-1.9,2.2c0.5,0.8,0.8,1.6,1.1,2.5l2.9,0.2c0.2,0.8,0.2,1.7,0.2,2.5
	c0,0.7-0.1,1.4-0.2,2.1L56,18.1z M52.8,36.3c4.5,0,8.2-3.7,8.2-8.2c0-3.7-2.5-6.8-5.8-7.9 M24.9,25.4c0-6.1-4.9-11-11-11
	s-11,4.9-11,11s4.9,11,11,11 M32,3c-7.6,0-14.1,4.8-16.6,11.5 M46.2,20.3c2.5,0,4.5-2,4.5-4.5s-2-4.5-4.5-4.5s-4.5,2-4.5,4.5
	S43.7,20.3,46.2,20.3z M18.7,54.3V43.7c0-1.1-0.9-2-2-2H5c-1.1,0-2,0.9-2,2v10.6c0,1.1,0.9,2,2,2h11.7
	C17.8,56.3,18.7,55.4,18.7,54.3z M61,54.3V43.7c0-1.1-0.9-2-2-2H47.3c-1.1,0-2,0.9-2,2v10.6c0,1.1,0.9,2,2,2H59
	C60.1,56.3,61,55.4,61,54.3z M18.7,23.7h-5.8 M18.7,29.1h-5.8 M8.9,23.7L8.9,23.7 M56.2,48.1l-5.1-2.9c-0.7-0.4-1.5,0.1-1.5,0.9v5.8
	c0,0.8,0.8,1.3,1.5,0.9l5.1-2.9C56.9,49.5,56.9,48.5,56.2,48.1z M14.4,52.9l-3.6-7.7 M7.2,52.9l3.6-7.7 M8,51.3h5.7 M18.7,45.1
	c1.4,0,2.6-1.2,2.6-2.6 M42.7,42.5c0,1.4,1.2,2.6,2.6,2.6 M21.3,42.5v-9.3 M40.4,59V48.4c0-1.1-0.9-2-2-2H25.6c-1.1,0-2,0.9-2,2V59
	c0,1.1,0.9,2,2,2h12.8C39.5,61,40.4,60.1,40.4,59z M40.4,55l-4.1-4.1l-4.9,4.9 M36.5,61l-8.2-8.2l-4.5,4.5 M32,46.4V28.1 M42.7,42.5
	v-9.3"/>
    </svg>
  );
});
export const Partner = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path d="M35.2,10.9c5.9-5.9,15.5-5.9,21.4,0s5.9,15.5,0,21.4 M35.2,10.9L25.1,21c-1.6,1.6-1.6,4.3,0,5.9l0,0
	c1.6,1.6,4.3,1.6,5.9,0l6.6-6.6 M56.6,32.4l-3.5,3.5 M28.8,10.9C22.9,5,13.3,5,7.4,10.9s-5.9,15.5,0,21.4 M10.7,35.6l-3.3-3.3
	 M28.8,10.9l3.2,3.2 M34.5,48.5L34.5,48.5c-1.4-1.4-3.8-1.4-5.2,0l-2.7,2.7c-1.4,1.4-1.4,3.8,0,5.2l0,0c1.4,1.4,3.8,1.4,5.2,0
	l2.7-2.7C36,52.3,36,49.9,34.5,48.5z M30.7,41.8L30.7,41.8c-1.4-1.4-3.8-1.4-5.2,0l-5.9,5.9c-1.4,1.4-1.4,3.8,0,5.2l0,0
	c1.4,1.4,3.8,1.4,5.2,0l5.9-5.9C32.2,45.6,32.2,43.3,30.7,41.8z M27.5,34.6L27.5,34.6c-1.4-1.4-3.8-1.4-5.2,0l-7.8,7.8
	c-1.4,1.4-1.4,3.8,0,5.2l0,0c1.4,1.4,3.8,1.4,5.2,0l7.8-7.8C28.9,38.4,28.9,36,27.5,34.6z M21.4,30.2L21.4,30.2
	c-1.4-1.4-3.8-1.4-5.2,0L9,37.4c-1.4,1.4-1.4,3.8,0,5.2l0,0c1.4,1.4,3.8,1.4,5.2,0l7.2-7.2C22.9,33.9,22.9,31.6,21.4,30.2z M37.9,52
	c1.4,1.4,3.8,1.4,5.2,0l0,0c1.4-1.4,1.4-3.8,0-5.2l-5.9-5.9 M45.1,48.7c1.4,1.4,3.8,1.4,5.2,0l0,0c1.4-1.4,1.4-3.8,0-5.2l-8-8
	 M49.6,42.7c1.4,1.4,3.8,1.4,5.2,0l0,0c1.4-1.4,1.4-3.8,0-5.2 M35,49.1l2.9,2.9 M45.1,48.7l-2-2 M54.8,37.5L37.6,20.3"/>
    </svg>
  );
});
export const Person = memo(({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path className="st0" d="M53.8,61H10.2V46.7c0-4,2.5-7.6,6.2-9.1l10.4-4 M36.6,17.6c-0.9,0-1.8-0.2-2.7-0.5c-1.1-0.4-1.9-1.3-2.6-2.1
	c-0.2-0.2-0.4-0.5-0.7-0.6c-0.7-0.6-1.7-0.9-2.7-0.9c-2.3,0-4.2,2.1-4.2,4.5v6c0,4.7,4,8.5,8.7,8.2c4.3-0.3,7.6-4.1,7.6-8.4v-6.9
	C39.1,17.3,37.9,17.6,36.6,17.6z M26.9,33.5v-3.2 M22.6,18.2c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9 M22.6,18.2h1.2 M22.6,24
	h1.2 M41.4,24c1.6,0,2.9-1.3,2.9-2.9c0-1.6-1.3-2.9-2.9-2.9 M41.4,24h-1.2 M41.4,18.2h-1.2 M43,14c0-6.1-4.9-11-11-11S21,7.9,21,14
	 M21,14v4.6 M43,14v4.7 M23.2,35l5.6,6.9l1.8-5.4l-3.7-2.9 M30.6,36.5l1.4,5.4 M32,61V41.9 M18.2,61V43.6 M53.8,61V46.7
	c0-4-2.5-7.6-6.2-9.1l-10.4-4 M37.1,33.5v-3.2 M37.1,33.5l-3.7,2.9l1.8,5.4l5.6-6.9 M33.4,36.5L32,41.9 M45.8,61V43.6"/>
    </svg>
  );
});
