import { cardVariants, technoVariant } from "../../animation_variants";
import { firstSlide, secondSlide, thirdSlide } from "../../data";
import TitleOfSection from "../TitleOfSection";
import { motion } from "framer-motion";

function Technologies() {
  function displayIconService() {
    return (
      <>
        {/* first slide */}
        <motion.div
          variants={technoVariant(0, -500, 0.25)}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="flex gap-3 "
        >
          {firstSlide.map(({ icon, id }) => (
            <div
              key={id}
              className="w-[180px] shrink-0 h-[90px] p-2 flex items-center justify-center bg-slate-200 rounded-xl"
            >
              <img
                src={icon}
                alt="logo"
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </motion.div>
        {/* second slide */}
        <motion.div
          variants={technoVariant(0, 500, 0.5)}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="flex gap-3"
        >
          {secondSlide.map(({ icon, id }) => (
            <div
              key={id}
              className="w-[180px] shrink-0 h-[90px] p-2 flex items-center justify-center bg-slate-200 rounded-xl"
            >
              <img
                src={icon}
                alt="logo"
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </motion.div>
        {/* third slide */}
        <motion.div
          variants={technoVariant(10, -500, 0.75)}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="flex gap-3"
        >
          {thirdSlide.map(({ icon, id }) => (
            <div
              key={id}
              className="w-[180px] shrink-0 h-[90px] p-2 flex items-center justify-center bg-slate-200 rounded-xl"
            >
              <img
                src={icon}
                alt="logo"
                className="w-full h-full object-cover"
              />
            </div>
          ))}
        </motion.div>
      </>
    );
  }

  return (
    <div className="mt-8 md:mt-16 tracking-widest">
      <div className="px-5 md:px-10 lg:px-24">
        <TitleOfSection title="Technologies" color1="mainColor">
          <span className="text-xl md:text-2xl ">
            Les technologies que <br /> nous utilisons.
          </span>
        </TitleOfSection>
      </div>
      <div className="overflow-hidden ml-5 md:ml-10 lg:mx-24">
        {/* Icons slides wrapper */}
        <div className="solutionIcons w-full flex flex-col gap-2 overflow-x-scroll my-8 pr-0">
          {displayIconService()}
        </div>
      </div>
    </div>
  );
}

export default Technologies;
