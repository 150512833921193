import { useEffect, useRef, useState } from "react";
import { HiMenu, HiX } from "react-icons/hi";

import { Link, useLocation } from "react-router-dom";
import Loader from "./Loader";
import { motion } from "framer-motion";
import Navlinks from "./Navlinks";

function NavBar() {
  const [showMenu, setShowMenu] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const { pathname } = useLocation();

  const navbar = useRef();
  function whenScrolling() {
    if (document.documentElement.scrollTop > 100) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  }

  useEffect(() => {
    if (
      pathname === "/signin" ||
      pathname === "/signup" ||
      pathname.includes("/forget")
    ) {
      window.removeEventListener("scroll", whenScrolling);

      setIsScroll(true);
    } else {
      window.addEventListener("scroll", whenScrolling);
    }

    return () => {
      window.removeEventListener("scroll", whenScrolling);
    };
  }, [pathname]);

  return (
    <div
      ref={navbar}
      className={`fixed cursor-none z-[1000] flex items-center justify-between w-screen px-5 md:px-10 tracking-widest duration-300 ease-in-out ${
        isScroll
          ? "bg-white/100 py-1 shadow-2xl border-b-4 border-mainColor/40 "
          : "lg:text-white py-4 border-b-2 border-transparent  "
      }`}
    >
      <div className="w-[45%] md:w-56">
        <Link to="/">
          {isScroll ? (
            <img
              loading="lazy"
              className="w-full h-full object-cover object-center"
              src="/assets/Artboard1.png"
              alt="brand"
            />
          ) : (
            <Loader styling="w-full h-full" lightIcon />
          )}
        </Link>
      </div>

      <button
        type="button"
        onClick={() => setShowMenu(!showMenu)}
        className="text-4xl lg:hidden md:text-5xl text-white"
      >
        {showMenu ? (
          <HiX color={isScroll ? "black" : "white"} />
        ) : (
          <HiMenu color={isScroll ? "black" : "white"} />
        )}
      </button>

      <div
        className={`absolute top-full w-full md:w-[55%] bg-slate-50/90 lg:bg-slate-50/0 lg:w-fit right-0 flex flex-col lg:flex-row lg:justify-end lg:relative lg:items-center lg:ml-8 pb-6 pt-2 lg:pb-0 lg:pt-0 rounded-l duration-300 ease-out tracking-widest ${
          showMenu
            ? "opacity-100 translate-x-0"
            : "invisible translate-x-full lg:visible lg:translate-x-0"
        }`}
      >
        <motion.ul className=" ">
          <Navlinks
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            isScroll={isScroll}
          />
        </motion.ul>
      </div>
    </div>
  );
}

export default NavBar;
