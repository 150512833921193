import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  ArrowrightIcon,
  FixPhoneIcon,
  EnvolopeIcon,
  LocationIcon,
  PhoneIcon,
  InstaIcon,
  LinkedinIcon,
  FbIcon,
  YoutubeIcon,
  TwitterIcon,
} from "../icons";
import { toast } from "react-toastify";
import Modal from "./Modal";
import { usePostNewsMutation } from "../redux/apiCalls/apiSlice";
import CustomeInput from "../utils/CustomeInput";

const footerLinks = [
  { name: "Accueil", link: "/" },
  { name: "Projets", link: "projects" },
  { name: "Services", link: "services" },
  { name: "Blog", link: "blog" },
  { name: "A propos de nous", link: "about" },
  { name: "Devis", link: "devis" },
  { name: "Contact", link: "contact" },
];

function Footer() {
  const [email, setEmail] = useState("");
  const [sendNews] = usePostNewsMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendNews({ data: { email } }).unwrap();
      toast.success(
        "Votre abonnement à notre newsletter a été effectué avec succès."
      );
      setEmail("");
    } catch (error) {
      console.log("error in subscribeNewsLetter", error);
      toast.error("une erreur est survenu veuillez re-essayer");
    }
  };

  return (
    <>
      {/* newsletter */}
      <div className="px-5 md:px-10 lg:px-24 bg-mainColor h-56 flex flex-wrap justify-center lg:justify-between gap-10 items-center tracking-widest">
        <h1 className="text-2xl font-semibold text-white">
          Inscrivez-vous <br /> à notre newsletter
        </h1>
        <form className="relative w-96 text-white z-50" onSubmit={handleSubmit}>
          <CustomeInput
            value={email}
            setValue={setEmail}
            type={"email"}
            name={"Votre email... *"}
          />
          <button type="submit">
            <ArrowrightIcon
              listener={() => {}}
              width={32}
              color="white"
              className="absolute top-0 right-0 duration-200 peer-focus:fill-secondColor"
            />
          </button>
        </form>
      </div>

      {/* footer section */}
      <footer className=" py-8 px-5 md:px-10 lg:px-14 grid grid-cols-1 md:grid-cols-2  lg:grid-cols-4 gap-5 bg-black/90 tracking-widest ">
        {/* col-1 logo ans d play btn */}
        <div>
          <img
            loading="lazy"
            src="/assets/Artboard2.png"
            alt="codingart-brand"
            className="w-56"
          />
          <Modal forhome={false} />
        </div>
        {/* col-2 menu links*/}
        <nav className=" text-xl md:text-sm">
          <h3 className="text-white text-2xl md:text-xl font-bold mb-4">
            Liens
          </h3>
          {footerLinks.map(({ link, name }, id) => (
            <Link className="cursor-none" key={id} to={link}>
              <p className="text-gray-400 capitalize mb-3 md:mb-2 duration-200 ease-out hover:text-white">
                {name}
              </p>
            </Link>
          ))}
        </nav>
        {/* col-3 contact/address */}
        <div>
          <h3 className="text-white text-2xl md:text-xl font-semibold mb-4">
            Contact
          </h3>
          <div className="flex space-x-4 items-start mb-2 text-gray-400">
            <FixPhoneIcon width={18} color="rgb(156 163 175)" />
            <p className="text-xl lg:text-sm">
              Fix <br />
              <span className="text-base lg:text-xs">+212 524 143 286</span>
            </p>
          </div>

          <div className="flex space-x-4 items-start mb-2 text-gray-400">
            <PhoneIcon width={20} color="rgb(156 163 175)" />
            <p className="text-xl lg:text-sm">
              Téléphone <br />
              <span className="text-base lg:text-xs">+212 688 071 888</span>
            </p>
          </div>

          <div className="flex space-x-4 items-start mb-2 text-gray-400">
            <EnvolopeIcon width={20} color="rgb(156 163 175)" />
            <p className="text-xl lg:text-sm">
              E-mail <br />
              <span className="text-base lg:text-xs">contact@codingart.io</span>
            </p>
          </div>

          <div className="flex space-x-4 items-start mb-2 text-gray-400">
            <LocationIcon
              width={20}
              className="shrink-0"
              color="rgb(156 163 175)"
            />
            <p className="text-xl lg:text-sm">
              Adresse <br />
              <span className="text-base lg:text-xs">
                Bureau TA321, 3éme Étage, Technopark Sous Massa, Tilila Agadir -
                Maroc.
              </span>
            </p>
          </div>
        </div>
        {/* col-4 sign up for newsletter and social media*/}
        <div className="text-center flex gap-5 flex-col justify-center lg:pl-10 lg:text-start">
          <h3 className="text-white text-2xl md:text-xl font-semibold mb-4">
            Suivez-nous
          </h3>
          {/* social media icons */}
          <div className="flex items-center justify-center  gap-3 ">
            <Link
              to="https://www.instagram.com/stecodingart/"
              target="_blank"
              rel="noreferrer"
              className="tooltip tooltip-bottom tooltip-secondary"
              data-tip="Instagram"
            >
              <InstaIcon
                color="white"
                width="25"
                className="hover:fill-[#E1306C]"
              />
            </Link>
            <Link
              to="https://www.linkedin.com/company/codingartsarl/"
              target="_blank"
              rel="noreferrer"
              className="tooltip tooltip-bottom tooltip-primary"
              data-tip="Linkdin"
            >
              <LinkedinIcon
                color="white"
                width="25"
                className="hover:fill-blue-600"
              />
            </Link>
            <Link
              to="https://web.facebook.com/stecodingart"
              target="_blank"
              rel="noreferrer"
              className="tooltip tooltip-bottom tooltip-secondary"
              data-tip="Facebook"
            >
              <FbIcon
                color="white"
                width="25"
                className="hover:fill-[#3b5998]"
              />
            </Link>
            <Link
              to="https://www.youtube.com/channel/UCQWX3O5qAxjiDulmM88Bh_g"
              target="_blank"
              rel="noreferrer"
              className="tooltip tooltip-bottom tooltip-secondary"
              data-tip="Youtube"
            >
              <YoutubeIcon
                color="white"
                width="25"
                className="hover:fill-red-500"
              />
            </Link>
            <Link
              to="https://twitter.com/stecodingart"
              target="_blank"
              rel="noreferrer"
              className="tooltip tooltip-bottom tooltip-secondary"
              data-tip="Twitter"
            >
              <TwitterIcon
                color="white"
                width="25"
                className="hover:fill-mainColor"
              />
            </Link>
          </div>
        </div>
      </footer>

      {/* copyright */}
      <div className="flex flex-col-reverse text-center items-center md:flex-row md:justify-between px-5 md:px-12 bg-black py-6 text-sm text-gray-500">
        <p>&copy; 2023 CODINGART. Tous Droits Réservés par CODINGART</p>
        <p className="mb-4 md:mb-0">
          <span className="duration-200 ease-out hover:text-mainColor">
            Sécurité
          </span>{" "}
          •{" "}
          <span className="duration-200 ease-out hover:text-mainColor">
            Politique de Confidentialité et de cookies
          </span>{" "}
          •{" "}
          <span className="duration-200 ease-out hover:text-mainColor">
            Conditions D'utilisation
          </span>
        </p>
      </div>
    </>
  );
}

export default Footer;
