import { memo } from "react";

function CustomInput({ setValue, value, name, type }) {
  return (
    <div className="w-full relative pt-1">
      <input
        // placeholder={placeholder}
        type={type}
        id={name}
        name={name}
        required
        className="w-full h-full outline-none border-b p-1 bg-transparent  border-white duration-300 ease-out focus:border-secondColor/50 peer text-sm tracking-widest"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <label
        htmlFor={name}
        className="absolute w-fit tracking-widest left-2 bottom-1 text-sm peer-focus:left-0 peer-valid:left-0 peer-valid:bottom-7  peer-focus:bottom-7 ease-in-out duration-300 peer-focus:font-medium peer-valid:font-medium"
      >
        {name}
      </label>
    </div>
  );
}

export default memo(CustomInput);
