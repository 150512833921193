import React from 'react'
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { AiOutlineHome } from "react-icons/ai";
import { GrWorkshop } from "react-icons/gr";
import { MdMiscellaneousServices, MdOutlineContactSupport, } from "react-icons/md";
import { BsTextareaT } from "react-icons/bs";
import { GiRotaryPhone } from "react-icons/gi";

const Navlinks = ({ showMenu, setShowMenu, isScroll }) => {
    const menuLinks = [
        { name: "Projets", link: 'projects' },
        { name: "Services", link: 'services' },
        { name: "Blog", link: 'blog' },
        { name: "A propos de nous", link: 'about' },
        { name: "Devis", link: 'devis' },
        { name: "Contact", link: 'contact' },
    ];
    const linkVariants = (delay) => {
        return {
            initial: {
                y: 50,
                opacity: 0,
            },
            animate: {
                y: 0,
                opacity: 1,
                transition: {
                    ease: "linear",
                    delay,
                },
            },
        };
    };

    return (
        <div className='flex flex-col lg:flex-row '>
            {menuLinks.map(
                ({ name, link }, id) => (
                    <NavLink
                        key={id}
                        to={"/" + link}
                        onClick={() => setShowMenu(!showMenu)}
                        className={({ isActive }) =>
                            isActive
                                ? "text-mainColor cursor-none"
                                : `${isScroll ? "text-black cursor-none" : "lg:text-white cursor-none"}`
                        }
                    >
                        <motion.p
                            variants={linkVariants(0.25 * id)}
                            initial="initial"
                            whileInView="animate"
                            className="py-3 px-5  flex gap-5 items-center capitalize transition-all duration-200 hover:text-mainColor hover:bg-slate-50/40 lg:hover:bg-slate-50/0"
                        >
                            {link === "home" ? (
                                <AiOutlineHome size={20} className="lg:hidden" />
                            ) : link === "projects" ? (
                                <GrWorkshop size={20} className="lg:hidden" />
                            ) : link === "services" ? (
                                <MdMiscellaneousServices size={20} className="lg:hidden" />
                            ) : link === "about" ? (
                                <MdOutlineContactSupport size={20} className="lg:hidden" />
                            ) : link === "contact" ? (
                                <GiRotaryPhone size={20} className="lg:hidden" />
                            ) : (
                                <BsTextareaT size={20} className="lg:hidden" />
                            )}
                            <span className='tracking-wide'>{name}</span>
                        </motion.p>
                    </NavLink>
                )
            )}
        </div>
    )
}

export default Navlinks
