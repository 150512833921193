export function implementCursor() {
  let cursor = document.querySelector(".cursor");
  let cursorinner = document.querySelector(".cursor2");
//   var a = document.querySelectorAll("a");

  document.addEventListener("mousemove", function (e) {
    cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`;
  });

  document.addEventListener("mousemove", function (e) {
    let x = e.clientX;
    let y = e.clientY;
    cursorinner.style.left = x + "px";
    cursorinner.style.top = y + "px";
  });

  document.addEventListener("mousedown", function () {
    cursorinner.classList.add("cursorinnerhover");
  });

  document.addEventListener("mouseup", function () {
    cursorinner.classList.remove("cursorinnerhover");
  });
}
