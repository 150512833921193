import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function PhoneField({ placeholder, setValue, value }) {
  return (
    <PhoneInput
      country={"ma"}
      inputClass="placeholder:text-white"
      placeholder={placeholder}
      containerStyle={{ width: "100%" }}
      inputStyle={{
        width: "100%",
        color: "white",
        backgroundColor: "#00b3e2",
        outline: "none",
        border: "none",
        borderBottom: "1px solid white",
        paddingBottom: 2,
        borderRadius: 0,
      }}
      buttonStyle={{
        cursor:"none",
        backgroundColor: "#00b3e2",
        border: "none",
        borderBottom: "1px solid white",
      }}
      dropdownClass="noscroll"
      dropdownStyle={{
        color: "black",
      }}
      countryCodeEditable={false}
      onChange={(phone) => setValue(phone)}
      value={value}
    />
  );
}

export default PhoneField;
