import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { Autoplay } from "swiper";

// Import Swiper styles
import "swiper/css";
import {
  DesignIcon,
  DevOpsIcon,
  DevSpecIcon,
  HostIcon,
  IotIcon,
  MarketingIcon,
  MobileIcon,
  ServerIcon,
  WebDevIcon,
} from "../../icons/services-icons";
import { ArrowleftIcon, ArrowrightIcon } from "../../icons";
import ServiceCard from "./ServiceCard";

function ServicesCarousel() {
  return (
    <Swiper
      style={{ paddingTop: "60px" }}
      className="relative w-fit h-full"
      breakpoints={{
        1024: {
          slidesPerView: 4,
        },
        750: {
          slidesPerView: 3,
        },
        420: {
          slidesPerView: 2,
        },
        375: {
          slidesPerView: 1,
        },
      }}
      modules={[Autoplay]}
      autoplay={{
        delay: 1000,
        disableOnInteraction: false,
        waitForTransition: true,
        pauseOnMouseEnter: true,
      }}
      loop={true}
      // loopFillGroupWithBlank={true}
    >
      <SwiperSlide>
        <ServiceCard url={"design"} title="Design">
          <DesignIcon
            width="50"
            className="duration-200 ease-out group-hover:fill-white"
          />
        </ServiceCard>
      </SwiperSlide>

      <SwiperSlide>
        <ServiceCard
          url={"developpement-web"}
          reversed
          title="Développement Web"
        >
          <WebDevIcon
            width="50"
            className="duration-200 ease-out group-hover:fill-white"
          />
        </ServiceCard>
      </SwiperSlide>

      <SwiperSlide>
        <ServiceCard url={"marketing-digital"} title="Marketing Digital">
          <MarketingIcon
            width="50"
            className="duration-200 ease-out group-hover:fill-white"
          />
        </ServiceCard>
      </SwiperSlide>

      <SwiperSlide>
        <ServiceCard
          url={"application-mobile"}
          reversed
          title="Application Mobile"
        >
          <MobileIcon
            width="50"
            className="duration-200 ease-out group-hover:fill-white"
          />
        </ServiceCard>
      </SwiperSlide>

      <SwiperSlide>
        <ServiceCard url={"reseaux-et-serveur"} title="Réseaux et Serveur">
          <ServerIcon
            width="50"
            className="duration-200 ease-out group-hover:fill-white"
          />
        </ServiceCard>
      </SwiperSlide>

      <SwiperSlide>
        <ServiceCard url={"hebergement-web"} reversed title="Hébergement Web">
          <HostIcon
            width="50"
            className="duration-200 ease-out group-hover:fill-white"
          />
        </ServiceCard>
      </SwiperSlide>

      <SwiperSlide>
        <ServiceCard url={"iot"} title="IOT">
          <IotIcon
            width="50"
            className="duration-200 ease-out group-hover:fill-white"
          />
        </ServiceCard>
      </SwiperSlide>

      <SwiperSlide>
        <ServiceCard url={"dev-specifique"} reversed title="Dév Specifique">
          <DevSpecIcon
            width="50"
            className="duration-200 ease-out group-hover:fill-white"
          />
        </ServiceCard>
      </SwiperSlide>

      <SwiperSlide>
        <ServiceCard url={"devops-strategy"} title="DévOps Strategy">
          <DevOpsIcon
            width="50"
            className="duration-200 ease-out group-hover:fill-white"
          />
        </ServiceCard>
      </SwiperSlide>
      <SlideNextButton />
    </Swiper>
  );
}

function SlideNextButton() {
  const swiper = useSwiper();

  return (
    <div className="hidden md:flex absolute -top-2 right-2">
      <button
        type="button"
        onClick={() => swiper.slidePrev()}
        className="relative cursor-none"
        id="prev"
      >
        <ArrowleftIcon width="50" className={`mr-4 peer`} />
        <span className="absolute opacity-0 duration-200 ease-in-out peer-hover:opacity-100 top-1/2 -left-2 -translate-y-1/2 bg-mainColor -z-10 w-8 h-8 rounded-full inline-block"></span>
      </button>

      <button
        type="button"
        onClick={() => swiper.slideNext()}
        className="relative cursor-none"
        id="next"
      >
        <ArrowrightIcon width="50" className={` peer;`} />
        <span className="absolute opacity-0 duration-200 ease-in-out peer-hover:opacity-100 top-1/2 -right-2  -translate-y-1/2 bg-mainColor -z-10 w-8 h-8 rounded-full inline-block"></span>
      </button>
    </div>
  );
}

export default ServicesCarousel;
