import {
  DevOpsIcon,
  DevSpecIcon,
  HostIcon,
  MarketingIcon,
  MobileIcon,
  ServerIcon,
} from "./icons/services-icons";
import { DesignIcon, IotIcon, WebDevIcon } from "./icons";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPhoneAlt,
  FaTiktok,
  FaWhatsapp,
} from "react-icons/fa";

export const socialMediaIcons = [
  {
    nom: "Whatsapp",
    path: "https://wa.me/message/P7NVWXUTSSO4J1",
    logo: <FaWhatsapp size={25} />,
    color: "bg-[#25D366]",
  },
  {
    nom: "Téléphone",
    path: "tel:+212688071888",
    logo: <FaPhoneAlt size={22} />,
    color: "bg-[#333]",
  },
  {
    nom: "Linkedin",
    path: "https://www.linkedin.com/company/codingartsarl/",
    logo: <FaLinkedin size={25} />,
    color: "bg-blue-600",
  },

  {
    nom: "Facebook",
    path: "https://web.facebook.com/stecodingart",
    logo: <FaFacebook size={25} />,
    color: "bg-[#3b5998]",
  },
  {
    nom: "Instagram",
    path: "https://www.instagram.com/stecodingart/",
    logo: <FaInstagram size={25} />,
    color: "bg-[#E1306C]",
  },
  {
    nom: "Tiktok",
    path: "https://www.tiktok.com/@stecodingart/",
    logo: <FaTiktok size={25} />,
    color: "bg-[#000]",
  },
];

export const projects = [
  "Nature de votre projet",
  "Design",
  "Développement Web",
  "Marketing Digital",
  "Application Mobile",
  "Réseaux et Serveurs",
  "Hébergement Web",
  "IOT",
  "Dév Spécifique",
  "DevOps Strategy",
];
export const prices = [
  "Budget",
  "0 à 500€",
  "500€ à 1000€",
  "1000€ à 5000€",
  "5000€ à 10000€",
  "plus de 10000€",
];

export const firstSlide = [
  { id: 1, icon: "/assets/technolog-logos/angular.png" },
  { id: 2, icon: "/assets/technolog-logos/algolia.png" },
  { id: 3, icon: "/assets/technolog-logos/firebase.png" },
  { id: 4, icon: "/assets/technolog-logos/aws.png" },
  { id: 5, icon: "/assets/technolog-logos/braintree.png" },
  { id: 6, icon: "/assets/technolog-logos/django.png" },
  { id: 7, icon: "/assets/technolog-logos/docker.png" },
  { id: 8, icon: "/assets/technolog-logos/docusign.png" },
  { id: 9, icon: "/assets/technolog-logos/elastic.png" },
  { id: 11, icon: "/assets/technolog-logos/flutter.png" },
];
export const secondSlide = [
  { id: 12, icon: "/assets/technolog-logos/googlecloud.png" },
  { id: 13, icon: "/assets/technolog-logos/graphql.png" },
  { id: 14, icon: "/assets/technolog-logos/java.png" },
  { id: 15, icon: "/assets/technolog-logos/kotlin.png" },
  { id: 16, icon: "/assets/technolog-logos/kubernetes.png" },
  { id: 17, icon: "/assets/technolog-logos/mongodb.png" },
  { id: 18, icon: "/assets/technolog-logos/mysql.png" },
  { id: 19, icon: "/assets/technolog-logos/node.png" },
  { id: 20, icon: "/assets/technolog-logos/paypal.png" },
  { id: 21, icon: "/assets/technolog-logos/cmi.png" },
];
export const thirdSlide = [
  { id: 22, icon: "/assets/technolog-logos/php.png" },
  { id: 23, icon: "/assets/technolog-logos/reactjs.png" },
  { id: 24, icon: "/assets/technolog-logos/sendgrid.png" },
  { id: 25, icon: "/assets/technolog-logos/stripe.png" },
  { id: 26, icon: "/assets/technolog-logos/reactnative.png" },
  { id: 27, icon: "/assets/technolog-logos/swift.png" },
  { id: 28, icon: "/assets/technolog-logos/symfony.png" },
  { id: 29, icon: "/assets/technolog-logos/twillo.png" },
  { id: 30, icon: "/assets/technolog-logos/vuejs.png" },
  { id: 31, icon: "/assets/technolog-logos/apiplatform.png" },
];

export const services = [
  // design
  {
    title: "Design",
    url: "design",
    description:
      "Ne vous faites plus de soucis pour la création d'une identité de marque personnalisée et  unique, nos designers expérimentés s'occupent de vos besoins de création de graphiques.",
    image: "/assets/design2.webp",
    titleTwo: "Notre design personnalisé apporte du sens à votre marque",
    presentation: [
      <p>
        Le design constitue l'élément essentiel poussant les visiteurs du site à
        lire et à découvrir le contenu de votre page, influençant ainsi votre{" "}
        <span className="spanColor">trafic</span> et{" "}
        <span className="spanColor">taux de conversion</span>, et donc{" "}
        <span className="spanColor">la performance de votre entreprise.</span>
      </p>,
      <p>
        Nous vous aidons à réaliser un design favorisant votre classement dans
        les moteurs de recherche, à travers un produit visuel de qualité :
      </p>,
      <p className="lg:ml-4 ml-0">
        <span className="spanColor">Un design responsive : </span>CodingArt vous
        fournit un design facile à consulter par des écrans de toute taille.
      </p>,
      <p className="lg:ml-4 ml-0">
        <span className="spanColor">Un design léger : </span>Nous pensons à
        concevoir un design à la fois esthétique et à chargement immédiat.
      </p>,
      <p className="lg:ml-4 ml-0">
        <span className="spanColor">Une navigation fluide : </span> Nous offrons
        à vos utilisateurs une expérience de navigation conviviale, réduisant le
        taux de rebond sur votre page.
      </p>,
      <p>
        Il est donc important de travailler l'image de son application web ou
        mobile autant que son contenu.
      </p>,
    ],
    desc: [
      {
        secondtitle: "Une charte graphique cohérente",
        details: [
          <p>
            Toute marque nécessite la mise en place d'une charte graphique
            attractive et de qualité, constituant{" "}
            <span className="spanColor">l'identité visuelle</span> de son site,
            dont l'internaute{" "}
            <span className="spanColor">gardera en mémoire</span> dans le la
            durée et dans le temps
          </p>,
          <p>
            Nos designers vous accompagneront tout au long de votre{" "}
            <span className="spanColor">processus de création graphique</span>,
            de la création de votre <span className="spanColor">logo</span> à la
            mise en place des{" "}
            <span className="spanColor">
              maquettes de votre application mobile.
            </span>
          </p>,
          <p>
            Le logo reflète la personnalité et l'identité de votre marque. Pour
            la conception d'un logo unique et de qualité, nous veillons à ce
            qu'il répond à certains critères :
          </p>,
          <p className="spanColor">
            Représente et correspond à votre marque <br />
            Différent <br />
            Mémorable et Compréhensible <br />
          </p>,
          <p>
            Nous mettons en place une palette de couleurs, respectant
            l'homogénéité des couleurs employée au niveau de l'ensemble des
            éléments graphiques, notamment des icônes , la typographie, les
            images, les illustrations et les photos, visant à mettre en valeur
            votre produit ou service.
          </p>,
          <p>
            Une charte graphique homogène transmet une meilleure image et un
            message témoignant la performance de votre produit ou service à vos
            clients et visiteurs.
          </p>,
        ],
      },
      {
        secondtitle: "Un web design UX/UI",
        details: [
          <p>
            Nos designers interviennent tout au long du processus de conception
            de votre site web.
          </p>,
          <p>
            Nous échangeons des idées avec nos clients lors de réunions, pour
            clarifier la vision et les fonctionnalités attendues du site ou de
            l'application, y compris les principales options et outils de{" "}
            <span className="spanColor">l'interface utilisateur.</span> et ce
            afin de définir les <span className="spanColor">personas</span> de
            vos clients et visiteurs, permettant de déterminer avec précision{" "}
            <span className="spanColor">les axes de communication</span> à
            adapter pour mieux répondre à{" "}
            <span className="spanColor">vos attentes</span> et à celles{" "}
            <span className="spanColor">de votre public.</span>
          </p>,
          <p>
            Nous fournissons{" "}
            <span className="spanColor">un accompagnement UX/UI</span> sur
            mesure aux professionnels et aux particuliers en proposant des{" "}
            <span className="spanColor">solutions stratégiques</span> à{" "}
            <span className="spanColor">long et moyen terme,</span> vous
            permettant de gagner du temps grâce à un design graphique attrayant
            et capable de communiquer aisément vos valeurs pour votre :
          </p>,
          <p className="spanColor">
            Site vitrine <br />
            Site e-commerce <br />
            Plateforme web <br />
          </p>,
        ],
      },
      {
        secondtitle: "Design mobile",
        details: [
          <p>
            Nous nous adaptons à votre univers et à votre projet afin de{" "}
            <span className="spanColor">révéler le meilleur</span> de votre
            marque ! Nos graphistes prennent en charge la réalisation des
            différentes <span className="spanColor">interfaces</span> de votre{" "}
            <span className="spanColor">application mobile,</span> permettant
            une <span className="spanColor">visualisation</span> enchaînée et
            adaptée à vos <span className="spanColor">écrans,</span> offrant une{" "}
            <span className="spanColor">expérience rapide</span> et répondant
            aux besoins de vos utilisateurs.
          </p>,
        ],
      },
      {
        secondtitle: "Design des supports de communication",
        details: [
          <p>
            Notre agence répond à{" "}
            <span className="spanColor">tous vos besoins</span> en matière de{" "}
            <span className="spanColor">conception graphique,</span> de la
            création de contenus digitaux à la création de{" "}
            <span className="spanColor">
              supports de communication imprimés
            </span>{" "}
            uniques. Choisissez ce qui vous convient en termes de{" "}
            <span className="spanColor">supports print.</span> Le support papier
            reste un moyen de communication{" "}
            <span className="spanColor">nécessaire</span> et incontournable pour
            les entreprises et particuliers.
          </p>,
          <p>
            Démarquez-vous avec des supports de communication parfaitement
            adaptés à votre emblème :
          </p>,
          <p className="spanColor">
            Carte de visit <br />
            Brochure et flyer <br />
            Catalogue et livre d'entréprise <br />
            Newsletters <br />
            Affiche et PLV <br />
          </p>,
        ],
      },
    ],
  },
  // Developpement web
  {
    title: "Développement Web",
    url: "developpement-web",
    description:
      "Nos développeurs chevronnés s'occupent de votre site web, le cœur du dispositif digital de votre marque, en vous assurant une étude stratégique et ergonomique du développement web dans le respect des normes développement.",
    image: "/assets/design1.webp",
    titleTwo: "Nous vous accompagnons dans votre parcours vers l'excellence.",
    titleThree:
      "Nous développons plus qu'une application web, nous développons une marque dans son intégralité !",
    presentation: [
      <>
        Vous êtes une entreprise à la recherche d'une agence ayant de solides
        compétences en matière de technologie web ? CodingArt vous aide dans
        tous vos projets d'applications web, peu importe votre secteur
        d'activité, nous réfléchissons à des solutions adaptées à votre marché
        et à la concurrence digitale.
        <br />
        Nos développeurs vous accompagnent dans votre processus de
        transformation digitale en fonction de vos besoins et de ceux des
        utilisateurs. Nous vous proposons un accompagnement global durant le
        processus du développement de votre application web en s'adaptant à
        votre besoin.
        <br />
        <span className="spanColor">
          Nous développons plus qu'une application web, nous développons une
          marque dans son intégralité !
        </span>
      </>,
    ],
    desc: [
      {
        secondtitle: "Site vitrine",
        details: [
          <p>
            Un site vitrine comme son nom l'indique est une vitrine sur le web
            au profit de votre marque. Il présente votre image de marque et
            informe les utilisateurs sur l'activité de votre entreprise, et a
            également pour intérêt de présenter les descriptifs et
            caractéristiques de vos produits ou services afin de promouvoir
            votre entité.
          </p>,
        ],
      },
      {
        secondtitle: "Site e-commerce",
        details: [
          <p>
            Nos experts simplifient votre travail en tant que commerçant en vous
            fournissant les meilleurs modèles de sites web offrant une
            présentation attrayante des produits de votre marque, avec
            l'intégration de toutes les options et les fonctionnalités
            nécessaires de paiement ou livraison en ligne pour une bonne
            expérience d'achat.
          </p>,
        ],
      },
      {
        secondtitle: "Plate forme web",
        details: [
          <p>
            Les plateformes web sont parfaitement adaptées aux entreprises
            généralement classées et organisées en différents départements ou
            services, mais également aux enseignes mettant en contact plusieurs
            sites web ou clients utilisateurs, assumant donc le rôle d'un
            intermédiaire digital.
          </p>,
        ],
      },
      {
        secondtitle:
          "Les développeurs de CodingArt répondent à vos attentes spécifiques",
        details: [
          <p>
            Nous proposons une solution Saas pour un pilotage plus aisé et
            fluide des projets. <br />
            Quel que soit le type de votre entreprise, petite ou moyenne, notre
            équipe se chargera d'un accompagnement sur mesure pour la refonte et
            la création d'une application SAAS dédiée à votre activité. <br />
            Nos développeurs utilisent des technologies modernes pour la
            création d'une application Saas flexible,sécurisée et adaptée aux
            besoins de vos utilisateurs.
          </p>,
        ],
      },
      {
        secondtitle:
          "Optimiser vos performances digitales pour un rendue plus rentable",
        details: [
          <p>
            La gestion des tâches au moyen d'une communication instantanée reste
            l'un des objectifs principaux de toute entreprise, qui contribue à
            améliorer la qualité du travail. Dans ce contexte, nous proposons
            des solutions garantissant une gestion automatisée reliant les
            différents acteurs de votre entreprise en interne et en externe.
          </p>,
        ],
      },
      {
        secondtitle: "Développez votre intranet et extranet !",
        details: [
          <p>
            Notre équipe vous aide à développer votre plate-forme intranet et
            extranet, à savoir des{" "}
            <span className="spanColor">réseaux informatiques</span> dédiés au{" "}
            <span className="spanColor">partage</span> d'informations et de{" "}
            <span className="spanColor">ressources internes</span> ou{" "}
            <span className="spanColor">externes.</span> <br />
            Nous développons votre plateforme intranet et extranet permettant
            une meilleure communication et une collaboration simplifiée au sein
            de votre entreprise, ainsi qu'avec vos partenaires et clients
            externes. Ces applications vous assurent la mise en place de
            plusieurs outils et fonctionnalités facilitant le partage des
            ressources en temps réel, tels que les forums de discussion, les
            calendriers partagés, les gestionnaires de projets, etc. Nos
            graphistes s'adaptent à votre image de marque en intégrant une
            interface personnalisée et cohérente.
          </p>,
        ],
      },
      {
        secondtitle:
          "Simplifier la gestion de vos informations grâce à l'outil du CRM",
        details: [
          <p>
            votre CRM vous permet de centraliser et stocker vos données en un
            seul endroit. avec cet outil en planifiez vos tâches et suivez le
            progrès des actions commerciales en temps réel et de façon
            personnalisé.
            <br />
            Transformez en digital vos processus commerciaux en mesurant
            l'efficacité de vos campagnes marketing en fonction des résultats
            obtenus. Bénéficiez des caractéristiques des outils de relation
            clients :
          </p>,
          <p className="ml-0 lg:ml-8 spanColor">
            Un retour sur investissement remarquable <br />
            Une connaissance approfondie sur les habitudes de vos clients <br />
            L'organisation des données dans un seul endroit
          </p>,
          <p>
            Nous sommes à votre disposition pour tous besoins spécifiques afin
            de vous offrir des services adaptés à votre enseigne !
          </p>,
        ],
      },
    ],
  },
  // Marketing digital
  {
    title: "Marketing Digital",
    url: "marketing-digital",
    description:
      "Nos experts en community management et en communication prennent en charge la rédaction de votre contenu web et réseaux sociaux en répondant parfaitement au référencement SEO et aux tendances du monde digital.",
    image: "/assets/design2.webp",
    titleTwo: "Faites vous entendre avec notre stratégie webmarketing.",
    titleThree: "Faites-vous entendre grâce à notre stratégie de webmarketing.",
    desc: [
      {
        secondtitle: "Audit et veille concurrentielle",
        details: [
          <p className="spanColor font-bold">
            Nous gardons un oeil vigilant pour vous au niveau du marché.
          </p>,
          <p>
            Notre agence de communication web et digitale vous accompagne pour
            un positionnement marquant sur votre marché cible. Un audit
            marketing permet une étude approfondie des faiblesses et des forces
            de vos concurrents. Nos marketeurs mettent en place une démarche
            marketing en fonction de votre secteur d'activité. Nous nous
            chargeons de la collecte d'informations sur vos concurrents par le
            billet des outils de veille. Avec CodingArt Distinguez-vous des
            autres !
          </p>,
          <p>
            Un benchmarking est une étape primordiale pour la réalisation d'une
            stratégie efficace dont le but est de comprendre le marché et son
            évolution dans le temps, en retirant les différents informations et
            stratégies adoptés dans le monde du net et du digital, ainsi que les
            dernières tendances en relation avec votre projet.
          </p>,
        ],
      },
      {
        secondtitle: "Stratégie digitale",
        details: [
          <p>
            Le début de votre projet doit être initié par une stratégie
            digitale, celle-ci englobe un ensemble de procédés à respecter,
            permettant d'analyser les différents volets et comportements
            influençant votre marché.
          </p>,
          <p>
            Nous définissons votre plan de communication digitale en adoptant
            les outils d'analyses marketing nécessaires à votre développement
            sur le web et les réseaux sociaux, notamment en déterminant les
            canaux et le contenu appropriés pour faire passer votre message au
            bon public cible.
          </p>,
        ],
      },
      {
        secondtitle:
          " Une stratégie digitale destiné à toutes les catégories et tailles d'entreprises",
        details: [
          <p>
            Pour atteindre vos objectifs de marque, il est important de désigner
            l'ensemble Pour atteindre vos objectifs de marque, il est important
            de déterminer :
          </p>,
          <p className="spanColor font-bold lg:ml-8 ml-0">
            La définition des objectifs
          </p>,
          <p className="lg:ml-10 ml-0">
            Nos experts marketing et communication vous assistent pour la
            définition des objectifs pertinents de votre marque, en essayant de
            comprendre vos besoins d'entreprise dans sa globalité et en
            s'appuyant sur votre vision d'entreprise et objectifs commerciaux.
          </p>,
          <p className="spanColor font-bold lg:ml-8 ml-0">
            Vision et positionnement
          </p>,
          <p className="lg:ml-10 ml-0">
            Nous essayons de comprendre clairement votre vision et vos ambitions
            afin de pouvoir déterminer les activités de marketing les plus
            appropriées à vos objectifs commerciaux tout en ajoutant une touche
            de créativité au style et au ton de vos canaux de médias digitaux.
          </p>,
          <p className="spanColor font-bold lg:ml-8 ml-0">
            Déterminer votre cible et support de diffusion
          </p>,
          <p className="lg:ml-10 ml-0">
            Une{" "}
            <span className="spanColor">
              stratégie de communication digitale
            </span>{" "}
            vise essentiellement à éclaircir les objectifs de votre projet de
            façon mesurable.
          </p>,
          <p className="lg:ml-10 ml-0">
            Nous vous assistons dans la détermination précise de vos{" "}
            <span className="spanColor">personas</span> et de vos{" "}
            <span className="spanColor">cibles,</span> en tenant compte de
            plusieurs facteurs tels que leurs{" "}
            <span className="spanColor">
              comportements d'achat, intérêt, motivations
            </span>{" "}
            et <span className="spanColor">attentes</span> vis-à-vis de votre
            marque.
          </p>,
          <p>
            La connaissance approfondie de votre{" "}
            <span className="spanColor">clientèle digitale</span> nous permet de
            déterminer les meilleurs <span className="spanColor">moyens</span>{" "}
            et <span className="spanColor">médias sociaux</span> pour
            l'atteindre, notamment :
          </p>,
          <p className="spanColor">
            Réseaux sociaux : création de contenu ( blog, image, videos et
            reels), création de contenu articles,etc. <br />
            Campagnes publicitaires <br />
            Campagnes emailing <br />
            Campagnes de référencement payant SEA <br />
            Contenu optimisée SEO <br />
            KPI ( les indicateurs clés de performances ) : étudie les retombées
            de votre stratégie sur votre retour sur investissement ROI.
          </p>,
        ],
      },
      {
        secondtitle: "Community management",
        details: [
          <p>
            Nos community managers vous accompagnent dans votre stratégie de
            réseaux sociaux. Nous veillons à bien interpréter les valeurs et les
            atouts de votre marque afin de faire passer votre message de la
            meilleure façon possible en termes de communication et de
            visibilité.
          </p>,
          <p>
            Nos community managers alimentent votre page par la création des
            publications et des campagnes publicitaires ciblées dans le but de
            bâtir{" "}
            <span className="spanColor">
              une communauté en ligne intéressée
            </span>{" "}
            et <span className="spanColor">engagée.</span>
          </p>,
        ],
      },
    ],
  },
  // Application mobile
  {
    title: "Application Mobile",
    url: "application-mobile",
    description:
      "Nos développeurs Mobile prennent le temps d'étudier la nature de vos produits et services, dans l'optique de concevoir une application mobile offrant une expérience utilisateur captivante et optimale.",
    image: "/assets/design1.webp",
    titleTwo:
      "Concevez votre identité en application mobile en toute ergonomie et simplicité",
    presentation: [
      <p className="spanColor font-bold">
        CodingArt s'adaptent à vos large choix CodingArt développe vos
        applications mobiles en toute ergonomie et en toute simplicité.
      </p>,
      <>
        La présence de votre entreprise sur le marché des applications mobiles
        n'est plus un luxe mais plutôt une nécessité pour vous adapter aux
        préférences des utilisateurs et aux tendances technologiques du marché
        digital actuel. Nous vous accompagnons dans toutes les étapes de votre
        choix d'application, en faisant de votre idée une réalité à travers un
        concept de marque unique. Notre agence vous accompagne dans le
        développement de différents types d'applications mobiles, adaptées à
        votre besoin de projet et de votre cible :
      </>,
      <p className="spanColor font-bold">
        Nous visons à vous assurer une expérience utilisateur réussie.
      </p>,
    ],
    desc: [
      {
        secondtitle:
          "Nous développons des applications mobiles natives sur mesure",
        details: [
          <p>
            Actuellement, le client est plus présent sur les plateformes via son
            smartphone qu'autre temps, c'est l'occasion pour les entreprises de
            profiter de cette présence et de s'aligner aux préférences digitales
            actuelles.
          </p>,
          <p>
            Nos développeurs mobile peuvent concevoir des applications natives
            développées spécifiquement pour votre projet, disponible sous iOS ou
            Android.
          </p>,
          <p>
            Cette technologie permet d'utiliser et d'accéder à un ensemble de
            technologies sur le téléphone de l'utilisateur, notamment le GPS, la
            notification push up, la caméra, l'audio et autres. Ce type
            d'application offre aux usagers une navigation fluide dotée
            d'options puissantes.
          </p>,
          <p className="spanColor">
            <span>Language de programmation :</span> <br />
            Android : Java/ Kotlen <br />
            IOS : SWIFT <br />
          </p>,
        ],
      },
      {
        secondtitle: "Les applications hybrides",
        details: [
          <p>
            Une application hybride vous permet de bénéficier des avantages de
            deux types d'applications : la performance du native et la
            compatibilité multiplateforme, c'est la combinaison d'une
            application native et d'une application web. C'est une solution
            économique et rentable disponible sur les plateformes IOS et
            Android. Une application hybride vous permet de bénéficier des
            avantages de deux types d'applications : la performance du native et
            la compatibilité multiplateforme.
          </p>,
        ],
      },
      {
        secondtitle: "Progressive Web Applications (PWA)",
        details: [
          <p>
            Une PWA ou Progressive Web App est une forme d'application mobile
            responsive mis en valeur par les moteurs de recherche, tout en ayant
            accès à certaines fonctionnalités dans le smartphone de
            l'utilisateur. La PWA est donc un support informatique accessible
            comme un site web et performant comme une application mobile.
          </p>,
        ],
      },
      {
        secondtitle: "Nous lions application mobile et marketing !",
        details: [
          <p>
            <span className="spanColor font-bold">
              Vous avez un projet à venir que vous considérez très spécial ?
            </span>{" "}
            Nos développeurs mobile et experts marketing se chargent de votre
            accompagnement. Un plan de communication et une stratégie marketing
            doivent être mis en place avant le lancement de votre projet, c'est
            l'un des leviers les plus efficaces pour faire connaître votre
            projet d'app mobile et pouvoir développer sa réussite.
          </p>,
          <p>
            Nous faisons donc la combinaison entre le développement d'app mobile
            et les stratégies de marketing de communication digitale, c'est le
            marketing mobile !
          </p>,
          <p>
            Nous nous chargeons de la promotion de votre projet application
            mobile à travers la gestion des réseaux sociaux, les campagnes
            publicitaires, les annonces en ligne et blogs par un contenu et
            messages cohérent avec l'identité de l'emblème tenant compte de la
            cible visée. Notre équipe tient compte ainsi
          </p>,
          <p>
            Notre équipe vous fournit un suivi continu permettant d'évaluer la
            performance de votre application et réseaux sociaux afin d'effectuer
            les modifications et ajustements nécessaires adaptés aux besoins des
            utilisateurs actuels du monde digital .
          </p>,
          <p>
            Notre équipe assure un suivi continu destiné à évaluer les
            performances de votre application et de vos compagnes communication
            dans les réseaux sociaux en vue d'apporter les modifications et
            ajustements nécessaires adaptés aux besoins des utilisateurs
            contemporains.
          </p>,
          <p>
            En développant une application mobile de qualité basée sur une étude
            de marketing avancée, votre projet développe rapidement sa présence
            en ligne. C'est la clé incitant les utilisateurs à accéder et à
            télécharger votre application.
          </p>,
        ],
      },
    ],
  },
  // Reseaux et serveur
  {
    title: "Réseaux et Serveurs",
    url: "reseaux-et-serveur",
    description:
      "Nous développons votre infrastructure réseaux en assurant la maintenance, l'infogérance et la sécurité  de vos différents types de serveurs, permettant  une disponibilité de votre site internet  en permanence,  un outil de communication principale.",
    image: "/assets/design2.webp",
    titleTwo: "CODINGART vous offre une infrastructure réseau",
    titleThree: "Sécurisée, Optimisée avec une Meilleure Connectivité.",
    presentation: [
      <>
        A L'ère actuel, la totalité des entreprises ont recours aux nouvelles
        technologies, qui fait que Ie monde de L'informatique occupe une place
        très importante dans la vie de L'être humain, et ce notamment pour
        L'exécution de plusieurs tâches au moyen de réseaux Internet offrant une
        connectivité instantanée dans le cadre des services internes et externes
        que ce soit au niveau de la communication, de la gestion ou de la
        facturation.
        <br />
        Les réseaux et les serveurs sont des composantes essentielles qui
        garantissent la sécurité de votre structure, ils vous permettent
        d'améliorer vos performances en surveillant les données et les fichiers
        sensibles, ainsi que la correction d'éventuels bugs.
        <br />
        <span className="spanColor font-bold">
          CodingArt vous accompagne dans la mise en oeuvre de votre structure
          réseau :
        </span>
        <br />
        <span className="spanColor">
          Avez vous pensé à sécuriser votre infrastructure ? <br />
          Une infrastructure réseau de qualité indique la performance de votre
          organisation !
        </span>
        <br />
        Votre projet d'infrastructure est entre de bon mains
        <br />
        <span className="spanColor">Installation réseaux :</span>
      </>,
    ],
    desc: [
      {
        secondtitle:
          "Une infrastructure réseau de qualité traduit la performance de votre organisation !",
        details: [
          <p>
            L'environnement de votre entreprise est constitué d'un ensemble de
            systèmes d'information formant le socle indispensable à son bon
            fonctionnement. CodingArt vous accompagne avec son expertise pour
            l'installation de vos réseaux et serveurs souhaités, choisissez la
            solution d'infrastructure réseaux qui vous convient à vos besoins et
            attentes !
            <br />
            Nous nous adaptons aux besoins de nos clients en tant que
            particuliers ou entreprises par la mise en place d'une
            infrastructure informatique répondant à tous types de demandes
            d'installation de réseaux informatiques. Nous assurons une
            infrastructure réseau de qualité solide et hautement sécurisée.
          </p>,
        ],
      },
      {
        secondtitle: "Sécuriser vos données avec CodingArt",
        details: [
          <p>
            Nos experts vous proposent des technologies innovantes permettant la{" "}
            <span className="spanColor">mise en place</span> d'une{" "}
            <span className="spanColor">architecture complète</span> de{" "}
            <span className="spanColor">système informatique (SI).</span> Nous
            assurons des améliorations pertinentes influençant les{" "}
            <span className="spanColor">performances</span> de{" "}
            <span className="spanColor">vos locaux</span> et qui répondent au
            niveau de <span className="spanColor">sécurité recommandé.</span>
            <br />
            Ainsi, le choix de votre réseau dépend de la taille de votre
            entreprise ou local ainsi qu'au volume de données traités :
          </p>,
          <p className="lg:ml-8 ml-0">
            <span className="spanColor">
              Développement d'infrastructure réseaux cloud :
            </span>
            <br />
            La <span className="spanColor">technologie sans fil</span> est
            caractérisée par sa <span className="spanColor">souplesse</span> et
            sa <span className="spanColor">simplicité</span> d'utilisation, qui
            ne nécessite pas d'investissement important de la part de
            l'entreprise.
            <br />
            Les équipes qui travaillent avec une infrastructure réseau cloud
            apportent plus de <span className="spanColor">valeur</span> et de{" "}
            <span className="spanColor">performance,</span> ainsi qu'une{" "}
            <span className="spanColor">rapidité d'exécution,</span>{" "}
            caractérisée par un
            <span className="spanColor">moindre effort</span> requis pour la
            maintenance d'une infrastructure physique sur les lieux. Dans cette
            optique, nous encourageons les entreprises à choisir cette
            technologie offrant une sécurité et une flexibilité accrues par
            défaut !
            <br />
            Audit et proposition de solutions de sécurité de votre réseau.{" "}
            <br />
            Administration en ligne de votre réseau. <br />
            Accessibilité des données rapidement et partout.
            <br />
            <span className="spanColor font-bold">
              Développement d'infrastructure réseaux local :
            </span>
            <br />
            Votre projet d'infrastructure est entre de bonnes mains. Nous vous
            accompagnons dans ce processus en nous adaptant à votre choix par le
            biais de <span className="spanColor">
              nos serveurs limitant
            </span>{" "}
            tous types de <span className="spanColor">défaillances.</span>
            <br />
            Nous assurons la mise en œuvre d'un même{" "}
            <span className="spanColor">système de réseau</span> dans votre
            infrastructure. Chaque poste de travail ou application devient
            capable de <span className="spanColor">communiquer facilement</span>{" "}
            avec tout le reste des appareils.
            <br />
            Nos techniciens réseau vous fournissent une infrastructure locale
            caractérisée par :
            <br />
            <span className="spanColor font-bold">Flexibilité :</span> accès et
            communication facile à vos serveurs en interne et en externe même en
            cas de coupure internet.
            <br />
            <span className="spanColor font-bold">
              Une meilleure productivité :
            </span>{" "}
            partage des données et des applications plus efficacement.
            <br />
            <span className="spanColor font-bold">
              Sécurité optimale :
            </span>{" "}
            permet à vos locaux d'être protégés par des pare-feu et des mots de
            passe spécifiques qui empêchent tout accès non autorisé.
            <br />
            <span className="spanColor font-bold">
              Gestion automatisée :
            </span>{" "}
            contrôle centralisé du réseau à partir de comptes d'administrateur
            en un seul endroit.
            <br />
            Maintenance simplifiée du réseau .
            <br />
            <span className="spanColor font-bold">
              {" "}
              La maintenance et gestion de vos serveurs :{" "}
            </span>
          </p>,
          <p>
            Nous nous occupons de l'installation, la maintenance et la gestion
            de différents types de serveurs à la demande :
            <br />
            La mise à jour de vos logiciels et l'entretien régulier de votre
            matériel garantissant un haut niveau de sécurité et de protection
            contre tous les types d'attaques informatiques. <br />
            Instaurer des systèmes de sécurité appropriés. <br />
            Identifier et prévenir d' éventuelles pannes informatiques et
            antivirus dans le but d'éviter les interruptions au sein de votre
            structure. <br />
            Analyse des différents log de vos serveurs. <br />
            Assurer la disponibilité de vos serveurs en temps réel.
          </p>,
        ],
      },
      {
        secondtitle:
          "Bénéficiez d'une infrastructure de qualité en nous confiant la gestion de vos serveurs !",
        details: [
          <p>
            Nos consultants en informatique vous aident à choisir le type de
            serveur répondant le mieux à vos besoins unique personnelle ou
            d'entreprise, et ce en fonction de de vos applications, de la
            quantité de données ainsi que la charge de votre travail.
          </p>,
          <p className="lg:ml-8 ml-0">
            <span className="spanColor font-bold">Serveur web :</span>{" "}
            recommandé pour l'hébergement de vos applications et sites web, en
            vous donnant la possibilité de gestion totale selon vos propres
            raisons et objectifs administrateur. Nos serveurs web comprennent :
            Nginx, Apache, Apache Tomcat, Caddy,etc.
            <br />
            <span className="spanColor font-bold">
              Serveur de base de données :
            </span>{" "}
            permet la gestion et le stockage des données de votre entreprise,
            vous offrant en tant qu'utilisateurs la possibilité d'accéder à vos
            données et de les manipuler. Nous optons pour les serveurs suivants
            : MySQL, PostgreSQL, MongoDB, Graphql, etc.
            <br />
            <span className="spanColor font-bold">
              Serveur de fichiers :
            </span>{" "}
            Nous utilisons Microsoft Windows Server, FTP, SFTP, Samba pour
            stocker et partager en temps réel les fichiers de votre structure
            sur un réseau donné.
            <br />
            <span className="spanColor font-bold">
              {" "}
              Serveur de messagerie :
            </span>{" "}
            Nous gérons par l'intermédiaire des serveurs Postfix, Exim,
            Squirrelmail, Citadel, Axigen, etc. vos services de messagerie, y
            compris les fonctions automatisées qui concernent la gestion de vos
            fichiers par l'envoi ou la réception de vos courriers électroniques
            ainsi que la filtration des spams
          </p>,
        ],
      },
    ],
  },
  // Hebergement web
  {
    title: "Hébergement Web",
    url: "hebergement-web",
    description:
      "Consacrez vous sur le cœur de votre métier en confiant la gestion de votre hébergement web à notre agence web pour pouvoir bénéficier de plusieurs avantages de réseau, maintenance et déploiement des serveurs.",
    image: "/assets/design1.webp",
    titleTwo:
      "Un hébergement performant et sur mesure dédié à vos projets digitaux.",
    presentation: [
      <>
        Nous vous proposons des solutions d'hébergement de qualité adaptées à
        vos sites web et répondant à vos besoins de projets en termes de
        stockage, fiabilité et scalabilité.
      </>,
      <>
        CodingArt vous offre un large choix vous aidant à optimiser la
        performance de votre site web avec le service hébergement web offrant
        une connectivité stable.
      </>,
      <>
        Le choix du type d'hébergement web dépend de l'ampleur de votre projet
        et de la stratégie de votre site web. Nous proposons des solution pour
        l'hébergement de votre site web
      </>,
      <p className="italic">
        (Le choix du type d'hébergement web dépend de l'ampleur de votre projet
        et de la stratégie de votre site web, à travers cette option vous louez
        une petite part d'espace sur un seul serveur web physique. Nous vous
        fournissons à travers cette option un hébergement de plusieurs sites web
        sur un seul serveur web physique, offrant ainsi un service stable et à
        faible coût servant à déployer plusieurs sites web sur internet)
      </p>,
    ],
    desc: [
      {
        secondtitle: "Hébergement partagé",
        details: [
          <p>
            Cette option est idéale pour les petits sites partageant les mêmes
            ressources d'un serveur web physique. <br /> En choisissant ce type
            d'hébergement vous réduisez vos dépenses tout en disposant d'un
            service stable servant à déployer plusieurs sites web sur internet.
          </p>,
        ],
      },
      {
        secondtitle: "Hébergement dédié",
        details: [
          <p>
            Ce type d'hébergement est spécialement destiné aux entreprises ne
            pouvant pas partager leurs serveurs avec d'autres sites web,
            détenant des données privées et plus sensibles que d'autres
            secteurs, comme les banques ou l'éducation.
          </p>,
          <p>
            Nous hébergeons le site web des entreprises ayant ce besoin sur un
            serveur dédié qui devient leur propriété exclusive, offrant une plus
            grande flexibilité et de meilleures performances à un prix
            abordable.
          </p>,
        ],
      },
      {
        secondtitle: "Hébergement VPS (serveurs privés virtuels )",
        details: [
          <p>
            Nous recommandons ce type d'hébergement aux sites Web avec un trafic
            à moyen élevé.
          </p>,
          <p>
            Nos serveurs privés virtuels vous offrent un contrôle total en vous
            permettant de personnaliser votre environnement, notamment en
            installant vos propres paramètres de serveur et en utilisant les
            technologies souhaitées, tout en assurant un stockage sécurisé à
            large bande passante.
          </p>,
          <p>
            Vous avez donc la main pour pouvoir résoudre vos soucis techniques
            et gérer votre site sans compromettre les performances.
          </p>,
        ],
      },
      {
        secondtitle: "Hébergement WordPress",
        details: [
          <p>
            Avec CodingArt, bénéficiez d'un hébergement wordpress adapté à vos
            sites e-commerce, sites de presse ou blogs,etc. Cette option est
            idéale pour les sites ayant besoin de rester fonctionnel à tout
            moment en raison de leur activité dans le but de présenter un
            contenu de qualité
          </p>,
          <p>
            Nous vous offrons une meilleure assistance, un site web rapide et
            sécurisé permettant de gérer votre site en toute flexibilité grâce à
            notre module de cache embarqué.
          </p>,
          <p>
            Votre projet est entre de bon mains, nous installons un système de
            gestion de contenu CMS ainsi qu'une certificat SSL, qui vous
            permettra d'optimiser votre référencement en ligne.
          </p>,
        ],
      },
      {
        secondtitle: "Hébergement sur le cloud",
        details: [
          <p>
            Contrairement aux autres types d'hébergement, ce type d'hébergement
            opte pour un réseau de serveurs interconnectés : virtuels et
            physiques. Concentrez vous sur votre activité principale en nous
            confiant la maintenance de votre site. Nous vous garantissons des
            solutions répondant à vos besoins en termes de technologie actuelle,
            un haut niveau de disponibilité avec plus de fiabilité, notamment
            grâce à la fonction d'équilibrage qui permet plus de protection et
            de sécurité.
          </p>,
          <p>
            Profitez davantage de notre technologie pour vous démarquer de la
            concurrence et offrir un service de qualité de manière instantanée
            grâce à une gestion de données flexible et sécurisée .
          </p>,
        ],
      },
      {
        secondtitle: "Pourquoi héberger votre site chez CodingArt ?",
        details: [
          <p className="spanColor font-bold">
            Pourquoi confier l'hébergement de votre site à CodingArt ?
          </p>,
          <p className="lg:ml-8 ml-0">
            <span className="spanColor">Une disponibilité 24/24h 7/7j </span>
            <br />
            Nous mettons à votre disposition des plateformes d'hébergement avec
            un taux de disponibilité convenable, gardant votre site en ligne
            tout au long de l'année sans coupure ou lenteur de chargement.
          </p>,
          <p className="lg:ml-8 ml-0">
            <span className="spanColor">
              Nous nous occupons du bon fonctionnement de votre site{" "}
            </span>
            <br />
            Vous n'êtes pas trop expérimenté dans le domaine de l'hébergement ?
            Notre agence est là pour vous proposer un service dédié uniquement à
            l'analyse de votre site, vous permettant de prendre des décisions
            futures pour améliorer l'expérience utilisateur et donc la
            performance de votre site.
          </p>,
          <p className="lg:ml-8 ml-0">
            Vous n'êtes pas trop expérimenté dans le domaine de l'hébergement ?
            Notre agence est là pour vous proposer un service dédié uniquement à
            l'hébergement de votre site, ou vous pouvez choisir le type
            d'hébergement qui convient le mieux à vos besoins de site web, et ce
            dans le but d'améliorer l'expérience utilisateur et donc la
            performance de votre site.
          </p>,
          <p className="lg:ml-8 ml-0">
            <span className="spanColor">Fiabilité et sécurité </span>
            <br />
            Votre site est entre de bonnes mains ! Ne vous inquiétez plus pour
            la sécurité de votre site, nous vous assurons une protection
            renforcée contre les attaques et les virus en gérant la mise à jour
            et la sauvegarde régulière et automatique de vos données. <br />{" "}
            <br />( Nos fonctionnalités permettent une sauvegarde automatique et
            fiable de vos données)
          </p>,
          <p className="lg:ml-8 ml-0">
            <span className="spanColor">Économie et support technique</span>
            <br />
            Économisez votre temps et votre budget en évitant les frais et les
            coûts associés à l'achat, à la gestion et à la maintenance de votre
            serveur.
          </p>,
          <p className="lg:ml-8 ml-0">
            Nos experts vous garantissent la mise à jour de vos serveurs en
            temps nécessaire et en toute sécurité. Ils vous fournissent un
            soutien efficace qui vous permet de résoudre tout problème technique
            ou de configuration pour vos clients. <br /> <br />
            Nos spécialistes se mettent à votre disposition pour résoudre tout
            problème urgent survenant sur votre site web.
          </p>,
        ],
      },
    ],
  },
  // IOT
  {
    title: "IOT",
    url: "iot",
    description:
      "La technologie IOT intervient sur tous les domaines, il vous d'être liée et  de communiquer indépendamment, en vous offrant une connectivité au moment présent grâce aux objets connectés.",
    image: "/assets/design2.webp",
    titleTwo: "L'IOT : Pour l'innovation de votre projets",
    titleThree: "L'IOT : Pour des projets Smart",
    titleFour: "L'IOT : Pour une innovation connecté de vos projets.",
    presentation: [
      <>Intégrez l'IOT dans tous vos domaines</>,
      <>
        L'IOT est considéré aujourd'hui comme étant une révolution du monde du
        numérique, c'est l'ensemble des objets et applications permettant de
        changer notre mode de vie, notre façon d'apprendre, de travailler et de
        nous divertir !
      </>,
      <>
        Cette technologie continuellement en accroissement, peut être expliquée
        de façon simplifiée comme une extension de l'internet envers les outils
        et objets récepteur au moyen d'autres équipements électroniques
        connectés à internet. Aujourd'hui, de nombreuses entreprises ont
        essentiellement besoin d'améliorer et d'intégrer cette nouvelle
        innovation.
      </>,
      <>
        Nous vous proposons des solutions IOT d'objets connectés adaptés à votre
        secteur, en utilisant les dernières technologies.
      </>,
    ],
    desc: [
      {
        secondtitle: "Nos prestations IOT",
        details: [
          <p>
            Bénéficier d'une structure développée et automatisée avec nos offres
            IOT comprenant des prestations personnalisées répondant à vos
            besoins spécifiques basée les dernières technologies :
          </p>,
          <p className="spanColor lg:ml-8 ml-0">Une meilleure Connectivité</p>,
          <p className="lg:ml-8 ml-0">
            Nos protocoles réseaux permettent une communication efficace entre
            les appareils. Ils sont conçus pour être utilisés dans une variété
            d'applications IoT, notamment les maisons et villes intelligentes,
            l'automatisation industrielle.
          </p>,
          <p className="spanColor lg:ml-8 ml-0">
            Des Capteurs fiables et sécurisés
          </p>,
          <p className="lg:ml-10 ml-0">
            Nous assurons l'apparition de capteurs fiables et agréables dans
            votre environnement, ils permettent l'amélioration de votre
            processus d'accomplissement des tâches, ainsi que la collecte et
            l'automatisation des données sur vos produits et outils utilisés.
          </p>,
          <p className="lg:ml-8 ml-0">
            (tels que les capteurs de surveillance de température ou les
            capteurs de mouvement,etc).
          </p>,
          <p className="spanColor lg:ml-8 ml-0">Big Data</p>,
          <p className="lg:ml-10 ml-0">
            L'IOT vous permet d'automatiser votre processus de gestion, de
            collecte et de stockage des données en temps réel et à grande
            échelle, pour une meilleure compréhension et prise de décision.
          </p>,
          <p className="spanColor lg:ml-8 ml-0">AI et machine learning</p>,
          <p className="lg:ml-10 ml-0">
            Nous mettons en œuvre des solutions de l'intelligence artificielle
            répondant à vos problématiques. Nos modèles AI sont conçues pour
            vous offrir une visualisation et une analyse de données plus clair,
            identifiant les tendances à partir des données collectées et visant
            à améliorer vos prévisions et besoins du futur.
          </p>,
          <p className="spanColor lg:ml-8 ml-0">Cloud computing</p>,
          <p className="lg:ml-10 ml-0">
            La mise en œuvre de solutions Cloud vous permet de traiter et de
            stocker des données volumineuses, tout en garantissant un niveau de
            cybersécurité permettant de protéger vos données et appareils
            connectés contre les menaces.
          </p>,
        ],
      },
      {
        secondtitle: "Comment puis-je intégrer l'IoT dans mon business ?",
        details: [
          <p>
            L'intégration de codes personnalisés dans les applications mobiles
            de vos clients, ce qui permet de détecter leur choix et leur
            identité d'acheteur. Les capteurs intelligents dans vos magasins,
            capables de détecter les mouvements des clients et de faire le lien
            entre l'espace physique et le monde virtuel.
          </p>,
          <p>
            Le geofencing est une forme de GPS intelligent qui aide les clients
            à repérer votre magasin le plus proche. Cette campagne IOT permet au
            client de se rendre dans votre magasin plutôt que chez vos
            concurrents.
          </p>,
          <p>
            Intégrer des étiquettes avec des capteurs imprimés sur votre
            produit. cette technologie vous permet de collecter des informations
            pertinents sur votre client finale en suivant les mouvements des
            produits à partir de la chaîne d'approvisionnement jusqu'au magasin
            de consommation
          </p>,
        ],
      },
    ],
  },
  // Dev specifique
  {
    title: "Dév Spécifique",
    url: "dev-specifique",
    description:
      "Les conseillers de CodingArt vous accompagnent pour tous besoins spécifiques dans le domaine d'ingénierie informatique afin de vous proposer les meilleures solutions de choix technologiques.",
    image: "/assets/design1.webp",
    titleTwo: "",
    presentation: [],
    desc: [],
  },
  // DevOps strategy
  {
    title: "DevOps Strategy",
    url: "devops-strategy",
    description:
      "Nos experts vous aideront à mettre en œuvre une stratégie DevOps permettant d'accélérer la livraison de vos logiciels et services.",
    image: "/assets/design1.webp",
    titleTwo: "Distinguez vous en intégrant une stratégie Devops.",
    titleThree:
      "Devops : une démarche positive pour l'avancement de vos projets.",
    titleFour: "codingArt : votre partenaire pour un accompagnement approprié.",
    desc: [
      {
        secondtitle: "Qu'est qu'une stratégie Devops ?",
        details: [
          <p>
            Devops est une méthodologie agile qui met les deux équipes de
            développement d'applications en collaboration pour livrer une
            application ou un logiciel dont les fonctionnalités sont adaptées
            aux opportunités du marché et répondant aux besoins des utilisateurs
            en temps voulu.
          </p>,
        ],
      },
      {
        secondtitle:
          "La culture Devops : l'humain est placé au cœur de la culture devops.",
        details: [
          <p>
            La culture devops est fondée sur les valeurs de partage et de
            transparence, visant à créer un espace entre les membres de l'équipe
            où ils peuvent exprimer membres de l'équipe où ils peuvent
            s'exprimer et communiquer librement à propos de leurs contraintes et
            difficultés, afin de fixer des objectifs communs et de pouvoir
            prendre et développer les bonnes actions.
          </p>,
          <p className="lg:ml-8 ml-0 spanColor">Surveillance et suivi </p>,
          <p className="lg:ml-10 ml-0 ">
            La stratégie Devops vous offre les outils nécessaire pour une
            démarche agile efficace, notamment par les KPI (indicateurs de
            performance) qui fournissent aux opérations de le développement des
            données sur les applications performant afin de monitorer l'activité
            et de surveiller tout changement et d'anticiper les problèmes les
            plus fréquents afin d'éviter leur reproduction et garantir la
            réussite des nouvelles versions logicielles.
          </p>,
        ],
      },
      {
        secondtitle: "Nos prestations Devops ?",
        details: [
          <p>
            Nous assurons le développement continu de vos différentes
            applications pour une souplesse, performance et stabilité de votre
            système. Nous mettons en œuvre les stratégies et outils décrits
            ci-après :
          </p>,
          <p className="lg:ml-8 ml-0 spanColor">
            Livrez de manière fiable avec l'approche CI/CD
          </p>,
          <p className="lg:ml-10 ml-0 ">
            La démarche devops vise essentiellement à améliorer la qualité et la
            fiabilité des déploiements logiciels, en utilisant les outils CI/CD
            qui vise à automatiser les étapes de distribution continu et de
            déploiement continue tout au long de cycle de vie de l'application,
            phase d'intégration, phase de test jusqu'a la phase de distribution
            et déploiement.
          </p>,
          <p className="lg:ml-8 ml-0 spanColor">
            Kubernetes renforce l'automatisation de votre activité
          </p>,
          <p className="lg:ml-10 ml-0 ">
            Kubernetes est une plateforme d'orchestration d'applications
            conteneurisées, visant à rationaliser l'écosystème informatiques
            généralistes, permettant d'automatiser les processus de déploiement
            et processus de développement, ainsi que votre trafic web en
            production. Kubernetes a pour but d'accroître votre activité en
            s'adaptant aux besoins des applications logicielles
          </p>,
          <p className="lg:ml-8 ml-0 spanColor">
            Déploiement continu avec Docker et Ansible
          </p>,
          <p className="lg:ml-10 ml-0 ">
            Pour gagner du temps en terme de déploiement continu, nous utilisons
            les deux technologies :{" "}
            <span className="spanColor">Docker et Ansible.</span> Ils aident
            votre entreprise à réduire les erreurs ainsi que de surmonter les
            défis de long délai de commercialisation survenant lors du processus
            de développement. Ces deux outils de base assurent un usage adapté à
            vos ressources et à la maintenance de votre infrastructure.
          </p>,
        ],
      },
    ],
  },
];

export const packs = [
  {
    title: "beginner",
    features: [
      "Site vitrine",
      "Hébergement",
      "Certificat SSL",
      "Design",
      "Marketing",
      "Copywirting",
      "Maintenance",
    ],
  },
  {
    title: "e-commerce",
    features: [
      "Site e-commerce",
      "Hébergement",
      "Certificat SSL",
      "Charte graphique",
      "Marketing",
      "Référencement",
      "Maintenance",
    ],
  },

  {
    title: "medium",
    features: [
      "Design",
      "Site vitrine",
      "Hébergement",
      "Certificat SSL",
      "Consulting",
      "Marketing",
      "Copywirting",
      "Référencement",
      "Maintenance",
      "Analyse",
    ],
  },

  {
    title: "growth",
    features: [
      "Design",
      "Site vitrine",
      "Hébergement",
      "Certificat SSL",
      "Consulting",
      "Marketing",
      "Copywirting",
      "Référencement",
      "Maintenance",
      "Analyse",
      "Diagnostique",
    ],
  },
];

export const blogs = [
  {
    title: "TOP 5 Digital Marketing Trends For 2023",
    writer: "M.Keita",
    readTime: "5min",
    keywords: ["marketing", "digital", "trends", "2022"],
    addedAt: "01/12/2022",
    rating: 4,
    reviews: 169,
    images: [
      "/assets/blogs/blog1.jpg",
      "/assets/design1.webp",
      "/assets/blogs/eblog1.png",
    ],
    post: "Marketing Director at CODINGART",
    text: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tinci-
    dunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud
    exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem
    vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu
    feugiat nulla facilisis at vero eros Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
    diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
    enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse mo-
    lestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros Lorem ipsum dolor sit
    amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore
    magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcor-
    per suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
  },

  {
    title: "Top 10 Graphic Designer's For 2023",
    writer: "M.Keita",
    readTime: "5min",
    keywords: [
      "marketing",
      "digital",
      "trends",
      "graphicdesign",
      "art",
      "2022",
    ],
    addedAt: "09/9/2022",
    rating: 4.5,
    reviews: 239,
    images: [
      "/assets/blogs/blog2.png",
      "/assets/design2.webp",
      "/assets/blogs/eblog1.png",
    ],
    post: "Marketing Director at CODINGART",
    text: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tinci-
    dunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud
    exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem
    vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu
    feugiat nulla facilisis at vero eros Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
    diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
    enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse mo-
    lestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros Lorem ipsum dolor sit
    amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore
    magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcor-
    per suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
  },

  {
    title: "Why having a website & app can help your business",
    writer: "M.Keita",
    readTime: "6min",
    keywords: ["marketing", "digital", "trends", "development", "2022"],
    addedAt: "11/5/2022",
    rating: 4.8,
    reviews: 39,

    images: [
      "/assets/blogs/blog3.jpg",
      "/assets/design1.webp",
      "/assets/blogs/eblog1.png",
    ],
    post: "Marketing Director at CODINGART",
    text: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tinci-
    dunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud
    exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem
    vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu
    feugiat nulla facilisis at vero eros Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
    diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
    enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse mo-
    lestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros Lorem ipsum dolor sit
    amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore
    magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcor-
    per suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
  },

  {
    title: "What is Backoffice and when we use it",
    writer: "M.Keita",
    readTime: "5min",
    keywords: ["marketing", "digital", "trends", "business", "2022"],
    addedAt: "01/3/2022",
    rating: 4,
    reviews: 12,
    images: [
      "/assets/blogs/blog4.webp",
      "/assets/design1.webp",
      "/assets/blogs/eblog1.png",
    ],
    post: "Marketing Director at CODINGART",
    text: `Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tinci-
    dunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud
    exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem
    vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu
    feugiat nulla facilisis at vero eros Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
    diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi
    enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex
    ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse mo-
    lestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros Lorem ipsum dolor sit
    amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore
    magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcor-
    per suscipit lobortis nisl ut aliquip ex ea commodo consequat.`,
  },
];

export const filter = [
  {
    title: "categories",
    types: [
      "Commercial (24)",
      "Marketing (13)",
      "Design (09)",
      "Development (27)",
      "Art (5)",
      "UI/UX (38)",
      "Branding (98)",
      "Logo (07)",
      "IOT (17)",
      "Digital (29)",
      "Photography (12)",
    ],
  },
  {
    title: "archives",
    types: [
      "December 2022",
      "November 2022",
      "October 2022",
      "September 2022",
      "July 2022",
      "June 2022",
      "May 2022",
      "April 2022",
      "March 2022",
      "February 2022",
      "Junary 2022",
    ],
  },

  {
    title: "tags",
    types: [
      "#marketing #business",
      "#marketingdigital",
      "#digitalmarketing",
      "#branding #socialmedia",
      "#entrepreneur",
      "#socialmediamarketing",
      "#design #instagram",
      "#advertising #marketing",
      "#smallbusiness",
      "#graphicdesign",
      "motivation #love",
    ],
  },
];

// DEVIS

const style = "fill-thirdColor ml-2 group-hover:fill-white duration-300";
export const devisServiceCards = [
  {
    title: "Design",
    icon: <DesignIcon width="40" className={style} />,
  },
  {
    title: "Développement Web",
    icon: <WebDevIcon width="40" className={style} />,
  },
  {
    title: "Marketing Digital",
    icon: <MarketingIcon width="40" className={style} />,
  },
  {
    title: "Application Mobile",
    icon: <MobileIcon width="40" className={style} />,
  },
  {
    title: "Réseaux et Serveurs Mobile",
    icon: <ServerIcon width="40" className={style} />,
  },
  {
    title: "Hébergement Web",
    icon: <HostIcon width="40" className={style} />,
  },
  {
    title: "IOT",
    icon: <IotIcon width="40" className={style} />,
  },
  {
    title: "Dév Spécifique",
    icon: <DevSpecIcon width="40" className={style} />,
  },
  {
    title: "DevOps Strategy",
    icon: <DevOpsIcon width="40" className={style} />,
  },
];
