import { lazy } from "react"

export { default as Modal } from "../components/Modal"
export { default as Loader } from "../components/Loader"
export { default as NavBar } from "../components/NavBar"
export { default as Footer } from "../components/Footer"

export const Home = lazy(() => import("../pages/Home"));
export const Projects = lazy(() => import("../pages/Projects"));
export const ProjectTemplate = lazy(() => import("../pages/ProjectTemplate"));
export const ProjectDetails = lazy(() => import("../pages/ProjectDetails"));
export const Services = lazy(() => import("../pages/Services"));
export const ServiceTemplate = lazy(() => import("../pages/ServiceTemplate"));
export const ServiceDetails = lazy(() => import("../pages/ServiceDetails"));
export const Blog = lazy(() => import("../pages/Blog"));
export const BlogTemplate = lazy(() => import("../pages/BlogTemplate"));
export const BlogDetails = lazy(() => import("../pages/BlogDetails"));
export const About = lazy(() => import("../pages/About"));
export const Devis = lazy(() => import("../pages/Devis"));
export const Contact = lazy(() => import("../pages/Contact"));
export const LoginP = lazy(() => import("../pages/LoginP"));
export const SignupP = lazy(() => import("../pages/SignupP"));

export const EnterEmail = lazy(() => import("../pages/ForgetPassword/EnterEmail"));
export const CheckYourEmail = lazy(() =>  import("../pages/ForgetPassword/CheckYourEmail"));
export const NewPassword = lazy(() => import("../pages/ForgetPassword/NewPassword"));
export const SuccessChange = lazy(() => import("../pages/ForgetPassword/SuccessChange"));

// pages components
export {default as Header} from '../components/Header'
export {default as TitleOfSection} from '../components/TitleOfSection'
export {default as HelpingBusinessCard} from '../components/HelpingBusinessCard'
export {default as ConsultationSection} from '../components/home/ConsultationSection'
export {default as StaticsNum} from '../components/home/StaticsNum'
export {default as Technologies} from '../components/home/Technologies'
export {default as ServicesCarousel} from '../components/home/ServicesCarousel'