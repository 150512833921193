import { useEffect, Suspense, useState, useRef } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ElfsightWidget } from "react-elfsight-widget";
import { implementCursor } from "./script/customCursor";

import {
  BlogTemplate,
  Loader,
  ProjectDetails,
  ProjectTemplate,
  ServiceDetails,
  ServiceTemplate,
  Modal,
  NavBar,
  Blog,
  BlogDetails,
  Home,
  Projects,
  Services,
  About,
  Contact,
  Devis,
  Footer,
  LoginP,
  SignupP,
  CheckYourEmail,
  EnterEmail,
  NewPassword,
  SuccessChange,
} from "./globalImports";

import "./style.css";
import Page404 from "./pages/Page404";

// toastify
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const { pathname } = useLocation();
  const [showModal, setShowModal] = useState(false);
  const googleReview = useRef();

  useEffect(() => {
    implementCursor(googleReview.current);
  }, []);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);

  return (
    <div className="w-full overflow-hidden">
      <Suspense
        fallback={<Loader styling="w-full md:w-96  mx-auto mt-[40vh]" />}
      >
        <NavBar />
        <ElfsightWidget widgetID="9e4a7270-33a1-48f9-8810-b1dee61501d6" />
        <div className="-z-10 cursor-none">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<ProjectTemplate />}>
              <Route index element={<Projects />} />
              <Route path=":id" element={<ProjectDetails />} />
            </Route>
            <Route path="/services" element={<ServiceTemplate />}>
              <Route index element={<Services />} />
              <Route path=":title" element={<ServiceDetails />} />
            </Route>
            <Route path="/blog" element={<BlogTemplate />}>
              <Route index element={<Blog />} />
              <Route path=":id" element={<BlogDetails />} />
            </Route>
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/devis" element={<Devis />} />
            <Route path="/signin" element={<LoginP />} />
            <Route path="/signup" element={<SignupP />} />
            <Route path="/forget">
              <Route path="password" element={<EnterEmail />} />
              <Route path="recieve" element={<CheckYourEmail />} />
              <Route path="newpassword" element={<NewPassword />} />
              <Route path="success" element={<SuccessChange />} />
            </Route>
            <Route path="*" element={<Page404 />} />
          </Routes>
          {pathname === "/signin" ||
          pathname === "/signup" ||
          pathname === "/loader" ||
          pathname.includes("/forget") ? null : (
            <Footer />
          )}
        </div>
      </Suspense>

      {/* cursor style */}
      <div
        className="cursor w-16 h-16 z-[1000] rounded-full border border-gray-400 transition-all duration-200 ease-out fixed pointer-events-none left-0 top-0 translate-x-[calc(-50%+15)] -translate-y-1/2"
        aria-hidden="true"
      />
      <div
        className="cursor2 w-7 h-7 z-[1000] rounded-full bg-gradient-to-t from-secondColor/50 to-mainColor fixed -translate-x-1/2 -translate-y-1/2 pointer-events-none"
        aria-hidden="true"
      />
      <ToastContainer theme="dark" />
    </div>
  );
}

export default App;
