import { AiOutlineCheck } from "react-icons/ai";

function CustomCheckbox({
  description,
  label,
  activeClass,
  textColor,
  value,
  setValue,
}) {
  return (
    <div className="relative flex my-4 gap-2 items-start">
      <input
        type="checkbox"
        id={label}
        onChange={(e) => setValue(e.target.checked)}
        checked={value}
        className="checkbox border border-gray-300 checkbox-sm rounded-sm"
      />

      <label
        htmlFor={label}
        className={`w-full text-xs font-light ${textColor}`}
      >
        {description}
      </label>
    </div>
  );
}

export default CustomCheckbox;
