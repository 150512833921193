import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const API_URL = process.env.REACT_APP_STRAPI_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: API_URL,
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: [
    "Contact",
    "Newsletter",
    "blogs",
    "blog",
    "Review",
    "Stat",
    "Projets",
    "Projet",
    "Devis",
  ],
  endpoints: (builder) => ({
    getContact: builder.query({
      query: () => "/contacts?populate=*",
      providesTags: ["Contact"],
    }),
    postContact: builder.mutation({
      query: (data) => ({
        url: "/contacts",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Contact"],
    }),

    getNews: builder.query({
      query: () => "/newsletters",
      providesTags: ["Newsletter"],
    }),
    postNews: builder.mutation({
      query: (data) => ({
        url: "/newsletters",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Newsletter"],
    }),

    getDevis: builder.query({
      query: () => "/devis?populate=*",
      providesTags: ["Devis"],
    }),
    postDevis: builder.mutation({
      query: (data) => ({
        url: "/devis",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Devis"],
    }),

    getReview: builder.query({
      query: () => "/reviews?populate=*",
      providesTags: ["Review"],
    }),
    postReview: builder.mutation({
      query: (data) => ({
        url: "/reviews",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Review"],
    }),

    getProjets: builder.query({
      query: () => "/projets?populate=*",
      providesTags: ["Projets"],
    }),
    getProjet: builder.query({
      query: (id) => `/projets/${id}?populate=*`,
      providesTags: ["Projet"],
    }),
    postProjet: builder.mutation({
      query: (data) => ({
        url: "/projets",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Projet"],
    }),

    getStat: builder.query({
      query: () => "/stats?populate=*",
      providesTags: ["Stat"],
    }),
    //     poststat: builder.mutation({
    //       query: (data) => ({
    //         url: "/stats",
    //         method: "POST",
    //         body: data,
    //       }),
    //       invalidatesTags: ["Stat"],
    //     }),

    getBlogs: builder.query({
      query: () => "/blogs?sort=publishedAt:desc&populate=*",
      providesTags: ["blogs"],
    }),
    getBlog: builder.query({
      query: (id) => `/blogs/${id}?populate=*`,
      providesTags: ["blog"],
    }),
    postBlogs: builder.mutation({
      query: (data) => ({
        url: "/blogs",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["blogs"],
    }),
  }),
});

export const {
  useGetContactQuery,
  useGetDevisQuery,
  useGetProjetsQuery,
  useGetProjetQuery,
  useGetReviewQuery,
  useGetStatQuery,
  useGetBlogQuery,
  useGetBlogsQuery,
  useGetNewsQuery,
  usePostBlogsMutation,
  usePostContactMutation,
  usePostDevisMutation,
  usePostNewsMutation,
  usePostProjetMutation,
  usePostReviewMutation,
} = apiSlice;
