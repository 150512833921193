import { memo, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { EnvolopeIcon, PlayBtnIcon } from "../icons";

import { motion } from "framer-motion";
import {
  brandSlider,
  headerVariants,
  lineVariants,
  paraghVariants,
  titleVariants,
} from "../animation_variants";
import SideIcons from "./SideIcons";
import Modal from "./Modal";

function Header({ title, forHome, headerImg, setShowModal }) {
  const { pathname } = useLocation();

  const container = useRef();
  const list = useRef();

  useEffect(() => {
    if (pathname !== "/") return;
    const list2 = list.current.cloneNode(true);
    container.current.appendChild(list2);
    list2.style.left = "100%";
    return () => {
      list2.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <motion.header
      variants={headerVariants}
      initial="hidden"
      animate="visible"
      className={` relative tracking-widest ${
        forHome ? "h-[680px] md:h-[650px]" : "h-[330px] md:h-[540px]"
      }`}
    >
      <img
        loading="eager"
        src={headerImg}
        alt=""
        className="w-full h-full absolute top-0 left-0 object-cover bg-top object-center "
      />
      <div className="relative flex flex-col lg:flex-row lg:justify-between lg:items-stretch pt-28 md:pt-44 px-5 md:px-10 lg:pl-24 ">
        <div className="w-full lg:w-1/2 text-white">
          <motion.div
            variants={lineVariants}
            aria-hidden="true"
            className="w-16 h-2 md:w-20 md:h-4 mb-4 bg-white"
          />
          <motion.h1
            variants={titleVariants(0)}
            className="text-4xl md:text-5xl font-bold leading-[50px] md:leading-[60px]"
          >
            {title}
          </motion.h1>
          {forHome && (
            <motion.p variants={paraghVariants} className="mt-2 md:mt-4">
              Nous vous conseillons en développement et en transformation
              numérique.
            </motion.p>
          )}
        </div>

        {forHome && (
          <motion.div
            variants={paraghVariants}
            className="lg:w-1/2 flex flex-wrap space-y-8 mt-8 lg:mt-0 lg:flex-col lg:items-center justify-between"
          >
            <Link
              to="/contact"
              className="w-fit group grid grid-cols-2 grid-rows-2 justify-items-center uppercase"
            >
              <span>
                {" "}
                <EnvolopeIcon
                  color="white"
                  width="35"
                  height="30"
                  className="group-hover:fill-mainColor mt-1"
                />
              </span>
              <span className="md:text-md py-2 w-full text-center text-secondColor group-hover:text-mainColor bg-white">
                Parlons
              </span>
              <span className="md:text-md col-span-2 px-4 py-2 text-secondColor group-hover:text-mainColor bg-white">
                de votre projet
              </span>
            </Link>

            <div className="flex items-center space-x-8">
              <p className="text-white text-end w-fit leading-4">
                <span className="md:hidden">Play</span>{" "}
                <span className="hidden md:block">Regardez la vidéo</span>{" "}
                <span className="text-sm text-end">01:00 min</span>
              </p>

              <Modal forhome={true} />
            </div>
          </motion.div>
        )}
      </div>
      {forHome && (
        <div
          className="absolute hidden md:flex flex-col items-center space-y-8 left-8 bottom-0 uppercase animate-pulse"
          aria-label="hidden"
        >
          <p className="text-sm text-white -rotate-90">scroll</p>
          <div className="w-[1px] h-16 bg-white" aria-hidden="true" />
        </div>
      )}

      {forHome && (
        <motion.div
          variants={brandSlider}
          className="absolute bottom-0 right-0 pl-5 translate-y-1/2 w-[calc(100%-24px)] md:w-[calc(100%-96px)] py-6 bg-gray-100 flex items-center overflow-hidden"
        >
          <h2 className="text-mainColor w-44 shrink-0 font-bold">
            Ils nous <br /> font confiance
          </h2>
          <div
            ref={container}
            className="relative flex h-full shrink-0 overflow-hidden group slider"
          >
            <div
              ref={list}
              className="shrink-0 flex justify-between w-[1000px] list"
            >
              {[...Array(9)].map((img, id) => (
                <img
                  key={id}
                  loading="lazy"
                  src={`/assets/partenaire/partenaire${id + 1}.png`}
                  alt="partners"
                  className="pointer-events-none select-none object-contain w-[85px] mr-4 shrink-0"
                />
              ))}
            </div>
          </div>
        </motion.div>
      )}
      {/* <motion.div
        variants={socialMedia}
        className="absolute right-16 bottom-0 hidden lg:block"
      >
        <Link
          to="https://www.instagram.com/stecodingart/"
          target="_blank"
          rel="noreferrer"
        >
          <InstaIcon
            color="white"
            width="30"
            className="mb-4 hover:fill-mainColor"
          />
        </Link>
        <Link
          to="https://www.linkedin.com/company/codingartsarl/"
          target="_blank"
          rel="noreferrer"
        >
          {" "}
          <LinkedinIcon
            color="white"
            width="30"
            className="mb-4 hover:fill-mainColor"
          />
        </Link>
        <Link
          to="https://web.facebook.com/stecodingart"
          target="_blank"
          rel="noreferrer"
        >
          <FbIcon color="white" width="30" className="hover:fill-mainColor" />
        </Link>
      </motion.div> */}
      <div className="hidden lg:flex fixed top-[25%] right-0 text-gray-300 z-50 ">
        <SideIcons />
      </div>
    </motion.header>
  );
}

export default memo(Header);
