export const AndroidIcon = ({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M22.4,25.3c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3h17.4c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7
	c-0.2-3.4-2.1-6.3-4.9-7.9l2-3.2c0.3-0.4,0.1-1-0.3-1.3c-0.4-0.3-1-0.1-1.3,0.3L35,16.7c-0.9-0.3-2-0.5-3-0.5c-1,0-2.1,0.2-3,0.5
	l-2.1-3.4c-0.3-0.4-0.8-0.6-1.3-0.3c-0.4,0.3-0.6,0.8-0.3,1.3l2,3.2C24.5,19,22.5,21.9,22.4,25.3z M32,18c3.8,0,7,2.7,7.7,6.4H24.3
	C25,20.8,28.2,18,32,18z M35.5,22.9c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1s-1.1,0.5-1.1,1.1S34.9,22.9,35.5,22.9z M35.5,21
	c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8s-0.8-0.3-0.8-0.8S35.1,21,35.5,21z M28.5,22.9c0.6,0,1.1-0.5,1.1-1.1s-0.5-1.1-1.1-1.1
	c-0.6,0-1.1,0.5-1.1,1.1S27.9,22.9,28.5,22.9z M28.5,21c0.4,0,0.8,0.3,0.8,0.8s-0.3,0.8-0.8,0.8c-0.4,0-0.8-0.3-0.8-0.8
	S28.1,21,28.5,21z M46,29.4v9c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3v-9c0-0.7,0.6-1.3,1.3-1.3C45.4,28.1,46,28.6,46,29.4z
	 M20.6,29.4v9c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3v-9c0-0.7,0.6-1.3,1.3-1.3C20,28.1,20.6,28.6,20.6,29.4z M40.7,27.1
	H23.3c-0.5,0-0.9,0.4-0.9,0.9v13.5c0,1.3,1,2.3,2.3,2.3h1.6v4.2c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2V44c0-0.1,0.1-0.1,0.1-0.1
	h2.4c0.1,0,0.1,0.1,0.1,0.1v4.1c0,1.2,1,2.2,2.2,2.2c1.2,0,2.2-1,2.2-2.2v-4.2h1.6c1.3,0,2.3-1,2.3-2.3V28.1
	C41.7,27.6,41.2,27.1,40.7,27.1z M39.8,41.6c0,0.3-0.2,0.5-0.5,0.5h-2.1c-0.8,0-1.4,0.6-1.4,1.4v4.7c0,0.2-0.2,0.3-0.3,0.3
	c-0.2,0-0.3-0.2-0.3-0.3V44c0-1.1-0.9-2-2-2h-2.4c-1.1,0-2,0.9-2,2v4.1c0,0.2-0.2,0.3-0.3,0.3s-0.3-0.2-0.3-0.3v-4.7
	c0-0.8-0.6-1.4-1.4-1.4h-2.1c-0.3,0-0.5-0.2-0.5-0.5V29h15.6V41.6z M46.4,2H17.6c-3.3,0-6,2.7-6,6V56c0,3.3,2.7,6,6,6h28.8
	c3.3,0,6-2.7,6-6V8C52.5,4.7,49.8,2,46.4,2z M49.7,56c0,1.8-1.5,3.3-3.3,3.3H17.6c-1.8,0-3.3-1.5-3.3-3.3V8c0-1.8,1.5-3.3,3.3-3.3
	h28.8c1.8,0,3.3,1.5,3.3,3.3V56z M27.1,8c0-0.8,0.6-1.4,1.4-1.4h6.9c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4h-6.9
	C27.8,9.4,27.1,8.8,27.1,8z M42.5,7.1c0.3,0.3,0.4,0.6,0.4,1c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.3,0.3-0.6,0.4-1,0.4
	c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.4-0.3c-0.1-0.1-0.2-0.3-0.3-0.4c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.1,0-0.2,0-0.3
	c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.2-0.2C41.1,6.5,42,6.5,42.5,7.1z M35.1,56c0,0.8-0.6,1.4-1.4,1.4h-3.5
	c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h3.5C34.5,54.6,35.1,55.2,35.1,56z"
      />
    </svg>
  );
};

export const DesignIcon = ({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M56,2H8C4.7,2,2,4.7,2,8v38.4c0,3.3,2.7,6,6,6H56c3.3,0,6-2.7,6-6V8C62,4.7,59.3,2,56,2z M8,4.8H56
            c1.8,0,3.3,1.5,3.3,3.3v37H4.8V8C4.8,6.3,6.3,4.8,8,4.8z M56,49.7H8c-1.3,0-2.4-0.8-2.9-1.9h53.8C58.4,48.9,57.2,49.7,56,49.7z
             M42.9,60.6c0,0.8-0.6,1.4-1.4,1.4H22.5c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h19.1C42.3,59.2,42.9,59.8,42.9,60.6z
             M18,27.9c0.7,0.7,1.6,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1l6.7-6.7c1.4-1.4,1.4-3.7,0-5.1l-0.4-0.4c-1.4-1.4-3.8-1.4-5.1,0l-6.7,6.7
            C17,23.1,16.6,24,16.6,25c0,1,0.4,1.9,1.1,2.6L18,27.9z M19.6,24.4l6.7-6.7c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2l0.4,0.4
            c0.3,0.3,0.3,0.8,0,1.2L21.2,26c-0.3,0.3-0.9,0.3-1.2,0l-0.4-0.4c-0.2-0.2-0.2-0.4-0.2-0.6C19.4,24.8,19.5,24.6,19.6,24.4z
             M32.4,20.5l4.9,2c1.3,0.5,2.3,1.7,2.6,3.1l2.6,11.6c0.2,1-0.1,2-0.8,2.7c0,0,0,0,0,0c0,0,0,0,0,0c-0.5,0.5-1.3,0.8-2,0.8
            c-0.2,0-0.4,0-0.6-0.1L27.5,38c-1.4-0.3-2.5-1.3-3.1-2.6l-2-4.9c-0.3-0.7,0.1-1.5,0.8-1.8c0.7-0.3,1.5,0.1,1.8,0.8l2,4.9
            c0.2,0.5,0.6,0.8,1.1,0.9l9.2,2l-7-7c-0.5-0.5-0.5-1.4,0-2c0.5-0.5,1.4-0.5,2,0l7,7l-2-9.2c-0.1-0.5-0.4-0.9-0.9-1.1l-4.9-2
            c-0.7-0.3-1.1-1.1-0.8-1.8C30.8,20.6,31.6,20.2,32.4,20.5z M41.3,24.6c-1.7-1.7-2.7-4-2.7-6.5c0-2.4,1-4.7,2.7-6.5
            c1.2-1.2,2.6-2,4.3-2.4c0.7-0.2,1.5,0.3,1.7,1c0.2,0.7-0.3,1.5-1,1.7c-1.1,0.3-2.1,0.9-3,1.7c-1.2,1.2-1.9,2.8-1.9,4.5
            c0,1.7,0.7,3.3,1.9,4.5l1.5,1.5c2.7,2.7,2.7,7.2,0,9.9c-0.3,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4c-0.5-0.5-0.5-1.4,0-2
            c1.6-1.6,1.6-4.3,0-5.9L41.3,24.6z"
      />
    </svg>
  );
};

export const DevSpecIcon = ({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M11.6,20.7c0-0.8,0.6-1.4,1.4-1.4h12.7c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4H13
            C12.2,22.1,11.6,21.5,11.6,20.7z M13,16h12.7c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4H13c-0.8,0-1.4,0.6-1.4,1.4
            C11.6,15.3,12.2,16,13,16z M13,28.3h12.7c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4H13c-0.8,0-1.4,0.6-1.4,1.4
            C11.6,27.7,12.2,28.3,13,28.3z M13,34.4h19c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4H13c-0.8,0-1.4,0.6-1.4,1.4
            C11.6,33.8,12.2,34.4,13,34.4z M13,40.6h19c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4H13c-0.8,0-1.4,0.6-1.4,1.4
            C11.6,40,12.2,40.6,13,40.6z M62,8v38.4c0,3.3-2.7,6-6,6H8c-3.3,0-6-2.7-6-6V8c0-3.3,2.7-6,6-6H56C59.3,2,62,4.7,62,8z M4.8,8v37
            h54.4V8c0-1.8-1.5-3.3-3.3-3.3H8C6.3,4.8,4.8,6.3,4.8,8z M58.9,47.8H5.1c0.5,1.1,1.6,1.9,2.9,1.9H56C57.2,49.7,58.4,48.9,58.9,47.8z
             M41.5,59.2H22.5c-0.8,0-1.4,0.6-1.4,1.4c0,0.8,0.6,1.4,1.4,1.4h19.1c0.8,0,1.4-0.6,1.4-1.4C42.9,59.8,42.3,59.2,41.5,59.2z
             M37.7,32.2c0.1,0,0.3,0.1,0.4,0.1c0.6,0,1.1-0.4,1.3-1L46.2,11c0.2-0.7-0.2-1.5-0.9-1.8c-0.7-0.2-1.5,0.2-1.8,0.9l-6.8,20.3
            C36.5,31.2,36.9,31.9,37.7,32.2z M46.2,17.8l2.9,2.9l-2.9,2.9c-0.5,0.5-0.5,1.4,0,2c0.3,0.3,0.6,0.4,1,0.4s0.7-0.1,1-0.4l3.9-3.9
            c0.5-0.5,0.5-1.4,0-2l-3.9-3.9c-0.5-0.5-1.4-0.5-2,0S45.6,17.3,46.2,17.8z M34.8,25.6c0.3,0.3,0.6,0.4,1,0.4s0.7-0.1,1-0.4
            c0.5-0.5,0.5-1.4,0-2l-2.9-2.9l2.9-2.9c0.5-0.5,0.5-1.4,0-2c-0.5-0.5-1.4-0.5-2,0l-3.9,3.9c-0.3,0.3-0.4,0.6-0.4,1
            c0,0.4,0.1,0.7,0.4,1L34.8,25.6z"
      />
    </svg>
  );
};

export const DevOpsIcon = ({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M56,2H8C4.7,2,2,4.7,2,8v38.4c0,3.3,2.7,6,6,6H56c3.3,0,6-2.7,6-6V8C62,4.7,59.3,2,56,2z M8,4.8H56
            c1.8,0,3.3,1.5,3.3,3.3v37H4.8V8C4.8,6.3,6.3,4.8,8,4.8z M56,49.7H8c-1.3,0-2.4-0.8-2.9-1.9h53.8C58.4,48.9,57.2,49.7,56,49.7z
             M42.9,60.6c0,0.8-0.6,1.4-1.4,1.4H22.5c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h19.1C42.3,59.2,42.9,59.8,42.9,60.6z
             M14.3,31.4c-1.8-1.8-2.7-4.1-2.7-6.6c0-2.5,1-4.8,2.7-6.6s4.1-2.7,6.6-2.7c2.5,0,4.8,1,6.6,2.7l11.1,11.1c1.2,1.2,2.8,1.9,4.6,1.9
            c1.7,0,3.4-0.7,4.6-1.9c2.5-2.5,2.5-6.6,0-9.2c-1.2-1.2-2.9-1.9-4.6-1.9c-1.4,0-2.8,0.5-3.9,1.3l2.6,0.9c0.7,0.3,1.1,1.1,0.8,1.8
            c-0.2,0.6-0.7,0.9-1.3,0.9c-0.2,0-0.3,0-0.5-0.1l-5.9-2.1c-0.7-0.3-1.1-1.1-0.8-1.8l2.1-5.9c0.3-0.7,1.1-1.1,1.8-0.8
            c0.7,0.3,1.1,1.1,0.8,1.8l-1,2.7c1.5-1,3.3-1.6,5.2-1.6c2.5,0,4.8,1,6.6,2.7c3.6,3.6,3.6,9.5,0,13.1c-1.8,1.8-4.1,2.7-6.6,2.7
            c-2.5,0-4.8-1-6.6-2.7L25.4,20.3c-1.2-1.2-2.8-1.9-4.6-1.9c-1.7,0-3.4,0.7-4.6,1.9s-1.9,2.8-1.9,4.6c0,1.7,0.7,3.4,1.9,4.6
            s2.8,1.9,4.6,1.9c1.4,0,2.8-0.5,3.9-1.3l-2.6-0.9c-0.7-0.3-1.1-1.1-0.8-1.8c0.3-0.7,1.1-1.1,1.8-0.8l5.9,2.1
            c0.7,0.3,1.1,1.1,0.8,1.8l-2.1,5.9c-0.2,0.6-0.7,0.9-1.3,0.9c-0.2,0-0.3,0-0.5-0.1c-0.7-0.3-1.1-1.1-0.8-1.8l1-2.7
            c-1.5,1-3.3,1.6-5.2,1.6C18.4,34.2,16.1,33.2,14.3,31.4z"
      />
    </svg>
  );
};

export const HostIcon = ({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M56,2H8C4.7,2,2,4.7,2,8v38.4c0,3.3,2.7,6,6,6H56c3.3,0,6-2.7,6-6V8C62,4.7,59.3,2,56,2z M8,4.8H56
            c1.8,0,3.3,1.5,3.3,3.3v37H4.8V8C4.8,6.3,6.3,4.8,8,4.8z M56,49.7H8c-1.3,0-2.4-0.8-2.9-1.9h53.8C58.4,48.9,57.2,49.7,56,49.7z
             M42.9,60.6c0,0.8-0.6,1.4-1.4,1.4H22.5c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h19.1C42.3,59.2,42.9,59.8,42.9,60.6z
             M32,40.6c8.7,0,15.7-7,15.7-15.7S40.7,9.2,32,9.2s-15.7,7-15.7,15.7S23.3,40.6,32,40.6z M19.2,26.3h6.4c0.1,4.1,0.9,8.1,2.2,10.8
            C23.2,35.5,19.7,31.3,19.2,26.3z M32,12c1.2,0,3.4,4.3,3.6,11.5h-7.2C28.6,16.3,30.8,12,32,12z M35.6,26.3
            c-0.3,7.2-2.4,11.5-3.6,11.5s-3.4-4.3-3.6-11.5H35.6z M36.2,37.1c1.3-2.7,2.1-6.7,2.2-10.8h6.4C44.3,31.3,40.8,35.5,36.2,37.1z
             M44.8,23.5h-6.4c-0.1-4.1-0.9-8.1-2.2-10.8C40.8,14.3,44.3,18.5,44.8,23.5z M27.8,12.7c-1.3,2.7-2.1,6.7-2.2,10.8h-6.4
            C19.7,18.5,23.2,14.3,27.8,12.7z"
      />
    </svg>
  );
};

export const IosIcon = ({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M45.5,39.2c-1.2-0.6-3.8-2.3-3.9-6.2v0c0-3.1,1.9-4.9,3-5.7c0.2-0.1,0.3-0.4,0.4-0.6c0-0.2,0-0.5-0.2-0.7
            c-2.1-2.7-5-3.1-6.1-3.1c-1.7-0.2-3.3,0.5-4.6,1c-0.7,0.3-1.4,0.6-1.8,0.6c-0.5,0-1.1-0.3-1.9-0.6c-1.1-0.4-2.2-0.9-3.6-0.9
            c0,0,0,0,0,0c-3,0-5.8,1.8-7.3,4.5c-2.2,3.8-2.2,11.2,2.1,17.4c1.3,1.9,3.1,4.5,5.6,4.5c0,0,0.1,0,0.1,0c1.1,0,1.8-0.4,2.6-0.7
            c0.8-0.3,1.6-0.7,2.9-0.7c1.2,0,2,0.3,2.7,0.7c0.8,0.3,1.6,0.7,2.8,0.7c2.6,0,4.2-2.4,5.5-4.3c1.2-1.8,1.9-3.6,2.2-4.5
            C46.1,39.9,45.9,39.4,45.5,39.2z M42.2,43.8c-1.1,1.6-2.4,3.5-4,3.5c-0.8,0-1.3-0.2-2-0.5c-0.8-0.4-1.9-0.8-3.5-0.8
            c-1.7,0-2.7,0.5-3.6,0.8c-0.7,0.3-1.2,0.5-1.9,0.5c0,0,0,0-0.1,0c-1.4,0-2.7-1.7-4-3.7c-3.9-5.6-3.9-12.2-2.1-15.5
            c1.2-2.1,3.4-3.5,5.8-3.5c0.9,0,1.9,0.4,2.9,0.8c0.9,0.4,1.7,0.7,2.6,0.7c0.8,0,1.6-0.3,2.5-0.7c1.1-0.4,2.5-1,3.8-0.8
            c0.8,0,2.7,0.3,4.2,1.7c-1.3,1.1-3,3.3-3,6.6c0,4.2,2.7,6.5,4.2,7.4C43.6,41.3,43,42.6,42.2,43.8z M33.4,15.9
            C33.4,15.9,33.4,15.9,33.4,15.9c-1,1.2-2,3.1-1.9,5.1c0,0.5,0.4,0.9,0.9,0.9c0,0,0,0,0,0c1.8,0,3.6-1,4.9-2.6c0,0,0,0,0,0
            c1.2-1.5,1.9-3.4,1.9-5.2c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-0.7-0.2C36.5,13.5,34.5,14.5,33.4,15.9z M34.8,17.1
            c0.6-0.7,1.5-1.3,2.4-1.7c-0.2,1-0.7,2-1.3,2.8c-0.7,0.8-1.6,1.4-2.5,1.7C33.6,18.8,34.2,17.7,34.8,17.1
            C34.8,17.1,34.8,17.1,34.8,17.1z M46.4,2H17.6c-3.3,0-6,2.7-6,6V56c0,3.3,2.7,6,6,6h28.8c3.3,0,6-2.7,6-6V8C52.5,4.7,49.8,2,46.4,2z
             M49.7,56c0,1.8-1.5,3.3-3.3,3.3H17.6c-1.8,0-3.3-1.5-3.3-3.3V8c0-1.8,1.5-3.3,3.3-3.3h28.8c1.8,0,3.3,1.5,3.3,3.3V56z M27.1,8
            c0-0.8,0.6-1.4,1.4-1.4h6.9c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4h-6.9C27.8,9.4,27.1,8.8,27.1,8z M40.2,8.6
            c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.4,0.1-0.7,0.4-1c0.5-0.5,1.5-0.5,2,0c0.1,0.1,0.1,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.2
            c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.3
            c-0.2,0.1-0.4,0.1-0.5,0.1c-0.4,0-0.7-0.1-1-0.4C40.4,8.9,40.3,8.8,40.2,8.6z M35.1,56c0,0.8-0.6,1.4-1.4,1.4h-3.5
            c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h3.5C34.5,54.6,35.1,55.2,35.1,56z"
      />
    </svg>
  );
};

export const IotIcon = ({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M56,2H8C4.7,2,2,4.7,2,8v38.4c0,3.3,2.7,6,6,6H56c3.3,0,6-2.7,6-6V8C62,4.7,59.3,2,56,2z M8,4.8H56
            c1.8,0,3.3,1.5,3.3,3.3v37H4.8V8C4.8,6.3,6.3,4.8,8,4.8z M56,49.7H8c-1.3,0-2.4-0.8-2.9-1.9h53.8C58.4,48.9,57.2,49.7,56,49.7z
             M42.9,60.6c0,0.8-0.6,1.4-1.4,1.4H22.5c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h19.1C42.3,59.2,42.9,59.8,42.9,60.6z
             M15.3,29.8h1v4.7c0,3.3,2.7,6,6,6h19.3c3.3,0,6-2.7,6-6v-4.7h1c0.8,0,1.4-0.6,1.4-1.4c0-0.8-0.6-1.4-1.4-1.4h-1v-4.7
            c0-3.3-2.7-6-6-6h-8.3v-5.7c0-0.8-0.6-1.4-1.4-1.4c-0.8,0-1.4,0.6-1.4,1.4v5.7h-8.3c-3.3,0-6,2.7-6,6v4.7h-1c-0.8,0-1.4,0.6-1.4,1.4
            C13.9,29.2,14.5,29.8,15.3,29.8z M19.1,22.3c0-1.8,1.5-3.3,3.3-3.3h19.3c1.8,0,3.3,1.5,3.3,3.3v12.2c0,1.8-1.5,3.3-3.3,3.3H22.3
            c-1.8,0-3.3-1.5-3.3-3.3V22.3z M26.2,29.4c-0.3-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-1c0.5-0.5,1.5-0.5,2,0c0.3,0.3,0.4,0.6,0.4,1
            c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4C26.9,29.9,26.5,29.7,26.2,29.4z M35.8,29.4c-0.3-0.3-0.4-0.6-0.4-1
            c0-0.4,0.1-0.7,0.4-1c0.5-0.5,1.5-0.5,2,0c0.3,0.3,0.4,0.6,0.4,1c0,0.4-0.1,0.7-0.4,1c-0.3,0.3-0.6,0.4-1,0.4
            C36.4,29.9,36.1,29.7,35.8,29.4z"
      />
    </svg>
  );
};

export const MarketingIcon = ({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M56,2H8C4.7,2,2,4.7,2,8v38.4c0,3.3,2.7,6,6,6H56c3.3,0,6-2.7,6-6V8C62,4.7,59.3,2,56,2z M8,4.8H56
            c1.8,0,3.3,1.5,3.3,3.3v37H4.8V8C4.8,6.3,6.3,4.8,8,4.8z M56,49.7H8c-1.3,0-2.4-0.8-2.9-1.9h53.8C58.4,48.9,57.2,49.7,56,49.7z
             M42.9,60.6c0,0.8-0.6,1.4-1.4,1.4H22.5c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h19.1C42.3,59.2,42.9,59.8,42.9,60.6z
             M22.3,27.6c0,0,0.1,0,0.1,0h5.9c1,0,1.9,0.3,2.7,0.9l6,4.3c0.6,0.4,1.4,0.7,2.1,0.7c1.9,0,3.5-1.5,3.6-3.4c0-0.1,0-0.2,0-0.3V12.9
            c0-0.1,0-0.2,0-0.3c-0.1-1.9-1.7-3.4-3.6-3.4c-0.8,0-1.5,0.2-2.1,0.7l-6,4.3c-0.8,0.6-1.7,0.9-2.7,0.9h-5.9c0,0-0.1,0-0.1,0
            c-3.4,0.1-6.1,2.9-6.1,6.3S18.9,27.4,22.3,27.6z M32.7,16.4l6-4.3c0.1-0.1,0.3-0.2,0.5-0.2c0.5,0,0.9,0.4,0.9,0.9v16.9
            c0,0.5-0.4,0.9-0.9,0.9c-0.2,0-0.4-0.1-0.5-0.2l-6-4.3c-1.3-0.9-2.8-1.4-4.3-1.4h-1.5v-6.9h1.5C29.9,17.8,31.4,17.4,32.7,16.4z
             M22.5,17.8H24v6.9h-1.5c-1.9,0-3.5-1.6-3.5-3.5S20.6,17.8,22.5,17.8z M44.8,23.3v-3.9c0-0.8,0.6-1.4,1.4-1.4s1.4,0.6,1.4,1.4v3.9
            c0,0.8-0.6,1.4-1.4,1.4S44.8,24,44.8,23.3z M29.5,32c0,0.8-0.6,1.4-1.4,1.4h-2v3.5c0,2-1.7,3.7-3.7,3.7c-2,0-3.7-1.7-3.7-3.7v-6.7
            c0-0.8,0.6-1.4,1.4-1.4c0.8,0,1.4,0.6,1.4,1.4v6.7c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9v-4c0-1.3,1-2.3,2.3-2.3h2.4
            C28.8,30.6,29.5,31.2,29.5,32z"
      />
    </svg>
  );
};

export const MobileIcon = ({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M46.4,2H17.6c-3.3,0-6,2.7-6,6V56c0,3.3,2.7,6,6,6h28.8c3.3,0,6-2.7,6-6V8C52.5,4.7,49.8,2,46.4,2z M49.7,56
            c0,1.8-1.5,3.3-3.3,3.3H17.6c-1.8,0-3.3-1.5-3.3-3.3V8c0-1.8,1.5-3.3,3.3-3.3h28.8c1.8,0,3.3,1.5,3.3,3.3V56z M27.1,8
            c0-0.8,0.6-1.4,1.4-1.4h6.9c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4h-6.9C27.8,9.4,27.1,8.8,27.1,8z M40.6,9c-0.3-0.3-0.4-0.6-0.4-1
            c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.2
            c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.4,0,0.5,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.2,0.1
            c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3
            c0,0.4-0.1,0.7-0.4,1c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0
            c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.1C40.7,9.2,40.6,9.1,40.6,9z M35.1,56c0,0.8-0.6,1.4-1.4,1.4h-3.5
            c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h3.5C34.5,54.6,35.1,55.2,35.1,56z M38.1,18.1l-9.5,28.6c-0.2,0.6-0.7,1-1.3,1
            c-0.1,0-0.3,0-0.4-0.1c-0.7-0.2-1.1-1-0.9-1.8l9.5-28.6c0.2-0.7,1-1.1,1.8-0.9C37.9,16.6,38.3,17.4,38.1,18.1z M46.5,31
            c0.3,0.3,0.4,0.6,0.4,1s-0.1,0.7-0.4,1l-6.8,6.8c-0.3,0.3-0.6,0.4-1,0.4s-0.7-0.1-1-0.4c-0.5-0.5-0.5-1.4,0-2l5.8-5.8l-5.8-5.8
            c-0.5-0.5-0.5-1.4,0-2c0.5-0.5,1.4-0.5,2,0L46.5,31z M26.2,39.7c-0.3,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4L17.5,33
            c-0.5-0.5-0.5-1.4,0-2l6.7-6.7c0.5-0.5,1.4-0.5,2,0c0.5,0.5,0.5,1.4,0,2L20.5,32l5.8,5.8C26.8,38.3,26.8,39.2,26.2,39.7z"
      />
    </svg>
  );
};

export const ServerIcon = ({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M56,23.9c3.3,0,6-2.7,6-6V8c0-3.3-2.7-6-6-6H8C4.7,2,2,4.7,2,8v9.8c0,3.3,2.7,6,6,6h22.5v6.7H8
            c-3.3,0-6,2.7-6,6v9.8c0,3.3,2.7,6,6,6H56c3.3,0,6-2.7,6-6v-9.8c0-3.3-2.7-6-6-6H33.4v-6.7H56z M59.2,36.7v9.8
            c0,1.8-1.5,3.3-3.3,3.3H8c-1.8,0-3.3-1.5-3.3-3.3v-9.8c0-1.8,1.5-3.3,3.3-3.3H56C57.7,33.4,59.2,34.9,59.2,36.7z M4.8,17.8V8
            c0-1.8,1.5-3.3,3.3-3.3H56c1.8,0,3.3,1.5,3.3,3.3v9.8c0,1.8-1.5,3.3-3.3,3.3H8C6.3,21.1,4.8,19.6,4.8,17.8z M42.9,60.6
            c0,0.8-0.6,1.4-1.4,1.4H22.5c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h19.1C42.3,59.2,42.9,59.8,42.9,60.6z M23.9,12.9
            c0,0.8-0.6,1.4-1.4,1.4h-9.6c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h9.6C23.2,11.5,23.9,12.2,23.9,12.9z M49.7,13.2
            c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.2
            c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.4,0,0.5,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.2,0.1
            c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.3,0.4,0.6,0.4,1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.2
            c0,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.6,0.4-1,0.4c-0.4,0-0.7-0.1-1-0.4c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.2
            C49.7,13.4,49.7,13.3,49.7,13.2z M44.9,13.2c0-0.1,0-0.2,0-0.3c0-0.4,0.1-0.7,0.4-1c0.3-0.3,0.8-0.5,1.3-0.4c0.1,0,0.2,0,0.3,0.1
            c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.3,0.4,0.6,0.4,1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.3
            c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.1,0.1-0.2,0.2c-0.3,0.3-0.6,0.4-1,0.4c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3-0.1
            c-0.1,0-0.2-0.1-0.2-0.1c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.1-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.2C44.9,13.4,44.9,13.3,44.9,13.2z
             M40.5,13.9c-0.3-0.3-0.4-0.6-0.4-1c0-0.4,0.1-0.7,0.4-1c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1
            c0.2,0,0.4,0,0.5,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.3,0.3,0.4,0.6,0.4,1c0,0.4-0.1,0.7-0.4,1
            c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0
            c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.2-0.1C40.7,14,40.6,14,40.5,13.9z M23.9,41.5c0,0.8-0.6,1.4-1.4,1.4h-9.6
            c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h9.6C23.2,40.1,23.9,40.8,23.9,41.5z M49.7,42.1c-0.1-0.2-0.1-0.3-0.1-0.5
            c0-0.4,0.1-0.7,0.4-1c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c0.2,0,0.4,0,0.5,0c0.1,0,0.2,0,0.3,0.1
            c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
            c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4-0.1,0.5c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.3,0.1-0.5,0.1
            c-0.4,0-0.7-0.1-1-0.4C49.9,42.4,49.8,42.2,49.7,42.1z M45,42.1c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3
            c0-0.1,0.1-0.2,0.1-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1
            c0.2,0,0.4,0,0.5,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2,0.1,0.2,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.2,0.2
            c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.4-0.1,0.7-0.4,1c-0.1,0.1-0.3,0.2-0.5,0.3
            c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.5-0.3C45.2,42.4,45.1,42.2,45,42.1z M40.2,42.1
            c-0.1-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0.1-0.1,0.2-0.2c0.5-0.5,1.5-0.5,2,0
            c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4-0.1,0.5
            c-0.1,0.2-0.2,0.3-0.3,0.5c-0.1,0.1-0.3,0.2-0.5,0.3c-0.2,0.1-0.3,0.1-0.5,0.1c-0.2,0-0.4,0-0.5-0.1c-0.2-0.1-0.3-0.2-0.5-0.3
            C40.4,42.4,40.3,42.2,40.2,42.1z"
      />
    </svg>
  );
};

export const WebDevIcon = ({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M56,2H8C4.7,2,2,4.7,2,8v38.4c0,3.3,2.7,6,6,6H56c3.3,0,6-2.7,6-6V8C62,4.7,59.3,2,56,2z M8,4.8H56
            c1.8,0,3.3,1.5,3.3,3.3v37H4.8V8C4.8,6.3,6.3,4.8,8,4.8z M56,49.7H8c-1.3,0-2.4-0.8-2.9-1.9h53.8C58.4,48.9,57.2,49.7,56,49.7z
             M42.9,60.6c0,0.8-0.6,1.4-1.4,1.4H22.5c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h19.1C42.3,59.2,42.9,59.8,42.9,60.6z
             M25.9,38.8l9.5-28.6c0.2-0.7,1-1.1,1.8-0.9c0.7,0.2,1.1,1,0.9,1.8l-9.5,28.6c-0.2,0.6-0.7,1-1.3,1c-0.1,0-0.3,0-0.4-0.1
            C26.1,40.3,25.7,39.5,25.9,38.8z M37.8,17.2c0.5-0.5,1.4-0.5,2,0l6.7,6.7c0.5,0.5,0.5,1.4,0,2l-6.8,6.8c-0.3,0.3-0.6,0.4-1,0.4
            s-0.7-0.1-1-0.4c-0.5-0.5-0.5-1.4,0-2l5.8-5.8l-5.8-5.8C37.2,18.6,37.2,17.7,37.8,17.2z M17.5,25.9c-0.3-0.3-0.4-0.6-0.4-1
            s0.1-0.7,0.4-1l6.7-6.7c0.5-0.5,1.4-0.5,2,0c0.5,0.5,0.5,1.4,0,2l-5.8,5.8l5.8,5.8c0.5,0.5,0.5,1.4,0,2c-0.3,0.3-0.6,0.4-1,0.4
            c-0.4,0-0.7-0.1-1-0.4L17.5,25.9z"
      />
    </svg>
  );
};

export const WebsiteIcon = ({ color, width, height, className }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 64 64"
      fill={color}
      width={width}
      height={height}
      className={className}
    >
      <path
        d="M56,2H8C4.7,2,2,4.7,2,8v38.4c0,3.3,2.7,6,6,6H56c3.3,0,6-2.7,6-6V8C62,4.7,59.3,2,56,2z M8,4.8H56
            c1.8,0,3.3,1.5,3.3,3.3v37h-2.3V10.9c0-2.1-1.7-3.7-3.7-3.7H10.8c-2.1,0-3.7,1.7-3.7,3.7V45H4.8V8C4.8,6.3,6.3,4.8,8,4.8z
             M54.1,12.7H9.9v-1.9c0-0.5,0.4-0.9,0.9-0.9h42.4c0.5,0,0.9,0.4,0.9,0.9V12.7z M9.9,15.5h44.3V45H9.9V15.5z M56,49.7H8
            c-1.3,0-2.4-0.8-2.9-1.9h3.4h47h3.4C58.4,48.9,57.2,49.7,56,49.7z M42.9,60.6c0,0.8-0.6,1.4-1.4,1.4H22.5c-0.8,0-1.4-0.6-1.4-1.4
            c0-0.8,0.6-1.4,1.4-1.4h19.1C42.3,59.2,42.9,59.8,42.9,60.6z M22.9,17.7h-9.5c-0.8,0-1.4,0.6-1.4,1.4v4.4c0,0.8,0.6,1.4,1.4,1.4h9.5
            c0.8,0,1.4-0.6,1.4-1.4v-4.4C24.3,18.3,23.7,17.7,22.9,17.7z M21.5,22.2h-6.7v-1.7h6.7V22.2z M50.5,17.7H29.4
            c-0.8,0-1.4,0.6-1.4,1.4v21.1c0,0.8,0.6,1.4,1.4,1.4h21.1c0.8,0,1.4-0.6,1.4-1.4V19.1C51.9,18.3,51.3,17.7,50.5,17.7z M49.1,38.9
            H30.8V20.5h18.4V38.9z"
      />
    </svg>
  );
};


