import { memo } from "react";
import { motion } from "framer-motion";

import { paraghVariants } from "../../animation_variants";
import { ArrowrightIcon, ServiceBox } from "../../icons";
import { useNavigate } from "react-router-dom";

function ServiceCard({ title, children, reversed, url }) {
  const navigate = useNavigate();
  return (
    <motion.div
      onClick={() => navigate("/services/" + url)}
      variants={paraghVariants}
      initial="hidden"
      whileInView="visible"
      exit="visible"
      viewport={{ once: true }}
      className={`service-carousel group capitalize text-center relative w-fit ${
        reversed && "mt-[99px]"
      }`}
    >
      <ServiceBox
        className={`box  duration-200 ease-out fill-[#f4f4f4] group-hover: group-hover:fill-mainColor ${
          reversed && "rotate-180"
        }`}
        width="230"
      />
      <div className="absolute top-16 left-1/2 -translate-x-1/2 grid place-items-center">
        {children}
        <h3 className="my-2 duration-200 ease-out group-hover:text-white">
          {title}
        </h3>
        <ArrowrightIcon
          width="40"
          height="30"
          className="ml-1 duration-200 ease-out group-hover:fill-white"
        />
      </div>
    </motion.div>
  );
}

export default memo(ServiceCard);
