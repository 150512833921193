import React from 'react'

const Page404 = () => {
    return (
        <div className='w-full h-full flex flex-col justify-center'>
            <h1 className="text-5xl">Page non Trouver</h1>
            <h1 className='text-5xl'>404</h1>
        </div>
    )
}

export default Page404
