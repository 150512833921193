export const headerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.5,
    },
  },
};
export const titleVariants = (delay = 0) => {
  return {
    hidden: {
      opacity: 0,
      y: "-25vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        delay,
      },
    },
  };
};
export const lineVariants = {
  hidden: {
    width: 0,
  },
  visible: {
    width: 64,
    transition: {
      type: "spring",
    },
  },
};
export const paraghVariants = {
  hidden: {
    scale: 0.5,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
      delayChildren: 0.5,
    },
  },
};

export const socialMedia = {
  hidden: {
    opacity: 0,
    scale: 0.5,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: "linear",
    },
  },
};

export const svgVariants = {
  hidden: {},
  visible: {},
};

export const draw = {
  hidden: { pathLength: 0, fillOpacity: 0 },
  visible: {
    fillOpacity: 1,
    pathLength: 1,
    transition: {
      fillOpacity: {
        delay: 2,
      },
      pathLength: {
        duration: 3,
      },
    },
  },
};

export const brandSlider = {
  hidden: {
    opacity: 0,
    y: "-10vh",
  },
  visible: {
    opacity: 1,
    y: "50%",
    transition: {
      type: "spring",
      delay: 1,
    },
  },
};

export const buttonVariants = {
  hover: {
    scale: 1.2,
    textShadow: "0px 0px 8px rgba(255,255,255, 0.8)",
    boxShadow: "0px 0px 8px rgba(255,255,255, 0.8)",
    transition: {
      duration: 0.3,
    },
  },
};

export const buttonVariants1 = {
  hover: {
    scale: 0.8,
    textShadow: "0px 0px 8px rgba(255,255,255, 0.8)",
    boxShadow: "0px 0px 8px rgba(255,255,255, 0.8)",
    transition: {
      duration: 0.5,
    },
  },
};

export const modal = {
  hidden: {
    scale: 0,
    opacity: 0,
  },
  visible: {
    scale: 1,
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
};

export const imageVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};

export const cardVariants = (delay) => {
  return {
    hidden: {
      scale: 0.5,
      opacity: 0,
      y: 50,
    },
    visible: {
      scale: 1,
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        delay,
      },
    },
    hover: {
      boxShadow: "-4px 0 15px rgba(0,0,0,0.3)",
    },
  };
};
export const technoVariant = (y, x, delay) => {
  return {
    hidden: {
      scale: 0.8,
      opacity: 0,
      x: x,
      y: y,
    },
    visible: {
      scale: 1,
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        duration: 0.8,
        delay,
      },
    },
  };
};

export const formVariants = (withY, delay) => {
  return {
    hidden: {
      opacity: 0,
      scale: 0.5,
      skewY: "-35deg",
    },
    visible: {
      opacity: 1,
      scale: 1,
      skewY: 0,
      y: withY && -40,
      transition: {
        type: "spring",
        duration: 0.8,
        delay: delay || 0.5,
        ease: "easeInOut",
      },
    },
  };
};
export const bgvariants = {
  hover: {
    clipPath: `circle(200% at 0px 100%)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  },
  out: {
    clipPath: "circle(0px at 0px 100%)",
    transition: {
      type: "spring",
      stiffness: 150,
      damping: 40,
    },
  },
};
