import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { socialMedia } from "../animation_variants";
import { socialMediaIcons } from "../data";

const SideIcons = () => {
  return (
    <motion.ul variants={socialMedia} initial="hidden" animate="visible">
      {socialMediaIcons.map((icon, index) => (
        <IconItem icon={icon} key={index} />
      ))}
    </motion.ul>
  );
};

export default SideIcons;

function IconItem({ icon: { color, nom, path, logo } }) {
  return (
    <motion.li
      className={`${color} flex items-center justify-center  w-[160px] translate-x-[110px] rounded-l-full pl-4 p-2 hover:translate-x-0 transition-transform duration-500 ease-in-out`}
    >
      <Link
        to={path}
        target="_blank"
        rel="noreferrer"
        className="flex items-center gap-3  w-full"
      >
        <span>{logo}</span> <span>{nom}</span>
      </Link>
    </motion.li>
  );
}
